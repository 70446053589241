import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Fade } from "react-reveal";
import { PlayToMintWebGl } from "./PlayToMintWebGl";
import ErrorBoundary from "Component/ErrorBoundary";
import { useSelector } from "react-redux";
import useWallet from "hooks/wallet";
import { useWindow } from "hooks/useWindow";
import { getNetworkUrl } from "helpers/constants";
// import { useImage } from "Context/ImageContext";

const whitelistSession = "whitelist";
const publicSession = "public";
const PlayToMint = () => {
  const { settings, discord_user_roles, user } = useSelector(
    ({ Setting, Login }) => ({
      loading: Setting.loading,
      settings: Setting.settings,
      discord_user_roles: Login?.user?.discord_user_roles,
      user: Login?.user,
    })
  );

  const isWhiteListRole = discord_user_roles?.find(
    (item) => item?.role_name === whitelistSession
  );
  const gamePlaySession = settings?.gamePlaySettings?.play_session;
  const isCurrentSessionWhitelist = gamePlaySession === whitelistSession;
  const isCurrentSessionPublic = gamePlaySession === publicSession;
  const wallet = useWallet();
  //  const {handleHideProgress} = useImage();

  useEffect(() => {
    if (!wallet.account && JSON.stringify(settings) !== "{}") {
      const networkUrl = getNetworkUrl("ethereum", settings);
      console.log("networkUrl",networkUrl);
      wallet.connect(
        "metamask",
        networkUrl.chainId || 1
      );
    }
  }, [settings, wallet]);

  console.log("use jhbhdsbhfbjdbfhbdhfbbdfbh", user);

  const isConnectedPrimaryWallet =
    user?.walletId?.walletAddress &&
    user?.walletId?.walletAddress?.toLowerCase() ===
      wallet.account?.toLowerCase();

  console.log("isConnectedPrimaryWallet", isConnectedPrimaryWallet);
  // useEffect(()=>{
  //   handleHideProgress(false);
  // },[]);
  const {
    screenSize: { dynamicWidth },
  } = useWindow();

  const isMobile = dynamicWidth <= 799;

  const downloadAndoirdGame = () =>{

    let downloadLink = document.createElement('a');
    // Set the file URL
    downloadLink.href = 'https://robotic-rabbits.s3.us-east-2.amazonaws.com/RoboticRabbitMainnet.apk';
    // Set the download attribute to specify the suggested file name
    downloadLink.download = 'RoboticRabbitMainnet.apk';
    // For target="_blank" behavior, set target attribute to "_blank"
    downloadLink.target = '_blank';
    // Append the anchor element to the body
    document.body.appendChild(downloadLink);
    // Trigger a click event on the anchor element
    downloadLink.click();
    // Remove the anchor element from the body
    document.body.removeChild(downloadLink);
  }

  return (
    <Fade>
      <section className="Nfts_section common-pad">
        <Container>
          <div class="Workshop_content">
            <h1>Play To Mint</h1>
            <p>Gameplay Session : {gamePlaySession}</p>
            <p className="text-orange">
              Your Eth Balance :{" "}
              {wallet.balance
                ? Number(wallet.balance).toFixed(6)
                : wallet.balance}
            </p>
            <p>
            Make sure your metamask wallet is connected to your primary wallet.
            </p>
          </div>

          {((isCurrentSessionWhitelist && isWhiteListRole) ||
            isCurrentSessionPublic) &&
          wallet.account &&
          isConnectedPrimaryWallet ? (
            !isMobile ? (
              <ErrorBoundary>
                <PlayToMintWebGl />
              </ErrorBoundary>
            ) : null
          ) : (
            <p className="text-white">
              You don't have the whitelist role or primary wallet not connected.
              you are not able to play in current session.
            </p>
          )}
 <div className="game-details my-5">
                  <h5 className="game-detail-heading">Mobile Game</h5>
                    <hr />
                    <div className="android-build">
                      <h4 className="game-detail-heading">Android</h4>
                      <div class="button-wrap">
                        <button className="link_wallet btn btn-secondary" onClick={downloadAndoirdGame}>
                      
                          Download Apk
                          <svg className="mx-2" xmlns="http://www.w3.org/2000/svg" width="26" height="18" viewBox="0 0 26 18"><path fill="#fff" d="M7.6 12.781v7.238c0 .962-.756 1.731-1.719 1.731c-.956 0-1.881-.775-1.881-1.731v-7.238c0-.944.925-1.719 1.881-1.719c.944 0 1.719.775 1.719 1.719m.675 9.813c0 1.025.825 1.85 1.85 1.85h1.244l.019 3.819c0 2.306 3.45 2.288 3.45 0v-3.819h2.325v3.819c0 2.294 3.469 2.3 3.469 0v-3.819h1.262a1.843 1.843 0 0 0 1.837-1.85V11.382H8.275zm15.5-11.819H8.206c0-2.675 1.6-5 3.975-6.212l-1.194-2.206c-.175-.306.269-.5.419-.238l1.212 2.225c2.181-.969 4.688-.919 6.769 0l1.206-2.219c.156-.269.594-.069.419.238l-1.194 2.2c2.356 1.212 3.956 3.538 3.956 6.212zM13.106 7.306a.656.656 0 0 0-.656-.656c-.356 0-.637.3-.637.656s.288.656.637.656c.369 0 .656-.3.656-.656m7.088 0a.653.653 0 0 0-.638-.656a.656.656 0 1 0 0 1.312c.35 0 .638-.3.638-.656m5.925 3.757c-.944 0-1.719.756-1.719 1.719v7.238c0 .962.775 1.731 1.719 1.731c.963 0 1.881-.775 1.881-1.731v-7.238c0-.962-.925-1.719-1.881-1.719"/></svg> 
                        </button>
                        </div>
                    </div>
                    {/* <div className="android-build">
                      <h4 className="game-detail-heading">Apple</h4>
                        <a href="https://robotic-rabbits.s3.us-east-2.amazonaws.com/RoboticRabbitMainnet.apk" target="_blank" download> 
                          Download
                        </a>
                        <p className="text-white">Coming soon</p>
                    </div> */}
                  </div>
          {/* <div className="d-flex justify-content-center mt-4">
              <h4 className="text-white">Coming Soon</h4>
          </div> */}
        </Container>
      </section>
    </Fade>
  );
};

export default PlayToMint;
