import React, { useEffect, useRef } from "react";
import ContactUs from "Pages/ContactUs";
import Footer from "Component/Footer/Footer";
import { ImageProvider, useImage } from "Context/ImageContext";


function ContactUsPage() {
  // const childProgressRef = useRef();

//   const { setShowProgress } = useImage();
//   useEffect(()=>{
//     setShowProgress(true);
//   },[])


  return (
    <>
      <div className="welcome-body">
        <ContactUs />
        <Footer extraclass="welcomefooter footer-main" />
      </div>
    </>
  );
}


export default function ContactUstheme() {
  return (
  <ImageProvider>
      <ContactUsPage />
  </ImageProvider>
  );
}
