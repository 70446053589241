import UpdateNftName from "Component/Modals/UpdateNftModal";
// import { useImage } from "Context/ImageContext";
import { getProfile } from "helpers/backend_helper";
import { claimNftsTypes, getNetworkUrl, wait } from "helpers/constants";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner, Button, Tab, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Fade } from "react-reveal";
import { toast } from "react-toastify";
import { getCollection, updateUserNftMetadataRequest } from "store/actions";
import Web3Intraction from "util/web3Intraction";
import LazyLoad from 'react-lazyload';
import useWallet from "hooks/wallet";
import { useNavigate } from "react-router-dom";
import CarrotechProgress from "Component/Modals/CarrotechProgress";
import toastr from "toastr";

const InventoryTab = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.Setting);
  const { user } = useSelector((state) => state.Login);
  const { loadingUpdateNft } = useSelector(state => state.Items);
  const [pageKey, setPageKey] = useState(undefined);
  const [totalNfts, setTotalNfts] = useState(0);
  const [nftsList, setNftsList] = useState(0);
  const [loading, setLoading] = useState(null);
  const [userDetail, setUserDetails] = useState();
  const [rrsCollection, setRRSCollection] = useState(null);
  const [rrwgCollection, setRRWGCollection] = useState(null);
  const [rrvCollection, setRRVCollection] = useState(null);
  const [rroCollection, setRROCollection] = useState(null);
  const [armorsCollection, setArmorsCollection] = useState(null);
  const [showUpdateNftModal, setShowUpdateNftModal] = useState({
    nftData: "",
    show: false
  });
  const [carrotectNftsCount, setCarrotecNftsCount] = useState(0);
  const [carrotectProgress, setCarrotectProgress] = useState({
    show: false,
    type: "",
    item: ""
  });
  const navigate = useNavigate();

  // const {handleImageLoad,handleHideProgress} = useImage();

  useEffect(() => {
    if (user) {
      setUserDetails({ ...user, walletAddress: user?.walletId?.walletAddress });
    }
  }, [user])


  useEffect(() => {
    if (settings && settings.activeCollectionId) {
      dispatch(getCollection(settings.activeCollectionId, (data) => {
        setRRSCollection(data);
      }));
      if (settings && settings.activeArmorsCollectionId) {
        dispatch(getCollection(settings.activeArmorsCollectionId, (data) => {
          setArmorsCollection(data);
        }));
      }
      // dispatch(getCollection(settings.actionWGClaimCollectionId,(data)=>{
      //   setRRWGCollection(data);
      // }));
      // dispatch(getCollection(settings.activeVillainClaimCollectionId,(data)=>{
      //   setRRVCollection(data);
      // }));
      // dispatch(getCollection(settings.activeOriginsClaimCollectionId,(data)=>{
      //   setRROCollection(data);
      // }));
    }
  }, [JSON.stringify(settings)]);

  function generateOpenSeaUrl(network, contractAddress, tokenId) {
    const baseUrl = network === 'mainnet' ? 'https://opensea.io' : `https://testnets.opensea.io`;
    const networkType = network === "mainnet" ? "ethereum" : "sepolia";
    console.log("networkType", networkType);
    console.log("network", network)
    return `${baseUrl}/assets/${networkType}/${contractAddress}/${tokenId}`;
  }

  function formatNFTData(nftDataArray) {
    const groupedNFTs = nftDataArray?.reduce((acc, nft) => {
      //nft_type metadata exit for only claimed nfts so only claimed nfts will be merged per type.
      let nftType = nft?.rawMetadata?.nft_type;
      const existingGroup = acc.find(group => group.nft_type === nftType);
      const opensea_url = generateOpenSeaUrl(settings?.blockchainNetworkMode, nft?.contract?.address?.toLowerCase(), nft?.tokenId);
      nft.opensea_url = opensea_url || null;
      if (existingGroup && claimNftsTypes.includes(nftType)) {
        existingGroup.nfts.push(nft);
        existingGroup.count += 1;
      } else {
        const contractSymbol = nft?.contract?.symbol;
        if (contractSymbol === "Robotic_Rabbit_Legendary_Villains" && !nft.rawMetadata.image) {
          nft.rawMetadata.image = "/images/Villain.gif";
          nftType = "claim-villain"
        }
        if (nftType === "claim-origin" && nft?.rawMetadata?.edition === "") {
          console.log("bahsbahsbha")
          nft.rawMetadata.name += " #" + nft?.tokenId;
        }
        acc.push({
          nft_type: nftType || "og-mint",
          nfts: [nft],
          count: 1,
        });
      }

      return acc;
    }, []);

    return groupedNFTs;
  }

  useEffect(() => {

    const conditions = (settings?.activeCollectionId && settings?.actionWGClaimCollectionId && settings?.activeVillainClaimCollectionId && settings?.activeOriginsClaimCollectionId) ? (
      (rrsCollection &&
        rrsCollection.contractAbi)
      &&
      (rrwgCollection &&
        rrwgCollection.contractAbi)
      && (
        rrvCollection &&
        rrvCollection.contractAbi
      )
      && (
        rroCollection &&
        rroCollection.contractAbi
      )
    ) : ((rrsCollection &&
      rrsCollection.contractAbi)
      ||
      (rrwgCollection &&
        rrwgCollection.contractAbi)
      || (
        rrvCollection &&
        rrvCollection.contractAbi
      )
      || (
        rroCollection &&
        rroCollection.contractAbi
      )
    );

    if (
      (rrsCollection &&
        rrsCollection.contractAbi) &&
      settings &&
      JSON.stringify(settings) !== "{}"
    ) {
      const web3Intraction = new Web3Intraction(
        rrsCollection?.blockchain,
        settings
      );
      setNftsList([]);
      if (userDetail?.walletAddress) {
        (async () => {

          if (!rrsCollection?.contractAddress) {
            return toast.error(`Contract address is missing!`)
          }

          const contractAddresses = [];
          rrsCollection?.contractAddress && contractAddresses.push(rrsCollection.contractAddress);
          // rrwgCollection?.contractAddress && contractAddresses.push(rrwgCollection.contractAddress);
          // rrvCollection?.contractAddress && contractAddresses.push(rrvCollection.contractAddress);
          // rroCollection?.contractAddress && contractAddresses.push(rroCollection?.contractAddress)
          const params = {
            owner: userDetail?.walletAddress,
            contractAddresses: contractAddresses,
            pageKey: pageKey,
          }
          try {
            setLoading(true);

            const response = await web3Intraction.getNfts(params);
            const { ownedNfts,
              pageKey,
              totalCount } = response;
            console.log("ownedNfts", ownedNfts)
            // setPageKey(pageKey); 
            // setTotalNfts(totalCount);
            // let contractNfts = (ownedNfts || []).filter((item)=>item?.contract?.address?.toLowerCase() === rrsCollection.contractAddress?.toLowerCase());
            let contractNfts = (formatNFTData(ownedNfts, settings?.blockchainNetworkMode) || []);
            console.log("contractNfts", contractNfts)
            setNftsList(contractNfts);
            setLoading(false);

          } catch (e) {
            setLoading(false);

            console.log("Error while getting nft's", e);
          } finally {
            setLoading(false);
            // handleHideProgress();
          }
        })();
      } else {
        // handleHideProgress();
      }
    }
  }, [
    userDetail?.walletAddress,
    JSON.stringify(rrsCollection),
    // JSON.stringify(rrwgCollection),
    // JSON.stringify(rrvCollection),
    // JSON.stringify(rroCollection),
    JSON.stringify(settings),
  ]);
  const wallet = useWallet();

  // useEffect(async () => {
  //   try {
  //     if (
  //       armorsCollection &&
  //       armorsCollection.contractAbi &&
  //       settings &&
  //       JSON.stringify(settings) !== "{}"
  //     ) {
  //       const web3Intraction = new Web3Intraction(
  //         armorsCollection.blockchain,
  //         settings
  //       );

  //       const contract = await web3Intraction.getContract(
  //         armorsCollection.contractAbi,
  //         armorsCollection.contractAddress
  //       );
  //       console.log("armory contract", contract)
  //       if (!contract) {
  //         return;
  //       }
  //       const networkUrl = getNetworkUrl("base", settings);
  //       console.log("networkUrl", networkUrl);
  //       let chainID = networkUrl?.chainId;
  //       if (
  //         wallet.account &&
  //         wallet.account?.toLowerCase() ===
  //         userDetail?.walletAddress?.toLowerCase()
  //       ) {

  //         await wallet.switchNetwork(
  //           chainID
  //         );

  //       }

  //       //69 is carrotec tokenIF
  //       const carrotechNfts = await contract.methods.balanceOf(wallet.account, 69).call();
  //       console.log("carrotechNfts", carrotechNfts);
  //       setCarrotecNftsCount(carrotechNfts);
  //     }

  //   } catch (err) {

  //   }
  // }, [
  //   userDetail?.walletAddress,
  //   JSON.stringify(armorsCollection),
  //   JSON.stringify(settings)])

  const downloadNft = (url, fileName) => {
    if (url) {
      try {
        var linkElement = document.createElement('a');
        linkElement.id = 'link';
        window.document.body.appendChild(linkElement);
        var link = document.getElementById('link');
        link.setAttribute('href', url);
        link.setAttribute('target', "_blank");
        link.setAttribute('download', fileName); // Set the desired file name
        link.click();
      } catch (err) {
        console.log("Error", err);
      }
    }
  }

  function isValidName(name) {
    // Regular expression to check for the presence of numbers
    var regex = /\d/;

    // Test the name against the regular expression
    if (regex.test(name)) {
      return false; // Name contains numbers
    } else {
      return true; // Name is valid
    }
  }


  const onUpdateNftName = (name) => {
    if (!isValidName(name)) {
      return toast.error("Please enter name without number and special character.token id will automatic adjust");
    }
    dispatch(updateUserNftMetadataRequest({
      name: name,
      tokenId: showUpdateNftModal?.nftData?.tokenId,
      type: "og_mint"
    }, () => {
      setShowUpdateNftModal({
        nftData: null,
        show: false
      });
      window.location.reload();
      //  setNftsList((prev)=> prev?.map((item)=> item?.tokenId === showUpdateNftModal?.nftData?.tokenId ? {...item,rawMetadata:{...item.rawMetadata, name : name}} : item))

    }))
  }


  const onClickVerifyCarrotech = async (item) => {
    try {
      if (
        armorsCollection &&
        armorsCollection.contractAbi &&
        settings &&
        JSON.stringify(settings) !== "{}"
      ) {
        setCarrotectProgress({
          show: true,
          type: "init",
          item: null
        });

        await wait(1000);

        setCarrotectProgress({
          show: true,
          type: "loading",
          item: null
        });

        const web3Intraction = new Web3Intraction(
          armorsCollection.blockchain,
          settings
        );

        const contract = await web3Intraction.getContract(
          armorsCollection.contractAbi,
          armorsCollection.contractAddress
        );
        console.log("armory contract", contract)
        if (!contract) {
          return;
        }
        const networkUrl = getNetworkUrl("base", settings);
        console.log("networkUrl", networkUrl);
        let chainID = networkUrl?.chainId;
        if (
          wallet.account &&
          wallet.account?.toLowerCase() ===
          userDetail?.walletAddress?.toLowerCase()
        ) {

          await wallet.switchNetwork(
            chainID
          );

        }

        //69 is carrotec tokenIF
        const carrotechNfts = await contract.methods.balanceOf(wallet.account, 69).call();
        console.log("carrotechNfts", carrotechNfts);

        if (carrotechNfts > 0 && item?.nfts?.[0]?.rawMetadata?.isEditable) {
          await wait(1000);

          setCarrotectProgress({
            show: true,
            type: "grant",
            item: item
          });
        } else {
          await wait(1000);

          setCarrotectProgress({
            show: true,
            type: "denied",
            item: null
          });
        }


        // setCarrotecNftsCount(carrotechNfts);
      } else {
        await wait(1000);
        toastr.error("Something went wrong!")
        setCarrotectProgress({
          show: true,
          type: "denied",
          item: null
        });
      }

    } catch (err) {
      await wait(1000);

      toastr.error("Something went wrong!")
      setCarrotectProgress({
        show: false,
        type: "",
        item: null
      });
    }
  };



  return (
    <Fade>
      <section className="Nfts_section common-pad">
        <Container>
          <UpdateNftName
            loading={loadingUpdateNft}
            onUpdate={onUpdateNftName}
            show={showUpdateNftModal?.show}
            name={showUpdateNftModal?.nftData?.rawMetadata?.name}
            onhide={() => {
              setShowUpdateNftModal({
                show: false,
                nftData: null
              })
            }} />
          {carrotectProgress.show ?
            <CarrotechProgress
              show={carrotectProgress.show}
              item={carrotectProgress.item}
              type={carrotectProgress.type}
              onhide={() => {
                setCarrotectProgress({ show: false, type: "", item: null })
              }}
            />
            : null
          }
          <div class="Workshop_content">

            {/* <h1>Characters</h1> */}
            {/* <p>Under Construction</p> */}
            {/* <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </p> */}
          </div>

          <div className="nft_slider mt-5">
            <Row>
              {nftsList && nftsList?.map((item, idx) => {
                console.log("nft item", item)
                const isClaimedNft = claimNftsTypes.includes(item?.nft_type);
                const fileExt = item?.nfts?.[0]?.rawMetadata?.image?.split('.').pop();
                const filename = item?.nfts?.[0]?.tokenId + fileExt;
                return (
                  <Col lg={3} md={6} sm={6} key={idx + "og-nft"}>
                    <div className="nft_cover mb-3 position-relative" >
                      <div className={`${item?.nft_type === "claim-villain" ? "villain_card" : ""} card_img`}>
                        {isClaimedNft &&
                          <div className="claim-nft-count-badge">
                            {item?.count}
                          </div>
                        }
                        <LazyLoad debounce>
                          <img
                            key={idx + "og-nft"}
                            src={item?.nfts?.[0]?.rawMetadata?.image}
                            alt=""
                            className="img-fluid lazy-media"
                          // onLoadedData={handleImageLoad}
                          // onError={handleImageLoad}
                          />
                        </LazyLoad>
                      </div>
                      <div className="d-flex justify-content-between align-items-start mt-2">
                        <p className="item-name">{item?.nfts?.[0]?.rawMetadata?.name}</p>
                        {/* {!isClaimedNft && item?.nft_type !== "claim-villain" && item?.nft_type !== "claim-origin"
                      && 
                      <Button className="nft_edit" onClick={()=>{
                          if(isClaimedNft) return;
                        setShowUpdateNftModal({
                          show : true,
                          nftData : item?.nfts?.[0]
                        })
                      }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#fff" d="m19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575t1.412.575l1.4 1.4q.575.575.6 1.388t-.55 1.387L19.3 8.925ZM17.85 10.4L7.25 21H3v-4.25l10.6-10.6l4.25 4.25Z"></path></svg>      
                       </Button>
                       } */}
                        {/* {carrotectNftsCount > 0 && item?.nfts?.[0]?.rawMetadata?.isEditable
                          && */}
                        <Button className="nft_edit" onClick={() => {
                          navigate(`/workshop/nft/${item?.nfts?.[0]?.rawMetadata?.edition}`)
                          // onClickVerifyCarrotech(item)

                        }}>
                          {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 512 512"><path d="M416.3 256c0-21 13.1-38.9 31.7-46.1-4.9-20.5-13-39.7-23.7-57.1-6.4 2.8-13.2 4.3-20.1 4.3-12.6 0-25.2-4.8-34.9-14.4-14.9-14.9-18.2-36.8-10.2-55-17.3-10.7-36.6-18.8-57-23.7C295 82.5 277 95.7 256 95.7S217 82.5 209.9 64c-20.5 4.9-39.7 13-57.1 23.7 8.1 18.1 4.7 40.1-10.2 55-9.6 9.6-22.3 14.4-34.9 14.4-6.9 0-13.7-1.4-20.1-4.3C77 170.3 68.9 189.5 64 210c18.5 7.1 31.7 25 31.7 46.1 0 21-13.1 38.9-31.6 46.1 4.9 20.5 13 39.7 23.7 57.1 6.4-2.8 13.2-4.2 20-4.2 12.6 0 25.2 4.8 34.9 14.4 14.8 14.8 18.2 36.8 10.2 54.9 17.4 10.7 36.7 18.8 57.1 23.7 7.1-18.5 25-31.6 46-31.6s38.9 13.1 46 31.6c20.5-4.9 39.7-13 57.1-23.7-8-18.1-4.6-40 10.2-54.9 9.6-9.6 22.2-14.4 34.9-14.4 6.8 0 13.7 1.4 20 4.2 10.7-17.4 18.8-36.7 23.7-57.1-18.4-7.2-31.6-25.1-31.6-46.2zm-159.4 79.9c-44.3 0-80-35.9-80-80s35.7-80 80-80 80 35.9 80 80-35.7 80-80 80z" fill="#888888" /></svg> */}
                          <img src="/images/gear.png" width={22} height={22} />
                        </Button>
                        {/* } */}
                      </div>

                      <div className="btn_openseawrap mt-3">
                        <div className="button-wrap">

                          <Button target="_blank" href={item?.nfts?.[0]?.opensea_url} className="btn_openseas">Opensea</Button>
                        </div>

                        <Button onClick={() => downloadNft(item?.nfts?.[0]?.rawMetadata?.image, filename)} className="btn_download">
                          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 32 32"><path fill="currentColor" d="M15 4v16.563L9.72 15.28l-1.44 1.44l7 7l.72.686l.72-.687l7-7l-1.44-1.44L17 20.564V4h-2zM7 27v2h18v-2H7z" /></svg>
                        </Button>
                      </div>

                    </div>
                  </Col>
                );
              })}
              {!loading && !nftsList?.length
                ?
                <p className="text-center text-light">No record found.</p>
                :
                loading && !nftsList?.length &&
                <div className="d-flex justify-content-center text-light">
                  <Spinner color="#fff" />
                </div>
              }
            </Row>

          </div>
        </Container>
      </section>
    </Fade>
  );
};

export default InventoryTab;
