import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Fade } from "react-reveal";
import { Outlet,useLocation } from "react-router-dom";

import Profilesidebar from "./Profilesidebar";
import { RoboticRabbitsS3BaseUri } from "helpers/constants";
import { ImageProvider, useImage } from "Context/ImageContext";

const ProfilePage = () => {
  const {pathname}  = useLocation();
  // const { setShowProgress,setHideTimeOut,handleHideProgress} = useImage();

  // useEffect(()=>{
  //   console.log("pathname",pathname)
  //   setShowProgress(true);
  //   setHideTimeOut(8000);
  //   const paths = ["/account","/wallet-link","/claim","/discord-link"];
  //   if(paths.includes(pathname)){
  //     //hide loader if pathname in above array
  //     handleHideProgress();
  //   }
  // },[pathname])


  return (
    <Fade>
      <section className="profile_main">
      <div className="dash_vedio">
          <video 
           autoPlay 
           muted 
           loop 
           playsInline
          //  onLoadedData={handleImageLoad}
          //  onError={handleImageLoad}
           className="lazy-media"
           >
        <source src={`${RoboticRabbitsS3BaseUri}/BG_Interior.MP4`} type="video/mp4"/>
       </video>
          </div>
        <>
         
          <div className="profile_bottom">
            <Row>
              <Col lg={3} md={12} sm={12}>
                <div className="profile_sidebar">
                  <Profilesidebar />
                </div>
              </Col>
              <Col lg={9} md={12} sm={12}>
                <div className="profile_right_content">
                  <Outlet />
                </div>
              </Col>
            </Row>
          </div>
        </>
      </section>
    </Fade>
  );
};

const Profile = () =>{
  return(
     <ImageProvider>
        <ProfilePage/>
     </ImageProvider>
  );
}
export default Profile;
