import { postContactUsApi } from "helpers/backend_helper";
import React, { useState } from "react";
import { Button, Container, Row , Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import toastr from "toastr";

export default function ContactUs() {
  const navigate = useNavigate();
  const [fields,setFields] = useState(
    {
      name : "",
      email : "",
      subject : "",
      message : ""
    }
);

const [loading ,setLoading] = useState(false);

const handleChange = (e) =>{
    const {name,value} = e.target;
    setFields(prev => ({...prev, [name]:value}));
}



const handleSubmit = async(e) =>{
    try{
        e.preventDefault();
        setLoading(true);
     
        const response = await postContactUsApi(fields);
        console.log("response",response)
        if(response?.status === "success"){
            setFields({name : "",email : "",subject : "",message : ""})
            setLoading(false);
            toastr.success("Your request is submitted, support team will process on your request.")
         }else{
            setLoading(false);
            toastr.error(response?.message)
         }
    }catch(err){
        setLoading(false);
        console.log("Something went wrong",err)
        toastr.error("Something went wrong!")
    }finally{
        setLoading(false);
    }
   
}

  return (
    <section
      className="contacus_page welcome-page position-relative"
    >
     <Container>
                <div className="contact_us_form">
                    <Form>
             <h2 className="text-center text-white mb-3">Contact Us</h2>
         <Row>
            <Col lg={6} md={6} sm={12}>
                <FormGroup>
               <Label for="name">Your Name *</Label>
              <Input type="text" name="name" id="name" onChange={handleChange} placeholder="Enter Your Name" />
                </FormGroup> 
                    
            </Col>

            <Col lg={6} md={6} sm={12}>
                <FormGroup>
               <Label for="exampleEmail">Email</Label>
              <Input type="email" name="email"  onChange={handleChange} id="exampleEmail" placeholder="with a placeholder" />
                </FormGroup> 
                    
            </Col>


            <Col lg={12} md={12} sm={12}>
                <FormGroup>
               <Label for="Subject">Subject *</Label>
              <Input type="text"  onChange={handleChange} name="subject" id="Subject" placeholder="Type Subject:" />
                </FormGroup> 
                    
            </Col>



            <Col lg={12} md={12} sm={12}>
            <FormGroup>
          <Label for="Message">Message</Label>
          <Input type="textarea" name="message" onChange={handleChange} id="exampleText" placeholder="Enter Your Message"/>
        </FormGroup>
            </Col>
     
            <Col lg={12} md={12} sm={12}>
            <div className="button-wrap w-100 mt-4">
                <button disabled={loading} onClick={(e)=>handleSubmit(e)} type="submit" className="btn_sign_in w-100">
                   {loading ? "Submitting..." : "Submit"}</button></div>
             
            </Col>
        
         </Row>
         </Form>

                </div>
         
      </Container>
    </section>
  );
}
