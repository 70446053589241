import { PageProgress } from 'Component/PageProgress';
import React, { createContext, useState, useContext, useRef,useEffect } from 'react';

const ImageContext = createContext();

export const ImageProvider = ({ children }) => {
  const childProgressRef = useRef();
  const [showProgress, setShowProgress] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [hideTimeout,setHideTimeOut] = useState(8000);
  const handleImageLoad = async () => {
    const mediaElements = document.querySelectorAll('.lazy-media');
    const totalMediaElements = mediaElements.length;
  console.log("totalMediaElements",totalMediaElements)
    let loadedMediaCount = 0;
    let loopThrough = 0;
  
    for (const media of mediaElements) {
      loopThrough++;
      if (
        (media.tagName.toLowerCase() === 'img' && !media.complete) ||
        (media.tagName.toLowerCase() === 'video' && media.error)
      ) {
        // Skip failed image or video
        continue;
      }
  
      if (
        (media.tagName.toLowerCase() === 'img' && media.complete) ||
        (media.tagName.toLowerCase() === 'video' && media.readyState >= 1)
      ) {
        loadedMediaCount++;
      }
  
   
    }
  
    const progress = (loadedMediaCount / totalMediaElements) * 100;
    setLoadingProgress(progress);
    
//     const delayInterval = Math.floor(20 / totalMediaElements);

//   // Set a reduced delay interval between each iteration
//   await new Promise(resolve => setTimeout(resolve, delayInterval / 2)); // Adjust the division factor as needed

    if (loopThrough === totalMediaElements) {
      // All media elements are loaded
      handleHideProgress();
    }
  };


  const handleHideProgress = () =>{
    setTimeout(() => {
      setShowProgress(false);
      setLoadingProgress(0);
     
    }, hideTimeout); // Reset progress bar after 3 second

    setTimeout(()=>{
      if (childProgressRef.current) {
        childProgressRef.current.style.transition = 'opacity 0.6s';
        childProgressRef.current.style.opacity = 0;
      }
    },hideTimeout * 0.8)
  }

  useEffect(()=>{
    var html = document.documentElement;

    if(showProgress){
      html.style.overflowY = "hidden";
      console.log("scrroY", html.style.overflow);
    }else{
      html.style.overflowY = "scroll";
      console.log("scrroelse", html.style.overflow);

    }
  },[
    showProgress
  ])

  const contextValue = {
    showProgress,
    setShowProgress,
    handleImageLoad,
    loadingProgress,
    setLoadingProgress,
    setHideTimeOut,
    handleHideProgress
  };

  return <ImageContext.Provider value={contextValue}>
      {showProgress && (
          <PageProgress
            ref={childProgressRef}
          />
        )}
       {children}
    </ImageContext.Provider>;
};

export const useImage = () => {
  return useContext(ImageContext);
};
