import React, { useState, useEffect } from "react";
import { Badge, Container, Spinner, Stack } from "react-bootstrap";
import { Button } from "reactstrap";
import { Fade } from "react-reveal";
import { useLocation, useNavigate } from 'react-router-dom';

import Authrizedmod from "Component/Modals/Authrizedmod";
import { getDiscordData, getDiscordUserData, getProfile, unlinkDiscord, verifyDiscordUserRequest} from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const Discordlink = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authToken, user } = useSelector(state => state.Login)
  const {loading} = useSelector(state => state.Discord)
  const [authorized, setauthorized] = useState(false);
  const [discord, setdiscord] = useState('0');
  const [discordLink,setDiscordLink] = useState(null);
  const location = useLocation();

  const fragment = window.location.hash;
  const params = fragment.slice(1).split('&');
  const accessToken = params[1] && params[1].slice(13);

  useEffect(() => {
    const callback = (response) => {
      if (response.status == "success") {
        dispatch(getProfile());
      } 
      navigate(location.pathname, { replace: true })
    }
   
    if (accessToken && authToken) {
      let data = accessToken
     
      dispatch(getDiscordUserData(data, callback))
    }
  }, [authToken, accessToken])

  useEffect(()=>{
    dispatch(verifyDiscordUserRequest({},(response)=>{
      if (response?.status == "success") {
        dispatch(getProfile());
      } 
    }));
  },[]);


  const openLink = (url) => {
    // Add a <a> to use to download an image file
    try{
      var linkElement = document.createElement('a');
      linkElement.id = 'link';
      window.document.body.appendChild(linkElement);
      var link = document.getElementById('link');
      link.setAttribute('href', url);
      link.setAttribute('target', "_blank");
      link.click();
    }catch(err){
      console.log("Error",err);
    }
};


  const Authrized = async () => {
    if (discord) {
      // dispatch(getDiscordData({}, (data) => {
      //   if (data) {
      //     console.log("data", data);
      //     openLink(data);
      //     // window.open(data, '_blank');
      //     setTimeout(()=>{
      //       setdiscord('0');
      //       setauthorized(false);
      //     },2000)
      //   }
      // }));

      // setdiscord("0");
      setauthorized(false);
      setDiscordLink(null);
    }
  
  };

  const disconnectDiscord = () => {
    const callback = (response) => {
      if (response.status == "success") {
        dispatch(getProfile());
      } 
    }
    dispatch(unlinkDiscord({}, callback));
  }

  // const {code} = useParams();
  console.log(discord, "discord")

  const onClickLinkToDiscord = () =>{
   dispatch(getDiscordData({}, (data) => {
        if (data) {
          console.log("data", data);
           setDiscordLink(data);
           setauthorized(true)
          // window.open(data, '_blank');
        }
      }));
  }

  return (
    <>
      {authorized && discordLink
      &&(
      <Authrizedmod
        show={authorized}
        onhide={() => setauthorized(false)}
        togglemod={Authrized}
        discordLink={discordLink}
      />
      )
      }
      <section className="Workshop_main common-pad">
        <Container>
          {!user?.discord_link
          ? 
          <>
          <div className="Workshop_content">
            <h1>Discord Role Bot</h1>
            <p>Connect Your Discord account to get your roles.</p>

            <div className="button-wrap">
            <Button
              className="link_wallet"
              onClick={() => onClickLinkToDiscord()}
              >
              {loading && <Spinner size="sm" color="light" className="mr-2"/>} Link Your Discord Account
            </Button>
              </div>
          </div>
        </>
        :
        <>
        <div className="Workshop_content">
          <h1>Discord Role Bot</h1>
          <p>Your Discord account is connected.</p>
          {/* <Button
            className="disconnect_account"

          >
            Discord account connected !
          </Button> */}

          <div className="button-wrap mt-4">
            {/* <p>You are assigned A OG mint session role</p> */}
            <Button
              className="link_wallet text-uppercase disconnect_account"
              onClick={() => { setdiscord('0'); disconnectDiscord() }}
            >
              Unlink your discord Account
            </Button>
          </div>
          <div className="discord-user-roles mt-4 mb-4">
             <p>Discord Id : {user?.discord_user_id}</p>
            
          </div>
          <div className="discord-user-roles mt-4 mb-4">
             <p>User Roles</p>
             <Stack direction="horizontal" gap={2}>
             {user?.discord_user_roles?.map((role)=>(
            <div className="button-wrap" key={role?.role_id}>
              <h4 className="role-tab m-0">
                    <Badge bg="secondary"  className="text-capitalize role-badge"> {role?.role_name}</Badge>
              </h4>
              </div>
             ))}
             </Stack>
          </div>
        </div>
      </>
          }
        </Container>
      </section>
    </>
  );
};

export default Discordlink;
