import React, { useEffect } from "react";
import Signin from "Pages/Signin";
import { ImageProvider, useImage } from "Context/ImageContext";

const SigninPage = () => {
  const { setShowProgress,setHideTimeOut } = useImage();
  useEffect(()=>{
    setShowProgress(true);
    setHideTimeOut(4000);
  },[])

  return (
    <React.Fragment>
         <Signin />
    </React.Fragment>
  );
};

const Signintheme = () =>{

  return(
      <ImageProvider>
         <SigninPage />
      </ImageProvider>
  );
}
export default Signintheme;
