import React, { useRef,forwardRef } from "react";



export const PageProgress = forwardRef((props, ref) => {

  // // Expose a method or property through the ref
  // React.useImperativeHandle(ref, () => ({
  //   // Define the method or property you want to expose
  //   fadeOff: () => {
  //     // Logic to be executed when doSomething is called
  //     if (ref?.current) {
  //       ref.current.style.transition = 'opacity 0.4s';
  //       ref.current.style.opacity = 0;
  //     }
  //   },
  // }));
  
  return(
    <div className="fullloader" ref={ref}>
          <img className="loader" src="/images/rabbit_loader.gif" alt=""/>
          <div className="progress-right-logo-wrap">
             <img className="progress-right-logo" src="/images/logo.png" alt="logo" />
          </div>
    </div>
    
  );
});