import React, { useEffect, useRef } from "react";
import PrivacyPolicy from "Pages/PrivacyPolicy";
import Footer from "Component/Footer/Footer";
import { ImageProvider, useImage } from "Context/ImageContext";


function PrivacyPolicyPage() {
  // const childProgressRef = useRef();

  const { setShowProgress } = useImage();
  useEffect(()=>{
    setShowProgress(true);
    setTimeout(()=> setShowProgress(false),2000)
  },[])


  return (
    <>
      <div className="welcome-body">
        <PrivacyPolicy />
        <Footer extraclass="welcomefooter footer-main" />
      </div>
    </>
  );
}


export default function PrivacyPolicyTheme() {
  return (
  <ImageProvider>
      <PrivacyPolicyPage />
  </ImageProvider>
  );
}
