import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, info) {
        console.log("error",error);
      // Handle the error here and update the state accordingly
      this.setState({ hasError: true });
    }
  
    render() {
      if (this.state.hasError) {
        // Render an error message or component
        return <div>Something went wrong</div>;
      }
      return this.props.children;
    }
  }

  export default ErrorBoundary;