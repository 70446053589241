import React from "react";
import { Link } from "react-router-dom";


export default function Dashfooter(props) {
  return (
    <section className={props.extraclass}>
      <div className="footer-copyright d-flex align-item-center justify-content-between">
        <p>© 2024 Robotic Rabbit Syndicate</p>


         <ul className="d-flex align-item-center m-0 p-0">
            <li><Link to="">Privacy Policy</Link></li>
            <li><Link to="">Terms & Condition</Link></li>

         </ul>
      </div>
    </section>
  );
}
