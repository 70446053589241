import React, { useState } from "react";

export const ButtomToTopButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500) {
      setVisible(true);
    } else if (scrolled <= 500) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  typeof window !== "undefined" &&
    window.addEventListener("scroll", toggleVisible);

  if (!visible) {
    return null;
  }
  return (
    <a
      onClick={scrollToTop}
      style={{ display: visible ? "block" : "none" }}
      href="javascript:void(0)"
      className="backToTop position-fixed d-flex align-items-center justify-content-center"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M8.99999 16H6.99999V4L1.49999 9.5L0.0799866 8.08L7.99999 0.160004L15.92 8.08L14.5 9.5L8.99999 4V16Z"
          fill="#fff"
        />
      </svg>
    </a>
  );
};
