import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Nftmod from "Component/Modals/Nftmod";

const Nfttrade = () => {
  const [nftmod, setnftmod] = useState(false);
  return (
    <>
      <Nftmod show={nftmod} onhide={() => setnftmod(false)}/>
      <section className="nfttrade_sec common-pad">
      <Container>
        <div class="Workshop_content">
          <h1>Send</h1>
        </div>

        <div className="nfttrade_card_wrap mt-4">
          <Row>
            <Col lg={7}>
              <div className="nfttrade_card">
                <div className="tab_head">
                  <h6>NFT</h6>
                </div>

                <div className="send_token d-flex align-items-center py-3">
                  <div className="token_img me-2">
                    <img
                      src="/images/bigeth.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p>Ethereum</p>
                </div>

                <div className="nftbox mb-3">
                  <div className="reicpent">
                    <p>Recipient</p>

                    <div className="wrap_input d-flex align-items-center justify-content-between">
                      <div className="input_recipent d-flex align-items-center">
                        <div className="token_img me-2">
                          <img
                            src="/images/bigeth.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <input
                          type="text"
                          placeholder="Address , domain or identity"
                        />
                      </div>

                      <div className="drop_downuser">
                        <UncontrolledDropdown>
                          <DropdownToggle caret>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="M5.85 17.1q1.275-.975 2.85-1.538T12 15q1.725 0 3.3.563t2.85 1.537q.875-1.025 1.363-2.325T20 12q0-3.325-2.337-5.663T12 4Q8.675 4 6.337 6.337T4 12q0 1.475.488 2.775T5.85 17.1ZM12 13q-1.475 0-2.488-1.012T8.5 9.5q0-1.475 1.012-2.488T12 6q1.475 0 2.488 1.012T15.5 9.5q0 1.475-1.012 2.488T12 13Zm0 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z"
                              />
                            </svg>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>Another Action</DropdownItem>
                            <DropdownItem>Another Action</DropdownItem>

                            <DropdownItem>Another Action</DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>

                    <p className="text-truncate w-25 value_here">
                      0x090000000000000
                    </p>
                  </div>
                </div>

                <div className="nftbox mb-3">
                  <div className="select-Nft">
                    <p>Select NFT</p>

                    <div className="item_part d-flex align-items-center gap-2">
                      <div className="item_div"></div>
                      <div className="item_Content">
                        <h4  onClick={() => setnftmod(true)}>
                          No item
                          <span className="ms-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6l-6-6l1.41-1.41z"
                              />
                            </svg>
                          </span>
                        </h4>
                        <p className="balce">Balance : 0</p>
                      </div>
                    </div>
                  </div>
                </div>

                <Button className="link_wallet w-100">Enter recipient</Button>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    </>

  );
};

export default Nfttrade;
