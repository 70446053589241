import SuccessMintModal from "Component/Modals/SuccessMintMod";
import {
  getOgClaimedNftsApi,
  getOriginClaimedTokenIdsApi,
} from "helpers/backend_helper";
import {
  claimNftSigPasscode,
  claimNftSigValue,
  generateNumberArraySteps,
  generateTokenIdArrayAscending,
  getNetworkUrl
} from "helpers/constants";
import useWallet from "hooks/wallet";
import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Fade } from "react-reveal";
import { toast } from "react-toastify";
import {
  getCollection,
  postMintActivity,
  postRrwsS3MetadataRequest,
  getDiscordAkNftCountRequest,
  postNftRrcToMainBucketRequest,
} from "store/actions";
import Web3Intraction from "util/web3Intraction";
// import web3InteractionEth from "util/web3InteractionEth";
import { ethers, Contract } from "ethers";
import {
  checkEveryTypeHasAtLeastOneElement,
  createNewObject,
  excludeClaimedIdsForOrigin,
  getFirstNElementsForOrigin,
} from "./commonFunctions";

const initialNftsSetCalaimOrigin = {
  "og-mint": [],
  "claim-vipak47-mint": [],
  "claim-earlybird-mint": [],
  "claim-maxmallet-mint": [],
};
const Claim = () => {
  const [userDetail, setUserDetails] = useState();
  const [nftsSetForClaimOrigin, setNftsSetForClaimOrigin] = useState(
    initialNftsSetCalaimOrigin
  );
  const [nftNumUserCanMint, setNftNumUserCanMint] = useState({
    earlyMint: 0,
    vipAk47: 0,
    mallet: 0,
  });
  const [loading, setloading] = useState(false);
  const [rrsCollection, setRRSCollection] = useState(null);
  const [rrwgCollection, setRRWGCollection] = useState(null);
  const [rrvCollection, setRRVCollection] = useState(null);
  const [rroCollection, setRROCollection] = useState(null);
  const [earlyMintClamable, setEarlyMintClamable] = useState(false);
  const [vipAk47MintClamable, setVipAk47MintClamable] = useState(false);
  const [maxMalletMintClamable, setMaxMalletMintClamable] = useState(false);
  const [villainMintClamable, setVillainMintClaimable] = useState(false);
  const [originMintClamable, setOriginMintClaimable] = useState(false);
  const [claimedStatusLoading, setClaimedStatusLoading] = useState(false);
  const [claimed, setClaimed] = useState({
    earlyMint: false,
    vipAk47: false,
    mallet: false,
  });
  const [discordNftCheckError, setDiscordNftCheckError] = useState("");
  const [userDiscordAkAlreadyMinted, setUserDiscordAkAlreadyMinted] =
    useState(false);
  const [userDiscordVillainAlreadyMinted, setUserDiscordVillainAlreadyMinted] =
    useState(false);

  const [loadingMint, setLoadingMint] = useState({
    "claim-earlybird-mint": false,
    "claim-vipak47-mint": false,
    "claim-maxmallet-mint": false,
    "claim-villain": false,
    "claim-origin": false,
  });
  const [successMintModal, setSuccessinModal] = useState(false);
  const [claimableTokens, setClaimableTokens] = useState({
    earlyMint: [],
    vipAk47: [],
    mallet: [],
  });

  const dispatch = useDispatch();
  const wallet = useWallet();

  const { user } = useSelector((state) => state.Login);
  const { settings } = useSelector((state) => state.Setting);
  const { loadingDiscordAk47 } = useSelector((state) => state.Items);
  const { loading: loadingCollection } = useSelector(
    (state) => state.Collection
  );

  useEffect(() => {
    if (!wallet.account && settings && JSON.stringify(settings) !== "{}") {
console.log("sesdsdsdttings",settings)
      const networkUrl = getNetworkUrl("ethereum", settings);
      console.log("networkUrl",networkUrl);
      wallet.connect(
        "metamask",
        networkUrl.chainId || 1
      );
    }
  }, [settings]);

  useEffect(() => {
    if (user) {
      setUserDetails({ ...user, walletAddress: user?.walletId?.walletAddress });
    }
  }, [user]);

  const checkDiscordClaimedNftsCount = () => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(
          getDiscordAkNftCountRequest(
            { discord_user_id: userDetail?.discord_user_id },
            (status, data) => {
              console.log("status", status, "data", data);
              if (status) {
                if (data?.ak47 > 0) {
                  setUserDiscordAkAlreadyMinted(true);
                } else {
                  // setVipAk47MintClamable(false);

                  setUserDiscordAkAlreadyMinted(false);
                }
                if (data?.villain > 0) {
                  setUserDiscordVillainAlreadyMinted(true);
                } else {
                  setUserDiscordVillainAlreadyMinted(false);
                }
                setDiscordNftCheckError("");
                resolve({ ak47: data?.ak47, villain: data?.villain });
              } else {
                console.log("discord error", data);
                setDiscordNftCheckError(data || "Error");
                // setVipAk47MintClamable(false);
                reject("Something went wrong!");
              }
            }
          )
        );
      } catch (error) {
        console.log("error", error);
        reject("Something went wrong!");
      }
    });
  };

  const claimedStatus = async (walletAddress, contract) => {
    try {
      setClaimedStatusLoading(true);
      const resp = await contract.methods.Getdata(walletAddress).call();
      console.log("claimed nfts", resp);
      const data = {
        earlyMint: resp?.[0],
        vipAk47: resp?.[1],
        mallet: resp?.[2],
      };
      setClaimed(data);
      setClaimedStatusLoading(false);
      return data;
    } catch (e) {
      setClaimedStatusLoading(false);
      return null;
    }
  };

  const checkVillainCliamable = async (userDetail) => {
    try {
      const allowedDiscordId = rrvCollection?.allowed_discord_users?.find(
        (item) => item?.discord_user_id === userDetail?.discord_user_id
      );

      if (!allowedDiscordId) {
        setVillainMintClaimable(false);
        return false;
      } else if (allowedDiscordId && allowedDiscordId?.mint_status) {
        setVillainMintClaimable(false);
        return false;
      } else {
        const legendaryVillain = userDetail?.discord_user_roles?.find(
          (item) => item?.role_name === "legendary_villain"
        );
        if (legendaryVillain) {
          setVillainMintClaimable(true);
          return true;
        } else {
          setVillainMintClaimable(false);
          return false;
        }
      }
    } catch (error) {
      setVillainMintClaimable(false);
      return null;
    }
  };

  useEffect(() => {
    if (settings) {
      dispatch(
        getCollection(settings.activeCollectionId, (data) => {
          setRRSCollection(data);
        })
      );
      dispatch(
        getCollection(settings.actionWGClaimCollectionId, (data) => {
          setRRWGCollection(data);
        })
      );
      dispatch(
        getCollection(settings.activeVillainClaimCollectionId, (data) => {
          setRRVCollection(data);
        })
      );
      // dispatch(getCollection(settings.activeOriginsClaimCollectionId,(data)=>{
      //   setRROCollection(data);
      // }));
    }
  }, [JSON.stringify(settings)]);

  const getAvailableOgTokenIdsForMint = async (clamableNfts, nft_type) => {
    try {
      const response = await getOgClaimedNftsApi({ nft_type });
      if (response.status === "success") {
        const ogClaimedNfts = response.data;
        console.log("ogClaimed Nfts", ogClaimedNfts);
        if (!ogClaimedNfts?.length) {
          return clamableNfts;
        } else {
          return clamableNfts.filter(
            (tokenId) => !ogClaimedNfts.includes(Number(tokenId))
          );
        }
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  // const getOriginClaimedTokenIds = async() =>{
  //   try{
  //      const response = await getOriginClaimedTokenIdsApi();
  //      if(response.status === "success"){
  //         return response["data"];
  //      }

  //     return null;

  //   }catch(error){
  //      return null;
  //   }
  // }

  const checkAllRequiredConditions = useCallback(() => {
    //initial state setting
    setOriginMintClaimable(false);
    if (
      rrsCollection &&
      rrsCollection.contractAbi &&
      rrwgCollection &&
      rrwgCollection.contractAbi &&
      settings &&
      JSON.stringify(settings) !== "{}"
    ) {
      const web3Intraction = new Web3Intraction(
        rrsCollection.blockchain,
        settings
      );
      console.log(userDetail?.walletAddress, "userDetail?.walletAddress");
      if (userDetail?.walletAddress) {
        (async () => {
          try {
            //rrs contract calling
            const contract = await web3Intraction.getContract(
              rrsCollection.contractAbi,
              rrsCollection.contractAddress
            );
            if (!contract) {
              return toast.error(`Error while wrong getting contract!`);
            }
            const _tokens = await contract.methods
              .walletOfOwner(userDetail?.walletAddress)
              .call();
            console.log("v", _tokens);

            //rrwg contract calling
            const web3Intraction2 = new Web3Intraction(
              rrwgCollection.blockchain,
              settings
            );

            const rrwgContract = await web3Intraction2.getContract(
              rrwgCollection.contractAbi,
              rrwgCollection.contractAddress
            );
            if (!rrwgContract) {
              return toast.error(`Error while wrong getting rrwgContract!`);
            }
            const claimedStatuces = await claimedStatus(
              userDetail?.walletAddress,
              rrwgContract
            );
            console.log("claimedStatuces", claimedStatuces);
            if (!claimedStatuces) {
              return toast.error("Error While getting claimed status!");
            }

            let checkAk47 = null;
            if (userDetail?.discord_user_id) {
              const data = await checkDiscordClaimedNftsCount();
              checkAk47 = data.ak47;
            }
            console.log("checkAk47", checkAk47);

            let tokensList = _tokens;
            tokensList = tokensList?.filter(
              (token) => token > 0 && token <= 100
            );
            let countMintable = {
              earlyMint: 0,
              vipAk47: 0,
              mallet: 0,
            };

            const uniqueEarlyBirdOgTokenIds =
              await getAvailableOgTokenIdsForMint(
                tokensList,
                "claim-earlybird-mint"
              );
            const uniqueOgVipTokenIds = await getAvailableOgTokenIdsForMint(
              _tokens,
              "claim-vipak47-mint"
            );
            const uniqueMalletTokenIds = await getAvailableOgTokenIdsForMint(
              _tokens,
              "claim-maxmallet-mint"
            );

            console.log("uniqueEarlyBirdOgTokenIds", uniqueEarlyBirdOgTokenIds);
            console.log("uniqueOgVipTokenIds", uniqueOgVipTokenIds);
            console.log("uniqueMalletTokenIds", uniqueMalletTokenIds);
            if (
              uniqueEarlyBirdOgTokenIds?.length &&
              !claimedStatuces?.earlyMint
            ) {
              //between 0 to 100 og nfts
              setEarlyMintClamable(true);
              countMintable.earlyMint = uniqueEarlyBirdOgTokenIds.length;
              setClaimableTokens((prev) => ({
                ...prev,
                earlyMint: uniqueEarlyBirdOgTokenIds,
              }));
            } else {
              setEarlyMintClamable(false);
              setClaimableTokens((prev) => ({ ...prev, earlyMint: [] }));
            }

            if (
              userDetail?.vip_role_status &&
              uniqueOgVipTokenIds?.length >= 2 &&
              !claimedStatuces?.vipAk47 &&
              checkAk47 === 0 &&
              userDetail?.discord_user_id
            ) {
              //check exact og nfts
              setVipAk47MintClamable(true);
              countMintable.vipAk47 = 1;
              setClaimableTokens((prev) => ({
                ...prev,
                vipAk47: uniqueOgVipTokenIds,
              }));
            } else {
              setVipAk47MintClamable(false);
              setClaimableTokens((prev) => ({ ...prev, vipAk47: [] }));
            }

            if (uniqueMalletTokenIds?.length >= 4 && !claimedStatuces?.mallet) {
              //check exact og nfts
              setMaxMalletMintClamable(true);
              countMintable.mallet = 1;
              setClaimableTokens((prev) => ({
                ...prev,
                mallet: uniqueMalletTokenIds,
              }));
            } else {
              setMaxMalletMintClamable(false);
              setClaimableTokens((prev) => ({ ...prev, mallet: [] }));
            }

            setNftNumUserCanMint(countMintable);
          } catch (error) {
            console.log(error, "Error while checking nft claimed conditions.");
          }
        })();
      }
    }

    if (
      rrvCollection &&
      rrvCollection.contractAbi &&
      settings &&
      JSON.stringify(settings) !== "{}"
    ) {
      const web3Intraction = new Web3Intraction(
        rrvCollection.blockchain,
        settings
      );
      if (userDetail?.walletAddress) {
        (async () => {
          try {
            //rrs contract calling
            const contract = await web3Intraction.getContract(
              rrvCollection.contractAbi,
              rrvCollection.contractAddress
            );
            if (!contract) {
              return toast.error(
                `Error while wrong getting robotic villain contract!`
              );
            }
            if (userDetail?.discord_user_id) {
              const data = await checkDiscordClaimedNftsCount();
              if (!data.villain) {
                checkVillainCliamable(userDetail, contract);
              }
            }
          } catch (error) {
            console.log(
              error,
              "Error while checking nft villain claimed conditions."
            );
          }
        })();
      }
    }

    //check nfts records for claim origins nfts
    const conditions =
      settings?.activeCollectionId &&
      settings?.actionWGClaimCollectionId &&
      rrsCollection &&
      rrsCollection.contractAbi &&
      rrwgCollection &&
      rrwgCollection.contractAbi;

    // if (
    //   (conditions) &&
    //   settings &&
    //   JSON.stringify(settings) !== "{}"
    // ) {
    //   const web3Intraction = new Web3Intraction(
    //     rrsCollection?.blockchain || rrwgCollection?.blockchain,
    //     settings
    //   );

    //   if (userDetail?.walletAddress) {
    //     (async () => {

    //       if (!rrsCollection?.contractAddress && !rrwgCollection?.contractAddress) {
    //         return toast.error(`Contract address is missing!`)
    //       }

    //       const contractAddresses = [];
    //       rrsCollection?.contractAddress && contractAddresses.push(rrsCollection.contractAddress);
    //       rrwgCollection?.contractAddress && contractAddresses.push(rrwgCollection.contractAddress);
    //        const params = {
    //           owner : userDetail?.walletAddress,
    //           contractAddresses :contractAddresses
    //        }
    //        try{
    //         // setLoading(true);
    //         const response = await web3Intraction.getNfts(params);
    //         const {ownedNfts,
    //               } = response;
    //               console.log("ownedNfts",ownedNfts)
    //               const nftsAccordingTypes = ownedNfts.reduce((acc, data) => {
    //                 const { tokenId } = data;
    //                 const nft_type = data?.rawMetadata?.nft_type || "og-mint";

    //                 if (!acc[nft_type]) {
    //                   acc[nft_type] = [];
    //                 }

    //                 acc[nft_type].push(tokenId);
    //                 return acc;
    //               }, {});

    //               console.log("nftsAccordingTypes",nftsAccordingTypes);
    //               const nftsSetForOrigin = getFirstNElementsForOrigin(nftsAccordingTypes);
    //               console.log("nftsSetForOrigin",nftsSetForOrigin)
    //               const dbClaimedTokensIds = await getOriginClaimedTokenIds();
    //               console.log("dbClaimedTokensIds",dbClaimedTokensIds);
    //               if(dbClaimedTokensIds){
    //                 const clamableTokenIds = excludeClaimedIdsForOrigin(nftsSetForOrigin,dbClaimedTokensIds);
    //                 //if got db record
    //                 console.log("clamableTokenIds",clamableTokenIds,"dbClaimedTokensIds",dbClaimedTokensIds);
    //               setNftsSetForClaimOrigin(clamableTokenIds);
    //               const checkOriginClaimable = checkEveryTypeHasAtLeastOneElement(clamableTokenIds);
    //               console.log("checkOriginClaimable",checkOriginClaimable)
    //               if(checkOriginClaimable){
    //                  setOriginMintClaimable(true);
    //               }else{
    //                 setOriginMintClaimable(false);
    //               }
    //             }else{
    //               setNftsSetForClaimOrigin(initialNftsSetCalaimOrigin);
    //               setOriginMintClaimable(false);
    //             }
    //         // setLoading(false);
    //        }catch(e){
    //         // setLoading(false);
    //           console.log("Error while getting nft's",e);
    //        }finally{
    //         // setLoading(false);
    //        }
    //     })();
    //   }
    // }
  }, [
    userDetail?.walletAddress,
    JSON.stringify(rrsCollection),
    JSON.stringify(settings),
    JSON.stringify(rrwgCollection),
    JSON.stringify(rrvCollection),
  ]);
  console.log("nftsSetForClaimOrigin", nftsSetForClaimOrigin);
  useEffect(() => {
    checkAllRequiredConditions();
  }, [
    userDetail?.walletAddress,
    JSON.stringify(rrsCollection),
    JSON.stringify(settings),
    JSON.stringify(rrwgCollection),
    JSON.stringify(rrvCollection),
  ]);

  // userDetail?.vip_role_status

  const claimNtsTokenUri = {
    mainnet: "https://rrwg-metadata-live.s3.eu-north-1.amazonaws.com",
    testnet: "https://rrwg-metadata-staging.s3.eu-north-1.amazonaws.com",
  };

  console.log("user details", userDetail);
  const onClaimEarlyBirdNft = async (type) => {
    try {
      let tokenType = null;
      let isVip = false;
      let tokensList = [];
      if (type === "claim-earlybird-mint") {
        if (claimed?.earlyMint) {
          return toast.error("You have already claimed!");
        }

        if (!claimableTokens?.earlyMint?.length) {
          return toast.error("You are not eligible to mint early bird nft!");
        }
        tokenType = 0;
        tokensList = claimableTokens?.earlyMint;
      }
      if (type === "claim-vipak47-mint") {
        if (discordNftCheckError) {
          return toast.error(
            "Not able to recognize ak47 clained record due to some error. please connect with the support team or refresh the page once and try.",
            { autoClose: 4000 }
          );
        }
        if (userDiscordAkAlreadyMinted) {
          return toast.error("You have already claimed!");
        }

        tokenType = 1;
        isVip = true;
        tokensList = claimableTokens?.vipAk47;
      }
      if (type === "claim-maxmallet-mint") {
        if (claimed?.mallet) {
          return toast.error("You have already claimed!");
        }
        if (claimableTokens?.mallet?.length < 4) {
          return toast.error("You are not eligible to mint max mallet!");
        }
        tokenType = 2;
        tokensList = claimableTokens?.mallet;
      }
      const web3Intraction = new Web3Intraction(
        rrwgCollection.blockchain,
        settings
      );

      const PROVIDER = new ethers.providers.Web3Provider(
        wallet.provider,
        web3Intraction.networkUrl
          ? {
              name: web3Intraction.networkUrl.chainName,
              chainId: Number(web3Intraction.networkUrl.chainId),
            }
          : "any"
      );
      console.log("PROVIDER", PROVIDER);
      const SIGNER = PROVIDER.getSigner();
      console.log("SIGNER", SIGNER);
      // console.log(collection, "collection")
      let ethercontract = new Contract(
        rrwgCollection.contractAddress,
        JSON.parse(rrwgCollection.contractAbi),
        SIGNER
      );
      const contract = await web3Intraction.getContract(
        rrwgCollection.contractAbi,
        rrwgCollection.contractAddress
      );
      console.log(contract, "ABIS");

      // setloading(true);

      if (!contract) {
        // setloading(false);
        return toast.error("Service not provided!");
      }
      if (!wallet.account) {
        return toast.error(`Make sure your metamask connected.`);
      }
      if (
        wallet.account &&
        wallet.account?.toLowerCase() ===
          userDetail?.walletAddress?.toLowerCase()
      ) {
        wallet.switchNetwork(
          settings?.blockchainNetworkMode === "testnet" ? 5 : 1
        );
      } else {
        return toast.error(
          `Make sure your metamask connected account is same as primary wallet : ${userDetail?.walletAddress}`
        );
      }

      let tokensIds = [];

      if (type === "claim-earlybird-mint") {
        //dummy token if for now
        tokensIds = tokensList;
      } else {
        tokensIds = [1];
      }

      const nextForMinttokenId = await contract.methods
        ._tokenIdCounter()
        .call();
      // const lastMintedTokenId = parseInt(nextForMinttokenId)  > 0 ? parseInt(nextForMinttokenId) - 1 : 0;
      console.log("nextMintable", parseInt(nextForMinttokenId));
      const mintTokenIds = generateTokenIdArrayAscending(
        parseInt(nextForMinttokenId),
        tokensIds
      );
      console.log("mintedTokenIds", mintTokenIds);
      const tokenUriBase = claimNtsTokenUri[settings.blockchainNetworkMode];
      //created a json tokenUri for metadata.
      console.log("tokenUriBase", tokenUriBase);
      const mintedtTokenUris = mintTokenIds.map(
        (tokenId) => tokenUriBase + "/" + tokenId?.toString() + ".json"
      );

      console.log("mintedtTokenUris", mintedtTokenUris);

      const encodeSignature = web3Intraction.encodedClaimNftSecureData(
        claimNftSigValue,
        claimNftSigPasscode
      );
      console.log("encodeSignature", encodeSignature);

      try {
        setLoadingMint((prev) => ({ ...prev, [type]: true }));
        try {
          console.log("ethercontract", ethercontract);
          const transaction = await ethercontract.ClaimNFT(
            tokenType, //type
            isVip, //vip true/false
            mintedtTokenUris, //tokenUri
            tokensList.map((item) => Number(item)), //tokenids
            encodeSignature
          );

          const tx = await transaction.wait();
          console.log("tx", tx);
          const txHash = tx.transactionHash;

          setLoadingMint((prev) => ({ ...prev, [type]: false }));

          if (txHash) {
            setSuccessinModal(true);
          }

          claimedStatus(userDetail?.walletAddress, contract);

          dispatch(
            postRrwsS3MetadataRequest({
              tokenIds: mintTokenIds,
              nftType: type,
            })
          );
          dispatch(
            postMintActivity(
              {
                type: rrwgCollection.blockchain,
                collection_id: rrwgCollection._id,
                transactionHash: txHash,
                price: rrwgCollection?.cost || 0,
                quantity: mintTokenIds?.length,
                walletAddress: userDetail?.walletAddress,
                mint_type: type,
                token_ids: mintTokenIds,
                discord_user_id: userDetail?.discord_user_id || null,
                claimedForOgTokenIds: tokensList.map((item) => Number(item)),
              },
              () => {
                checkAllRequiredConditions();
              }
            )
          );
        } catch (error) {
          console.log("Fial", error);
          setLoadingMint((prev) => ({ ...prev, [type]: false }));
        }
      } catch (error) {
        console.log("errpr", error);
        setLoadingMint((prev) => ({ ...prev, [type]: false }));
        return toast.error(error.message);
      }

      // setloading(true);
      // setloading(false);
    } catch (err) {
      console.log("err", err);
      // setloading(false);
      if (err.message) {
        return toast.error(err.message);
      }
    } finally {
      // setloading(false);
    }
  };

  const cliamAllNfts = async () => {
    try {
      if (loadingDiscordAk47) {
        return;
      }
      if (claimed?.earlyMint || claimed?.mallet || claimed?.vipAk47) {
        toast.error("You have to claim from cards claim button!", {
          autoClose: 4000,
        });
        return;
      }

      if (discordNftCheckError) {
        return toast.error(
          "Not able to recognize ak47 claimed record due to some error or missing dicord_user_id. please connect with the support team or check your discord and vip role on discord.",
          { autoClose: 4000 }
        );
      }

      let tokensList = claimableTokens.earlyMint;
      let tokenIdsForLength = [];
      const payload = [];

      if (tokensList?.length) {
        tokenIdsForLength = generateNumberArraySteps(
          tokenIdsForLength,
          tokensList.length
        );
        console.log("tokenIdsForLength early bird", tokenIdsForLength);
        payload.push({
          mint_type: "claim-earlybird-mint",
          tokensIds: tokenIdsForLength,
          claimableTokenIds: claimableTokens.earlyMint,
        }); //push if eligible
        // console.log("early push",payload)
      }

      if (claimableTokens?.vipAk47?.length >= 2) {
        console.log("tokenIdsForLength ak47 bird before", tokenIdsForLength);
        tokenIdsForLength = generateNumberArraySteps(tokenIdsForLength, 1);
        console.log("tokenIdsForLength ak47 bird", tokenIdsForLength.slice(-1));
        payload.push({
          mint_type: "claim-vipak47-mint",
          tokensIds: tokenIdsForLength.slice(-1),
          claimableTokenIds: claimableTokens.vipAk47,
        }); //push if eligible
        // console.log("vipak47 push",payload)
      }

      if (claimableTokens?.mallet?.length >= 4 && !claimed.mallet) {
        console.log("tokenIdsForLength max mint before", tokenIdsForLength);

        tokenIdsForLength = generateNumberArraySteps(tokenIdsForLength, 1);
        console.log("tokenIdsForLength max mint", tokenIdsForLength.slice(-1));

        payload.push({
          mint_type: "claim-maxmallet-mint",
          tokensIds: tokenIdsForLength.slice(-1),
          claimableTokenIds: claimableTokens.mallet,
        }); //push if eligible
        // console.log("maxmallet push",payload)
      }

      console.log("payload", payload);
      const web3Intraction = new Web3Intraction(
        rrwgCollection.blockchain,
        settings
      );

      const PROVIDER = new ethers.providers.Web3Provider(
        wallet.provider,
        web3Intraction.networkUrl
          ? {
              name: web3Intraction.networkUrl.chainName,
              chainId: Number(web3Intraction.networkUrl.chainId),
            }
          : "any"
      );
      const SIGNER = PROVIDER.getSigner();
      // console.log(collection, "collection")
      let ethercontract = new Contract(
        rrwgCollection.contractAddress,
        JSON.parse(rrwgCollection.contractAbi),
        SIGNER
      );

      // console.log(collection, "collection")

      const contract = await web3Intraction.getContract(
        rrwgCollection.contractAbi,
        rrwgCollection.contractAddress
      );
      console.log(contract, "ABIS");

      if (!contract) {
        // setloading(false);
        return toast.error("Service not provided!");
      }
      if (!wallet.account) {
        return toast.error(`Make sure your metamask connected.`);
      }
      if (
        wallet.account &&
        wallet.account?.toLowerCase() ===
          userDetail?.walletAddress?.toLowerCase()
      ) {
        wallet.switchNetwork(
          settings?.blockchainNetworkMode === "testnet" ? 5 : 1
        );
      } else {
        return toast.error(
          `Make sure your metamask connected account is same as primary wallet : ${userDetail?.walletAddress}`
        );
      }

      const nextForMinttokenId = await contract.methods
        ._tokenIdCounter()
        .call();
      // const lastMintedTokenId = parseInt(nextForMinttokenId)  > 0 ? parseInt(nextForMinttokenId) - 1 : 0;
      console.log("nextMintable", parseInt(nextForMinttokenId));
      const mintTokenIds = generateTokenIdArrayAscending(
        parseInt(nextForMinttokenId),
        tokenIdsForLength
      );
      console.log("mintedTokenIds", mintTokenIds);
      const tokenUriBase = claimNtsTokenUri[settings.blockchainNetworkMode];
      //created a json tokenUri for metadata.
      console.log("tokenUriBase", tokenUriBase);
      const mintedtTokenUris = mintTokenIds.map(
        (tokenId) => tokenUriBase + "/" + tokenId?.toString() + ".json"
      );

      console.log("mintedtTokenUris", mintedtTokenUris);
      const encodeSignature = web3Intraction.encodedClaimNftSecureData(
        claimNftSigValue,
        claimNftSigPasscode
      );

      setloading(true);
      try {
        const transaction = await ethercontract.ClaimAll(
          mintedtTokenUris, //tokenUri
          true,
          claimableTokens?.earlyMint.map((item) => Number(item)), //tokenids
          encodeSignature
        );

        const tx = await transaction.wait();
        console.log("tx", tx);
        const txHash = tx.transactionHash;

        setloading(false);

        if (txHash) {
          setSuccessinModal(true);
        }

        claimedStatus(userDetail?.walletAddress, contract);

        const updatedPayload = [];
        let indexStartForTokenIds = 0;

        for (let i = 0; i < payload.length; i++) {
          const tokensIds = payload[i]["tokensIds"];
          const mintedtokenIdsPerType = mintTokenIds.slice(
            indexStartForTokenIds,
            indexStartForTokenIds + tokensIds.length
          );
          console.log("startSlice", indexStartForTokenIds);
          console.log("endSlice", indexStartForTokenIds + tokensIds.length);
          console.log("mintedtokenIdsPerType", mintedtokenIdsPerType);
          console.log("payload length", tokensIds.length);

          updatedPayload.push({
            type: rrwgCollection.blockchain,
            collection_id: rrwgCollection._id,
            transactionHash: txHash,
            price: rrwgCollection?.cost || 0,
            quantity: tokensIds.length,
            walletAddress: userDetail?.walletAddress,
            mint_type: payload[i]["mint_type"],
            token_ids: mintedtokenIdsPerType,
            discord_user_id: userDetail?.discord_user_id || null,
            claimedForOgTokenIds: payload[i].claimableTokenIds.map((item) =>
              Number(item)
            ),
          });

          indexStartForTokenIds += tokensIds.length;
        }

        console.log("updatedPayload", updatedPayload);

        dispatch(
          postMintActivity(
            {
              type: rrwgCollection.blockchain,
              activities_type: "multiple",
              activities: updatedPayload,
            },
            () => {
              checkAllRequiredConditions();
              // checkDiscordClaimedNftsCount();
            }
          )
        );

        for (let item of updatedPayload) {
          dispatch(
            postRrwsS3MetadataRequest({
              tokenIds: item.token_ids,
              nftType: item.mint_type,
            })
          );
        }
      } catch (error) {
        console.log("Fial", error);
        setloading(false);
      }
    } catch (err) {
      if (err?.message) {
        return toast.error(err.message);
      }
      setloading(false);
    } finally {
      setloading(false);
    }
  };

  //claim villain nft
  const onClaimVillainNft = async () => {
    try {
      if (!rrvCollection) {
        return toast.error("Villain collection is not found!");
      }
      if (!userDetail?.discord_user_id) {
        return toast.error("Discord user id is missing!");
      }

      if (discordNftCheckError) {
        return toast.error(
          "Not able to recognize villain claimed record due to some error. please connect with the support team or refresh the page once and try.",
          { autoClose: 4000 }
        );
      }
      if (userDiscordVillainAlreadyMinted) {
        return toast.error("You have already claimed!");
      }

      const web3Intraction = new Web3Intraction(
        rrvCollection.blockchain,
        settings
      );

      const PROVIDER = new ethers.providers.Web3Provider(
        wallet.provider,
        web3Intraction.networkUrl
          ? {
              name: web3Intraction.networkUrl.chainName,
              chainId: Number(web3Intraction.networkUrl.chainId),
            }
          : "any"
      );
      console.log("PROVIDER", PROVIDER);
      const SIGNER = PROVIDER.getSigner();
      console.log("SIGNER", SIGNER);
      // console.log(collection, "collection")
      let ethercontract = new Contract(
        rrvCollection.contractAddress,
        JSON.parse(rrvCollection.contractAbi),
        SIGNER
      );
      const contract = await web3Intraction.getContract(
        rrvCollection.contractAbi,
        rrvCollection.contractAddress
      );
      console.log(contract, "ABIS");

      if (!contract && !ethercontract) {
        // setloading(false);
        return toast.error("Service not provided!");
      }
      if (!wallet.account) {
        return toast.error(`Make sure your metamask connected.`);
      }
      if (
        wallet.account &&
        wallet.account?.toLowerCase() ===
          userDetail?.walletAddress?.toLowerCase()
      ) {
        wallet.switchNetwork(
          settings?.blockchainNetworkMode === "testnet" ? 5 : 1
        );
      } else {
        return toast.error(
          `Make sure your metamask connected account is same as primary wallet : ${userDetail?.walletAddress}`
        );
      }

      try {
        setLoadingMint((prev) => ({ ...prev, ["claim-villain"]: true }));
        try {
          console.log("ethercontract", ethercontract);
          const transaction = await ethercontract.mintNFT(
            userDetail?.discord_user_id
          );

          const tx = await transaction.wait();
          console.log("tx", tx);
          const txHash = tx.transactionHash;

          setLoadingMint((prev) => ({ ...prev, ["claim-villain"]: false }));

          if (txHash) {
            setSuccessinModal(true);
          }

          const _tokens = await contract.methods
            .walletOfOwner(userDetail?.walletAddress)
            .call();
          if (_tokens?.length > 0) {
            console.log("_tokens", _tokens);
            const lastestToken = _tokens.slice(-1);
            console.log("getLastestToken", lastestToken);
            dispatch(
              postMintActivity(
                {
                  type: rrvCollection.blockchain,
                  collection_id: rrvCollection._id,
                  transactionHash: txHash,
                  price: rrvCollection?.cost || 0,
                  quantity: 1,
                  walletAddress: userDetail?.walletAddress,
                  mint_type: "claim-villain",
                  token_ids: _tokens,
                  discord_user_id: userDetail?.discord_user_id || null,
                },
                () => {
                  checkAllRequiredConditions();
                }
              )
            );

            //reveal nfts
            dispatch(
              postNftRrcToMainBucketRequest(
                { tokens: lastestToken, type: "claim-villain" },
                (response) => {
                  console.log("response after tokens move", response);
                }
              )
            );
          }
        } catch (error) {
          console.log("Fial", error);
          setLoadingMint((prev) => ({ ...prev, ["claim-villain"]: false }));
        }
      } catch (error) {
        console.log("errpr", error);
        setLoadingMint((prev) => ({ ...prev, ["claim-villain"]: false }));
        return toast.error(error.message);
      }

      // setloading(true);
      // setloading(false);
    } catch (err) {
      console.log("err", err);
      // setloading(false);
      if (err.message) {
        return toast.error(err.message);
      }
    } finally {
      // setloading(false);
    }
  };

  // const onClaimOriginNfts = async () =>{
  //   try {

  //     if(!rroCollection){
  //       return toast.error("Origins collection is not found!");
  //     }

  //     const web3Intraction = new Web3Intraction(
  //       rroCollection.blockchain,
  //       settings
  //     );

  //     const PROVIDER = new ethers.providers.Web3Provider(
  //       wallet.provider,
  //       web3Intraction.networkUrl
  //         ? { name: web3Intraction.networkUrl.chainName, chainId: Number(web3Intraction.networkUrl.chainId) }
  //         : "any"
  //     );
  //     const SIGNER = PROVIDER.getSigner();
  //     // console.log(collection, "collection")
  //     let ethercontract = new Contract(rroCollection.contractAddress, JSON.parse(rroCollection.contractAbi), SIGNER);
  //     const contract = await web3Intraction.getContract(
  //       rroCollection.contractAbi,
  //       rroCollection.contractAddress
  //     );
  //     console.log(contract ,"ABIS");

  //     if (!contract && !ethercontract) {
  //       // setloading(false);
  //       return toast.error("Service not provided!");
  //     }
  //     if(!wallet.account){
  //       return toast.error(`Make sure your metamask connected.`)
  //     }
  //     if (wallet.account && wallet.account?.toLowerCase() === userDetail?.walletAddress?.toLowerCase()) {
  //       wallet.switchNetwork(
  //         settings?.blockchainNetworkMode === "testnet" ? 5 : 1
  //       );
  //     } else {
  //       return toast.error(`Make sure your metamask connected account is same as primary wallet : ${userDetail?.walletAddress}`)
  //     }

  //     const counter = nftsSetForClaimOrigin["og-mint"].length;
  //     if(counter < 1){
  //        return toast.error("You are not able to cliam.");
  //     }
  //   console.log("counter",counter,"nftsSetForClaimOrigin",nftsSetForClaimOrigin)

  //     try {
  //       setLoadingMint((prev)=>({...prev,["claim-origin"]:true}))
  //       try {
  //         console.log("ethercontract",ethercontract)

  //         const transaction = await ethercontract.multiMint(counter);

  //         const tx = await transaction.wait();
  //         console.log("tx", tx);
  //         const txHash = tx.transactionHash;

  //         setLoadingMint((prev)=>({...prev,["claim-origin"]:false}))

  //         if(txHash){
  //             setSuccessinModal(true);
  //         }

  //         const _tokens = await contract.methods
  //           .walletOfOwner(userDetail?.walletAddress)
  //           .call();
  //      if(_tokens?.length > 0){
  //       console.log("_tokens",_tokens);

  //       const originMintedFor = createNewObject(nftsSetForClaimOrigin);
  //       console.log("originMintedFor",originMintedFor);
  //           const lastestToken =  _tokens.slice(-Number(counter));
  //           console.log("getLastestToken",lastestToken);
  //           dispatch(
  //             postMintActivity(
  //               {
  //                 type: rroCollection.blockchain,
  //                 collection_id: rroCollection._id,
  //                 transactionHash: txHash,
  //                 price: rroCollection?.cost || 0,
  //                 quantity: counter,
  //                 walletAddress : userDetail?.walletAddress,
  //                 mint_type : "claim-origin",
  //                 token_ids : lastestToken,
  //                 originClaimedForTokenIds : originMintedFor || [],
  //               },
  //               () => {
  //                 checkAllRequiredConditions();
  //               }
  //             )
  //           );

  //         //   //reveal nfts
  //         dispatch(postNftRrcToMainBucketRequest({tokens : lastestToken, type : "claim-origin"},(response)=>{
  //           console.log("response after tokens move",response);
  //         }));
  //      }

  //       } catch (error) {
  //         console.log("Fial", error)
  //         setLoadingMint((prev)=>({...prev,["claim-origin"]:false}))

  //       }

  //     } catch (error) {
  //       console.log("errpr", error)
  //       setLoadingMint((prev)=>({...prev,["claim-origin"]:false}))
  //       return toast.error(error.message)
  //     }

  //     // setloading(true);
  //     // setloading(false);

  //   } catch (err) {
  //     console.log("err", err);
  //     // setloading(false);
  //     if (err.message) {
  //       return toast.error(err.message);
  //     }
  //   }finally{
  //     // setloading(false);
  //   }
  // }

  return (
    <Fade>
      <SuccessMintModal
        show={successMintModal}
        onhide={() => setSuccessinModal(false)}
      />
      <section className="Nfts_section common-pad">
        <Container>
          <div class="Workshop_content">
            <h1>Claim</h1>
          </div>

          <div className="Claimswrap">
            <div className="d-flex justify-content-between align-items-center border-bottom border-light border-opacity-25 pb-3">
              <h4 className="claim_head">
                <span className="me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="m7.825 13l5.6 5.6L12 20l-8-8l8-8l1.425 1.4l-5.6 5.6H20v2H7.825Z"
                    />
                  </svg>
                </span>
                Claimable NFTs
              </h4>
              {/* {earlyMintClamable &&
              vipAk47MintClamable &&
              maxMalletMintClamable ? (
                <div className="button-wrap">
                  <Button
                    disabled={
                      (!earlyMintClamable &&
                        !vipAk47MintClamable &&
                        !maxMalletMintClamable) ||
                      loadingMint["claim-earlybird-mint"] ||
                      loadingMint["claim-vipak47-mint"] ||
                      loadingMint["claim-maxmallet-mint"] ||
                      loading ||
                      loadingDiscordAk47
                    }
                    className="Claims link_wallet"
                    onClick={() => cliamAllNfts()}
                  >
                    {loading && (
                      <Spinner size="sm" color="light" className="mr-2 me-2" />
                    )}
                    {claimed?.earlyMint && claimed?.mallet && claimed?.vipAk47
                      ? "Claimed All"
                      : "Claim All"}
                  </Button>
                </div>
              ) : null} */}
            </div>

            <div className="claim_inner mt-3">
              <Row>
                {/* {earlyMintClamable &&
                  <Col lg={3} md={6} sm={6}>
                    <div className="nft_cover mb-3 position-relative">
                      <div className="card_img">
                        <div className="claim-nft-count-badge">
                            
                                {nftNumUserCanMint?.earlyMint}
                            
                        </div>
                      <img src="/images/Scythe-diamondframe.png" alt="" className="img-fluid" />
                      </div>
                      <p className="item-name">Early Bird Diamond Scythe</p>
              <div className="button-wrap w-100">

                      <Button   
                      disabled={claimed?.earlyMint || loadingMint["claim-earlybird-mint"] || loadingMint["claim-vipak47-mint"] || loadingMint["claim-maxmallet-mint"]|| loading}
                      onClick={()=>onClaimEarlyBirdNft("claim-earlybird-mint")} className="Claims link_wallet w-100">
                      {loadingMint["claim-earlybird-mint"] && <Spinner size="sm" color="light" className="mr-2 me-2" />}
                       {claimed?.earlyMint ? "Claimed": "Claim"} 
                    </Button>
           </div>
                    </div>
                  </Col>
                } */}
                {/* {vipAk47MintClamable && 
                  <Col lg={3} md={6} sm={6}>
                    <div className="nft_cover mb-3 position-relative">
                      <div className="card_img">
                      <div className="claim-nft-count-badge">
                            
                                {nftNumUserCanMint?.vipAk47}
                          
                        </div>
                      <img src="/images/AK47goldframe.png" alt="" className="img-fluid" />
                      </div>
                      <p className="item-name">VIP Golden AK47</p>
                      

              <div className="button-wrap w-100">

                      <Button  
                      className="Claims link_wallet w-100"
                      disabled={claimed?.vipAk47 || loadingMint["claim-earlybird-mint"] || loadingMint["claim-vipak47-mint"] || loadingMint["claim-maxmallet-mint"]|| loading ||  userDiscordAkAlreadyMinted }
                      onClick={()=>onClaimEarlyBirdNft("claim-vipak47-mint")}
                      >
                        {loadingMint["claim-vipak47-mint"] && <Spinner size="sm" color="light" className="mr-2 me-2" />}
                        {claimed?.vipAk47 || userDiscordAkAlreadyMinted  ? "Claimed": "Claim"}
                    </Button>
                    </div>

                    </div>
                  </Col>
                  } */}
                {/* {maxMalletMintClamable &&
                  <Col lg={3} md={6} sm={6}>
                    <div className="nft_cover mb-3 position-relative">
                      <div className="card_img">
                      <div className="claim-nft-count-badge">
                          
                                {nftNumUserCanMint?.mallet}
                            
                        </div>
                      <img src="/images/MalletMintframe.png" alt="" className="img-fluid" />
                      </div>
                      <p className="item-name">Max Mint Mallet</p>

                      <div className="button-wrap w-100">

                      <Button  
                     disabled={claimed?.mallet || loadingMint["claim-earlybird-mint"] || loadingMint["claim-vipak47-mint"] || loadingMint["claim-maxmallet-mint"]|| loading || loadingCollection}
                      className="Claims link_wallet w-100"
                      onClick={()=>onClaimEarlyBirdNft("claim-maxmallet-mint")}
                      >
                        {loadingMint["claim-maxmallet-mint"] && <Spinner size="sm" color="light" className="mr-2 me-2" />}
                     {claimed?.mallet ? "Claimed": "Claim"}
                      </Button>
                    </div>
                    </div>

                  </Col>
                } */}
                {/* {villainMintClamable && !loadingCollection && !claimedStatusLoading &&
                <Col lg={3} md={6} sm={6}>
                    <div className="nft_cover mb-3 position-relative">
                      <div className="card_img villain_card">
                        <div className="claim-nft-count-badge">
                         1  
                        </div>
                        <img src="/images/Villain.gif" alt="" className="img-fluid" />
                      </div>
                      <p className="item-name">Legendary Villain</p>
                      <Button  
                       disabled={userDiscordVillainAlreadyMinted || loadingMint["claim-villain"]}
                       onClick={()=>onClaimVillainNft()}
                       className="Claims w-100"
                      >
                        {loadingMint["claim-villain"] && <Spinner size="sm" color="light" className="mr-2 me-2" />}
                     {userDiscordVillainAlreadyMinted ? "Claimed": "Claim"}
                        
                      </Button>
                    </div>
                  </Col>
              } */}
                {/* {originMintClamable && !loadingCollection && !claimedStatusLoading &&
                <Col lg={3} md={6} sm={6}>
                    <div className="nft_cover mb-3 position-relative">
                      <div className="card_img villain_card">
                        <div className="claim-nft-count-badge">
                         {nftsSetForClaimOrigin["og-mint"]?.length}
                        </div>
                        <img src="/images/RROrigins.gif" alt="" className="img-fluid" />
                      </div>
                      <p className="item-name">Robotic Rabbit Origins</p>
                      <Button  
                       disabled={loadingMint["claim-origin"]}
                       onClick={()=>onClaimOriginNfts()}
                       className="Claims w-100"
                      >
                        {loadingMint["claim-origin"] && <Spinner size="sm" color="light" className="mr-2 me-2" />}
                        {"Claim"}   
                      </Button>
                    </div>
                  </Col>
              } */}
              </Row>

              {/* {loadingCollection || claimedStatusLoading ? (
                <div className="d-flex align-items-center justify-content-center">
                  <Spinner style={{ color: "#fff" }} />
                </div>
              ) : (
                !earlyMintClamable &&
                !vipAk47MintClamable &&
                !maxMalletMintClamable &&
                !villainMintClamable &&
                !originMintClamable && (
                  <p className="text-center text-white">No NFTs to claim.</p>
                )
              )} */}
              <p className="text-center text-white">No NFTs to claim.</p>
            </div>
          </div>
        </Container>
      </section>
    </Fade>
  );
};

export default Claim;
