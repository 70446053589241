// SectionContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';

const SectionContext = createContext();

export const SectionProvider = ({ children }) => {
  const [activeSection, setActiveSection] = useState(null);
  const [currentScrollYPos, setCurrentScrollYPos] = useState(0);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setCurrentScrollYPos(window.screenY);

    // Define your section IDs
    const sectionIds = ['homesection', 'nfts', 'play_mint', 'team', 'faq', 'footer','workshop'];

    // Find the first section that is currently in view
    const inViewSection = sectionIds.find((sectionId) => {
      const section = document.getElementById(sectionId);

    if(section){
      const cusPos = section === "homesection" ? scrollPosition  :  scrollPosition ;
      const sectionOffsetTop =  section === "homesection" ? section.offsetTop : section.offsetTop;
      const sectionOffserHeight = section === "homesection" ?  section.offsetHeight : section.offsetHeight;
      return (
        section &&
        sectionOffsetTop <= cusPos &&
        cusPos < sectionOffsetTop + sectionOffserHeight
      );

    }
       return false;
    });

    setActiveSection(inViewSection);
  };

  useEffect(() => {
    // Initial call to set active section
    handleScroll();

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const value = {
    activeSection,
    currentScrollYPos
  };

  return <SectionContext.Provider value={value}>{children}</SectionContext.Provider>;
};

export const useSection = () => {
  const context = useContext(SectionContext);
  if (!context) {
    throw new Error('useSection must be used within a SectionProvider');
  }
  return context;
};
