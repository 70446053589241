import UpdateNftName from "Component/Modals/UpdateNftModal";
import { claimNftsTypes, getNetworkUrl } from "helpers/constants";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Fade } from "react-reveal";
import { getCollection } from "store/actions";
import LazyLoad from 'react-lazyload';
import { getNftsList } from "helpers/backend_helper";
import SpecialPowerModal from "Component/Modals/SpecialPowerModal";
import InfiniteScroll from "react-infinite-scroll-component";
import useWallet from "hooks/wallet";
import WeaponsAndGearModal from "Component/Modals/WeaponsGearModal";
import { useNavigate } from "react-router-dom";


const Workshop = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { settings } = useSelector((state) => state.Setting);
  const { user } = useSelector((state) => state.Login);
  const [nftsList,setNftsList] = useState([]);
  const [loading,setLoading] = useState(null);
  const [userDetail, setUserDetails] = useState();
  const [rrsCollection,setRRSCollection] = useState(null);
  const [showSpecialPowerModal,setShowSpecialPowerModal] = useState(false);
  const [showWeaponsAndGearModal,setShowWeaponsAndGearModal] = useState(false);
  const [nftData,setNftData] = useState(null)
  const [filters,setFilters] = useState({
    collectionId:"",
    walletAddress:"",
    types : ["og-mint"],
    limit : 50,
    page : 1
  })
  const [totalCount,setTotalCount] = useState(0);
  const wallet = useWallet();

  useEffect(() => {
    if (user) {
      setUserDetails({ ...user, walletAddress: user?.walletId?.walletAddress });
      setFilters((pre)=>({...pre,walletAddress : user?.walletId?.walletAddress}));
    }
  }, [user]);
   
  
useEffect(async()=>{
  try {
    if (wallet.account) return;
    console.log("cliecked");
    await wallet.initializeOnBoard();
    
    if (!wallet.account && settings?.blockchainNetworkMode && JSON.stringify(settings) !== "{}") {
      const networkUrl = getNetworkUrl("base", settings);
    console.log("networkUrl onConnectMetamask",networkUrl);
      wallet.connect(
        "metamask",
        networkUrl.chainId 
      );
      
    }
  } catch (err) {}
},[wallet.account]);


  function generateOpenSeaUrl(network, contractAddress, tokenId) {
    const baseUrl = network === 'mainnet' ? 'https://opensea.io' : `https://testnets.opensea.io`;
    const networkType = network === "mainnet" ? "ethereum" : "sepolia"; 
    console.log("networkType",networkType);
    console.log("network",network)
    return `${baseUrl}/assets/${networkType}/${contractAddress}/${tokenId}`;
  }

  function formatNFTData(nftDataArray) {
    const groupedNFTs = nftDataArray?.reduce((acc, nft) => {
        const opensea_url =  generateOpenSeaUrl(settings?.blockchainNetworkMode,nft?.collection?.contractAddress?.toLowerCase(),nft?.token_id);
        nft.opensea_url = opensea_url || null;
      
            acc.push({
                ...nft
            });
  
        return acc;
    }, []);

    return groupedNFTs;
}

  useEffect(()=>{
    const fetchData = async() => {
      try{
        setLoading(true);
        console.log("outside")
        if(filters?.collectionId && filters?.walletAddress){
          console.log("inside")
          const response = await getNftsList(filters);
          console.log("response",response);  
          if(response?.status === "success"){
            let data = formatNFTData(response.data)
             data = filters.page > 1 ? [...nftsList,...data] : data;
            data = formatNFTData(data)
            console.log("data",data)
            setNftsList(data);
            setTotalCount(response.totalCount)
          }
          setLoading(false)
         }
      }catch(err){
        setLoading(false);
      }finally{
        setLoading(false);
      }
     
    }

    fetchData();
   
  },[filters]);


  useEffect(() => {
    if (settings && settings.activeCollectionId) {
      dispatch(getCollection(settings.activeCollectionId,(data)=>{
        setRRSCollection(data);
        setFilters((pre)=>({...pre,collectionId : settings.activeCollectionId}));
      }));
    }
  }, [JSON.stringify(settings)]);
  
  const fetchMore = () =>{
    if(loading) return;
     setFilters((pre)=>({...pre, page : pre.page + 1}))
  }

  return (
    <Fade>
      
      <section className="Nfts_section common-pad">
      {showSpecialPowerModal ? 
        <SpecialPowerModal
        nftData={nftData}
        show={showSpecialPowerModal}
        userDetail={userDetail}
        onhide={() => {
          setShowSpecialPowerModal(false)
          setNftData(null);
        }}
      />
      :null
      }
       {showWeaponsAndGearModal ? 
        <WeaponsAndGearModal
        nftData={nftData}
        show={showWeaponsAndGearModal}
        userDetail={userDetail}
        onhide={() => {
          setShowWeaponsAndGearModal(false)
          setNftData(null);
        }}
      />
      :null
      }
        <Container>
          <div class="Workshop_content">
            <h1>Workshop</h1>
          </div>

          <InfiniteScroll
                  dataLength={nftsList?.length || 0}
                  next={fetchMore}
                  // style={{maxHeight: }}
                  hasMore={nftsList?.length < totalCount}
                  scrollThreshold={0}
                  // scrollableTarget="msg"
                  style={{overflow : "hidden"}}
                  loader={
                      <div className="collection mx-auto mt-3" style={{ maxWidth: 1000 }}>
                          {loading && filters.page === 2 && (
                              <div className="col-span-12 flex justify-center items-center">
                                  <Spinner size="md" />
                              </div>
                          )}
                      </div>
                  }
              >
           <div className="nft_slider mt-5">

            <Row>
              {nftsList && nftsList?.map((item, idx) => {
                // const specialPowerValue = item?.metadata?.attributes.find((attribute) =>attribute.trait_type === "Special Power")?.value;

                // const weaponAndGearValue = item?.metadata?.attributes.find((attribute) =>attribute.trait_type === "Weapons and Gear")?.value;

                // const isEquipedWeapon =  (item?.weaponsAndGearDetails && item?.weaponsAndGearDetails?.equipment_status === "equiped" || !item?.weaponsAndGearDetails);
                // const isEquipedPower =  (item?.specialPowerDetails && item?.specialPowerDetails?.equipment_status === "equiped" || !item?.specialPowerDetails);
                // const showPowerBtn = (specialPowerValue && specialPowerValue !== "None")  || item?.specialPowerDetails;
                // const showGearBtn =  (weaponAndGearValue && weaponAndGearValue !== "None") || item?.weaponsAndGearDetails;
                
                return (
                  <Col lg={3} md={6} sm={6}  key={idx+"og-nft"} onClick={()=>navigate(`/workshop/item/${item?._id}`)}>
                    <div className="nft_cover mb-3 position-relative">
                      <div className= {`card_img`}>
                    
                      <LazyLoad debounce>
                        <img 
                            key={idx+"og-nft"}
                            src={`${item?.metadata?.image}?t=${new Date().getTime()}`}
                            alt="" 
                            className="img-fluid lazy-media" 
                            // onLoadedData={handleImageLoad}
                            // onError={handleImageLoad}
                        />
                      </LazyLoad>
                      </div>
                      <div className="d-flex justify-content-between align-items-start mt-2">
                      <p className="item-name">{item?.metadata?.name}</p>
                      </div>        
                    </div>
                  </Col>
                );
              })}
              {!loading && !nftsList?.length
              ? 
               <p className="text-center text-light">No record found.</p>
              : 
              loading && !nftsList?.length && 
              <div className="d-flex justify-content-center text-light">
                  <Spinner color="#fff"/>
               </div>
              }
              </Row>
          </div>
          </InfiniteScroll>  
        </Container>
      </section>
    </Fade>
  );
};

export default Workshop;
