export const ITEM_API_FAIL = "ITEM_API_FAIL";

// Mint Acitvity
export const POST_MINT_ACTIVITY = "POST_MINT_ACTIVITY";
export const POST_MINT_ACTIVITY_FAIL = "POST_MINT_ACTIVITY_FAIL";
export const POST_MINT_ACTIVITY_SUCCESS = "POST_MINT_ACTIVITY_SUCCESS";

// Item List
export const GET_ITEMS = "GET_ITEMS";
export const GET_ITEMS_FAIL = "GET_ITEMS_FAIL";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";

export const NFTS_RRC_TO_MAINBUCKET_REQUEST = "NFTS_RRC_TO_MAINBUCKET_REQUEST";
export const NFTS_RRC_TO_MAINBUCKET_SUCCESS = "NFTS_RRC_TO_MAINBUCKET_SUCCESS";
export const NFTS_RRC_TO_MAINBUCKET_FAIL = "NFTS_RRC_TO_MAINBUCKET_FAIL";

export const GET_USER_MINTED_NFTS_COUNT_REQUEST =
  "GET_USER_MINTED_NFTS_COUNT_REQUEST";
export const GET_USER_MINTED_NFTS_COUNT_SUCCESS =
  "GET_USER_MINTED_NFTS_COUNT_SUCCESS";
export const GET_USER_MINTED_NFTS_COUNT_FAIL =
  "GET_USER_MINTED_NFTS_COUNT_FAIL";

export const UPDATE_USER_NFT_METADATA_REQUEST =
  "UPDATE_USER_NFT_METADATA_REQUEST";
export const UPDATE_USER_NFT_METADATA_SUCCESS =
  "UPDATE_USER_NFT_METADATA_SUCCESS";
export const UPDATE_USER_NFT_METADATA_FAIL = "UPDATE_USER_NFT_METADATA_FAIL";

export const RRWS_TOKENIDS_S3_METADATA_REQUEST =
  "RRWS_TOKENIDS_S3_METADATA_REQUEST";
export const RRWS_TOKENIDS_S3_METADATA_SUCCESS =
  "RRWS_TOKENIDS_S3_METADATA_SUCCESS";
export const RRWS_TOKENIDS_S3_METADATA_FAIL = "RRWS_TOKENIDS_S3_METADATA_FAIL";

export const GET_DISCORD_AK_NFT_COUNT_REQUEST =
  "GET_DISCORD_AK_NFT_COUNT_REQUEST";
export const GET_DISCORD_AK_NFT_COUNT_SUCCESS =
  "GET_DISCORD_AK_NFT_COUNT_SUCCESS";
export const GET_DISCORD_AK_NFT_COUNT_FAIL = "GET_DISCORD_AK_NFT_COUNT_FAIL";

export const GET_USER_GAME_MINT_REQUEST = "GET_USER_GAME_MINT_REQUEST";
export const GET_USER_GAME_MINT_SUCCESS = "GET_USER_GAME_MINT_SUCCESS";
export const GET_USER_GAME_MINT_FAIL = "GET_USER_GAME_MINT_FAIL";
