import React, { useEffect, useRef } from "react";
import TermsAndConditions from "Pages/TermsAndConditions";
import Footer from "Component/Footer/Footer";
import { ImageProvider, useImage } from "Context/ImageContext";


function TermsPage() {
  // const childProgressRef = useRef();

  const { setShowProgress } = useImage();
  useEffect(()=>{
    setShowProgress(true);
    setTimeout(()=> setShowProgress(false),2000)
  },[])


  return (
    <>
      <div className="welcome-body">
        <TermsAndConditions />
        <Footer extraclass="welcomefooter footer-main" />
      </div>
    </>
  );
}


export default function TermsTheme() {
  return (
  <ImageProvider>
      <TermsPage />
  </ImageProvider>
  );
}
