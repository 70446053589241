import UpdateNftName from "Component/Modals/UpdateNftModal";
// import { useImage } from "Context/ImageContext";
import { getProfile } from "helpers/backend_helper";
import { claimNftsTypes, getNetworkUrl } from "helpers/constants";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner, Button, Tab, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Fade } from "react-reveal";
import { toast } from "react-toastify";
import { getCollection, updateUserNftMetadataRequest } from "store/actions";
import Web3Intraction from "util/web3Intraction";
import LazyLoad from 'react-lazyload';
import useWallet from "hooks/wallet";
import { useNavigate } from "react-router-dom";


const ArmorsTab = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.Setting);
  const { user } = useSelector((state) => state.Login);
  const { loadingUpdateNft } = useSelector(state => state.Items);
  const [pageKey, setPageKey] = useState(undefined);
  const [nftsList, setNftsList] = useState(0);
  const [loading, setLoading] = useState(null);
  const [userDetail, setUserDetails] = useState();
  const [armorsCollection, setArmorsCollection] = useState(null);
  const [showUpdateNftModal, setShowUpdateNftModal] = useState({
    nftData: "",
    show: false
  });
  const [carrotectNftsCount, setCarrotecNftsCount] = useState(0);
  const navigate = useNavigate();
  const wallet = useWallet();

  // const {handleImageLoad,handleHideProgress} = useImage();

  useEffect(() => {
    if (user) {
      setUserDetails({ ...user, walletAddress: user?.walletId?.walletAddress });
    }
  }, [user])


  useEffect(() => {
    if (settings && settings.activeCollectionId) {
      if (settings && settings.activeArmorsCollectionId) {
        dispatch(getCollection(settings.activeArmorsCollectionId, (data) => {
          setArmorsCollection(data);
        }));
      }
    }
  }, [JSON.stringify(settings)]);

  function generateOpenSeaUrl(network, contractAddress, tokenId) {
    const baseUrl = network === 'mainnet' ? 'https://opensea.io' : `https://testnets.opensea.io`;
    const networkType = network === "mainnet" ? "base" : "base-sepolia";
    console.log("networkType", networkType);
    console.log("network", network)
    return `${baseUrl}/assets/${networkType}/${contractAddress}/${tokenId}`;
  }

  function formatNFTData(nftDataArray) {
    const groupedNFTs = nftDataArray?.reduce((acc, nft) => {
      const opensea_url = generateOpenSeaUrl(settings?.blockchainNetworkMode, nft?.contract?.address?.toLowerCase(), nft?.tokenId);
      nft.opensea_url = opensea_url || null;

      acc.push({
        ...nft
      });

      return acc;
    }, []);

    return groupedNFTs;
  }


  useEffect(() => {
    if (
      (armorsCollection &&
        armorsCollection?.contractAbi) &&
      settings &&
      JSON.stringify(settings) !== "{}"
    ) {
      const web3Intraction = new Web3Intraction(
        armorsCollection?.blockchain,
        settings
      );
      setNftsList([]);
      if (userDetail?.walletAddress) {
        (async () => {

          if (!armorsCollection?.contractAddress) {
            return toast.error(`Contract address is missing!`)
          }

          const contract = await web3Intraction.getContract(
            armorsCollection.contractAbi,
            armorsCollection.contractAddress
          );
          console.log("armory contract", contract)
          if (!contract) {
            return;
          }

          const contractAddresses = [];
          armorsCollection?.contractAddress && contractAddresses.push(armorsCollection.contractAddress);

          const params = {
            owner: userDetail?.walletAddress,
            contractAddresses: contractAddresses,
            pageKey: undefined,
            blockchain: armorsCollection.blockchain
          }
          try {
            setLoading(true);
            console.log("params", params)
            const response = await web3Intraction.getNfts(params);
            const { ownedNfts,
              pageKey,
              totalCount } = response;
            console.log("ownedNfts", ownedNfts)
            let contractNfts = (formatNFTData(ownedNfts, settings?.blockchainNetworkMode) || []);
            console.log("contractNfts", contractNfts)
            setNftsList(contractNfts);

            const networkUrl = getNetworkUrl("base", settings);
            console.log("networkUrl", networkUrl);
            let chainID = networkUrl?.chainId;
            if (
              wallet.account &&
              wallet.account?.toLowerCase() ===
              userDetail?.walletAddress?.toLowerCase()
            ) {

              await wallet.switchNetwork(
                chainID
              );

            }
            //38 is carrotec tokenIF
            const carrotechNfts = await contract.methods.balanceOf(wallet.account, 38).call();
            console.log("carrotechNfts", carrotechNfts);
            setCarrotecNftsCount(carrotechNfts);

            setLoading(false);

          } catch (e) {
            setLoading(false);

            console.log("Error while getting nft's", e);
          } finally {
            setLoading(false);
            // handleHideProgress();
          }
        })();
      } else {
        // handleHideProgress();
      }
    }
  }, [
    wallet.account,
    userDetail?.walletAddress,
    JSON.stringify(armorsCollection),
    JSON.stringify(settings),
  ]);

  const downloadNft = (url, fileName) => {
    if (url) {
      try {
        var linkElement = document.createElement('a');
        linkElement.id = 'link';
        window.document.body.appendChild(linkElement);
        var link = document.getElementById('link');
        link.setAttribute('href', url);
        link.setAttribute('target', "_blank");
        link.setAttribute('download', fileName); // Set the desired file name
        link.click();
      } catch (err) {
        console.log("Error", err);
      }
    }
  }

  function isValidName(name) {
    // Regular expression to check for the presence of numbers
    var regex = /\d/;

    // Test the name against the regular expression
    if (regex.test(name)) {
      return false; // Name contains numbers
    } else {
      return true; // Name is valid
    }
  }


  const onUpdateNftName = (name) => {
    if (!isValidName(name)) {
      return toast.error("Please enter name without number and special character.token id will automatic adjust");
    }
    dispatch(updateUserNftMetadataRequest({
      name: name,
      tokenId: showUpdateNftModal?.nftData?.tokenId,
      type: "og_mint"
    }, () => {
      setShowUpdateNftModal({
        nftData: null,
        show: false
      });
      window.location.reload();
      //  setNftsList((prev)=> prev?.map((item)=> item?.tokenId === showUpdateNftModal?.nftData?.tokenId ? {...item,rawMetadata:{...item.rawMetadata, name : name}} : item))

    }))
  }

  return (
    <Fade>
      <section className="Nfts_section common-pad">
        <Container>
          <UpdateNftName
            loading={loadingUpdateNft}
            onUpdate={onUpdateNftName}
            show={showUpdateNftModal?.show}
            name={showUpdateNftModal?.nftData?.rawMetadata?.name}
            onhide={() => {
              setShowUpdateNftModal({
                show: false,
                nftData: null
              })
            }} />
          <div class="Workshop_content">

            {/* <h1>Armory</h1> */}
            {/* <p>Under Construction</p> */}
            {/* <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </p> */}
          </div>

          <div className="nft_slider mt-5">
            <Row>
              {nftsList && nftsList?.map((item, idx) => {
                console.log("nft item", item)

                const fileExt = item?.rawMetadata?.image?.split('.').pop();
                const filename = item?.tokenId + fileExt;
                return (
                  <Col lg={3} md={6} sm={6} key={idx + "og-nft"}>
                    <div className="nft_cover mb-3 position-relative">
                      <div className={`${item?.nft_type === "claim-villain" ? "villain_card" : ""} card_img`}>

                        <div className="claim-nft-count-badge">
                          {item?.balance}
                        </div>

                        <LazyLoad debounce>
                          <img
                            key={idx + "og-nft"}
                            src={item?.rawMetadata?.image}
                            alt=""
                            className="img-fluid lazy-media"

                          />
                        </LazyLoad>
                      </div>
                      <div className="d-flex justify-content-between align-items-start mt-2">
                        <p className="item-name">{item?.rawMetadata?.name}</p>
                      </div>

                      <div className="btn_openseawrap mt-3">
                        <div className="button-wrap">

                          <Button target="_blank" href={item?.opensea_url} className="btn_openseas">Opensea</Button>
                        </div>

                        <Button onClick={() => downloadNft(item?.rawMetadata?.image, filename)} className="btn_download">
                          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 32 32"><path fill="currentColor" d="M15 4v16.563L9.72 15.28l-1.44 1.44l7 7l.72.686l.72-.687l7-7l-1.44-1.44L17 20.564V4h-2zM7 27v2h18v-2H7z" /></svg>
                        </Button>
                      </div>

                    </div>
                  </Col>
                );
              })}
              {!loading && !nftsList?.length
                ?
                <p className="text-center text-light">No record found.</p>
                :
                loading && !nftsList?.length &&
                <div className="d-flex justify-content-center text-light">
                  <Spinner color="#fff" />
                </div>
              }
            </Row>

          </div>
        </Container>
      </section>
    </Fade>
  );
};

export default ArmorsTab;
