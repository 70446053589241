import React, { useEffect } from "react";
import Forgotpass from "Pages/Forgotpass";
import { ImageProvider, useImage } from "Context/ImageContext";

const ForgotpassPage = () => {

  const { setShowProgress,setHideTimeOut } = useImage();
  useEffect(()=>{
    setShowProgress(true);
    setHideTimeOut(4000);
  },[])

  return (
    <React.Fragment>
        <Forgotpass/>
    </React.Fragment>
  );
};

const Forgotpasstheme = () =>{

  return(
     <ImageProvider>
        <ForgotpassPage/>
     </ImageProvider>
  );
}

export default Forgotpasstheme;
