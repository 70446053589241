export const GET_WALLETS = "GET_WALLETS";
export const GET_WALLETS_FAIL = "GET_WALLETS_FAIL";
export const GET_WALLETS_SUCCESS = "GET_WALLETS_SUCCESS";

export const ADD_WALLET = "ADD_WALLET"
export const ADD_WALLET_FAIL = "ADD_WALLET_FAIL"
export const ADD_WALLET_SUCCESS = "ADD_WALLET_SUCCESS"

export const EDIT_WALLET = "EDIT_WALLET"
export const EDIT_WALLET_FAIL = "EDIT_WALLET_FAIL"
export const EDIT_WALLET_SUCCESS = "EDIT_WALLET_SUCCESS"

export const DELETE_WALLET = "DELETE_WALLET"
export const DELETE_WALLET_FAIL = "DELETE_WALLET_FAIL"
export const DELETE_WALLET_SUCCESS = "DELETE_WALLET_SUCCESS"