//REGISTER
export const POST_REGISTER_WITH_EMAIL = "/user/register";
// LOGIN
export const POST_LOGIN_WITH_EMAIL = "/user/login";
export const POST_LOGIN = "/user/login-with-wallet";
export const GET_SETTINGS = "/setting/view/";
export const GET_CURRENT_USER = "/user/profile";
export const UPDATE_PROFILE = "/user/updateProfile";
export const LINK_WALLET = "/user/link-wallet";
export const UNLINK_WALLET = "/user/unlink-wallet";
// File
export const POST_FILE = "/file/add";
export const POST_IPFS_FILE = "/file/ipfs";

///  wishlist
export const POST_WHITELIST = "/user/whitelist-request";

// contents API's
export const GET_TEAMS = "team/list";
export const GET_FAQS = "/faq/list ";
export const GET_ROADMAP = "/roadmap/list";

//collection details api
export const GET_COLLECTION = "/collection/view";

export const POST_MINT_ACTIVITY = "/item/user/mint-activity";
export const GET_ITEMS = "/item/list";
export const DISCORD_DATA = "/discord/auth";
export const DISCORD_USER_DATA = "/discord/callback";
export const UNLINK_DISCORD = "/discord/unlink";

//wallets APIs
export const GET_WALLETS = "/user/wallet-list";
export const ADD_WALLET = "/user/add-wallet";
export const EDIT_WALLET = "/user/update-wallet";
export const DELETE_WALLET = "/user/delete-wallet";

//profile image upload
export const POST_IMAGE_UPLOAD = "/user/updateProfileImage";
export const POST_CHANGE_PASSWORD = "/user/changePassword";

export const REDIRECT_URL_DISCORD_LOGIN = "/discord/authenticate";
export const POST_DISCORD_LOGIN = "/discord/login";

export const NFT_COPY_RRCTOMAIN = "/item/copy-tokens";

//forgot password

export const FORGOT_PASSWORD = "/user/forgotPassword";
export const RESET_PASSWORD = "/user/resetPassword";

export const GET_USER_MINTED_NFTS_COUNT = "/item/user/minted-nfts-count";

export const UPDATE_USER_NFT_METADATA = "/item/user/nft-metadata/update";

export const POST_RRWS_TOKENIDS_S3METADATA = "/item/rrwsTokenIdS3Metadata";

export const GET_DISCORD_AK_NFT_COUNT = "/item/get-discord-ak47-claimed-nft";

export const GET_OG_CLAIMED_TOKENIDS = "/item/get-unique-claimed-og-tokens";

export const VERIFY_DISCORD_USER = "/discord/verify-discord-user";

export const GET_ORIGIN_CLAIMED_TOKENIDS =
  "/item/get-origin-claimed-for-tokenIds";
export const POST_DIRECT_MINT_ACTIVITY = "/item/user/direct-mint-activity";

export const GET_USER_GAME_MINT_DETAIL = "/item/game-user-mint-details";

export const CREATE_GAME_VALIDATOR_SIGN =
  "/collection/create-game-validator-sign";

//Contact Us
export const POST_CONTACT_US =  "/contact/user-request";

export const GET_CONTENT = "/content/list";
export const GET_NFTS_BASIC_DETAILS = "/item/nfts-basic-details";
export const GET_NFTS_LIST = "/item/list";
export const GET_NFT_DETAILS = "/item/getNftDetails"
export const GET_NFT_METADATA_DETAIL_BY_NAME = "/item/nft-metadata-details-by-name";
export const EQUIP_UNEQUIP_POWER = "/item/equip-unequip-power";
export const PREVIEW_NFT_BEFORE_MERGE = "/item/preview-before-merge-nft";
export const EQUIP_OTHER_TRAITS = "/item/equipOtherTraits";
export const POST_ARMORS_ACTIVITY = "/item/user/armors-mint-activity";
export const GET_NFT_METADATA_BY_TOKENID = "/item/user/nft-metadata";
export const CREATE_VALIDATOR_SIGN =
  "/collection/create-validator-sign";