import moment from "moment";
import { createValidatorSign } from "./backend_helper";


export const createValidatorSigning = async (data) =>{
    return new Promise(async(resolve, reject)=>{
        try{
           const response = await createValidatorSign(data);
           resolve(response.data);
        }catch(e){
         reject(null)
        }
    })
 }

export const createVoucher = async (
    roboticTokenId,
    tokenId,
    buyer,
    contractAddress,
    chainId
  ) => {
    try {
     
      const mintTimestamp = moment().unix();
     
      const voucher = { roboticTokenId, tokenId, mintTimestamp, buyer };
   
      console.log("voucher", voucher);
   
      const signature = await createValidatorSigning(
        {...voucher,contractAddress,chainId:parseInt(chainId)}
      );
   
      console.log("valid", signature);

      if(!signature){
        return null;
      }

      return {
          ...voucher,
          signature
      };
    } catch (err) {
      console.log("eeror in voucher", err);
      return null;
    }
  };



  export async function fetchJsonData(url) {
    try {
      const response = await fetch(url);
      
      // Check if the response status is OK (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      // Parse and return the JSON data
      const data = await response.json();
      return data;
      
    } catch (error) {
      console.error('Error fetching JSON data:', error);
      throw error; // Re-throw the error if you need to handle it further
    }
  }