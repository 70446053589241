import Web3 from "web3";
import { ContractFactory, Contract, ethers } from "ethers";

// Constant
import {
  getNetworkUrl,
  getContractDetails,
} from "helpers/constants";

class Web3Intraction {
  constructor(blockchain, provider, settings) {
    const networkUrl = getNetworkUrl(blockchain || "ethereum", settings);

    if (provider) {
      this.PROVIDER = new ethers.providers.Web3Provider(
        provider,
        networkUrl
          ? { name: networkUrl.chainName, chainId: Number(networkUrl.chainId) }
          : "any"
      );
      this.SIGNER = this.PROVIDER.getSigner();
    }

    this.settings = settings;
    this.networkUrl = networkUrl;
    this.adminContractSetting = getContractDetails(
      blockchain || "ethereum",
      settings
    );
  }

  convertPriceToEther = (price) => {
    return ethers.utils.parseEther(price?.toString())._hex;
    // return Web3.utils.toWei(Number(price).toFixed(8), "ether")
  };

  getContract = (abi, address) => {
    try {
      let contract = new Contract(address, abi, this.SIGNER);
      // console.log(this.SIGNER, "this.SIGNER");
      return contract;
    } catch (error) {
      console.log("error", error);
      return null;
    }
  };

  /**
   * Deploy collection contract.
   *
   * @param {object} collectionData Collection Details (ie. abi, bytecode)
   * @param {function} callback Callback function
   *
   * @returns {Promise} Object (Transaction Hash, Contract Address) in Success or Error in Fail
   */
  deployContract = (collection, callback = null) => {
    return new Promise(async (resolve, reject) => {
      try {
        const factory = new ContractFactory(
          JSON.parse(collection.data.abi),
          collection.data.bytecode,
          this.SIGNER
        );

        const contract = await factory.deploy();
        // console.log(contract, "contract");
        let receipt = await contract.deployTransaction.wait();

        // console.log(receipt, "receipt");
        callback &&
          callback(null, { txHash: receipt.transactionHash, receipt });
        resolve({ txHash: receipt.transactionHash, receipt });
      } catch (error) {
        callback && callback(error.message);
        reject(error.message);
      }
    });
  };

  /**
   * Check user approved contract transactions, if not then make transaction to approve.
   *
   * @param {string} userWallet Current user wallet address
   * @param {object} collectionData Collection Details (ie. abi, contract address, bytecode)
   * @param {function} callback Callback function
   *
   * @returns {Promise} Success for approved or Fail for error
   */
  verifyApproved = (userWallet, collection, callback = null) => {
    return new Promise(async (resolve, reject) => {
      if (collection.abi && collection.data.contractAddress) {
        const contract = this.getContract(
          collection.abi,
          collection.data.contractAddress
        );

        if (!contract) {
          const error_message = "Invalid Contract";
          callback && callback(error_message);
          reject(error_message);
          return;
        }

        const isApproved = await contract.isApprovedForAll(
          userWallet,
          this.settings.walletAddress.publicKey
        );
        // console.log(isApproved, "isApproved");
        if (isApproved) {
          // console.log(collection, "<====collection");
          callback && callback(null, null);
          resolve(collection);
          return;
        }

        try {
          const transaction = await contract.setApprovalForAll(
            this.settings.walletAddress.publicKey,
            true
          );
          // console.log(transaction, "<===transaction");
          callback && callback(null, transaction);

          const receipt = await transaction.wait();

          console.log(receipt, "<===receipt");

          callback && callback(null, receipt);
          resolve(receipt);
        } catch (error) {
          callback && callback(error.message);
          reject(error.message);
        }
      } else {
        const error_message = "No Collection Data!";
        callback && callback(error_message);
        reject(error_message);
      }
    });
  };

  /**
   * Mint NFT
   *
   * @param {string} userWallet Current user wallet address
   * @param {object} collectionData Collection Details (ie. abi, contract address, bytecode)
   * @param {object} itemData (NFT) Item details
   * @param {function} callback Callback function
   *
   * @returns {Promise} Receipt in Success or Error in Fail
   */
  // mintNFT = (userWallet, token_uri, callback = null) => {
  //   return new Promise(async (resolve, reject) => {
  //     if (this.adminContractSetting.abi && this.adminContractSetting.contractAddress) {
  //       const contract = this.getContract(
  //         this.adminContractSetting.abi,
  //         this.adminContractSetting.contractAddress
  //       );

  //       if (!contract) {
  //         const error_message = "Invalid Contract";
  //         callback && callback(error_message);
  //         reject(error_message);
  //         return;
  //       }

  //       try {
  //         const transaction = await contract.mintNFT(
  //           // userWallet,
  //           token_uri
  //         );
  //         const receipt = await transaction.wait();

  //         if (!!receipt?.logs && !!receipt.logs[0]) {
  //           receipt.token_id = Web3.utils.hexToNumberString(
  //             receipt.logs[0].topics[3]
  //           );
  //         }
  //         callback && callback(null, receipt);
  //         resolve(receipt);
  //       } catch (error) {
  //         callback && callback(error);
  //         reject(error);
  //       }
  //     }
  //   });
  // };

  mintNFT = (token_uri, callback = null) => {
    return new Promise(async (resolve, reject) => {
      if (this.adminContractSetting.abi && this.adminContractSetting.contractAddress) {
        const contract = this.getContract(
          this.adminContractSetting.abi,
          this.adminContractSetting.contractAddress
        );

        if (!contract) {
          const error_message = "Invalid Contract";
          callback && callback(error_message);
          reject(error_message);
          return;
        }

        try {
          // console.log(contract, "contract");
          // console.log(token_uri, "token_uri");

          const transaction = await contract.mintNFT(token_uri);
          let receipt = await transaction.wait();
          console.log(receipt, "receipt");
          let customReceipt = [];

          if (!!receipt?.logs && !!receipt.logs[0]) {
            let logData = [...receipt.logs];
            logData?.pop();
            customReceipt = logData?.map((data, i) => {
              return {
                token_id: Web3.utils.hexToNumberString(data.topics[3]),
                transactionHash: data.transactionHash,
              };
            });
            // receipt.token_id = Web3.utils.hexToNumberString(
            //   receipt.logs[0].topics[3]
            // );
          }

          callback && callback(customReceipt);
          resolve(customReceipt);
        } catch (error) {
          callback && callback(error);
          reject(error);
        }
      }
    });
  };

  /*/////////////////////////////// */

  /**
   * Transfer balance to NFT owner account
   *
   * @param {object} itemData (NFT) Item details
   * @param {function} callback Callback function
   *
   * @returns {Promise} Receipt in Success or Error in Fail
   */
  sendTransaction = (price, callback = null) => {
    return new Promise(async (resolve, reject) => {
      // const adminContract = this.getContract(
      //   this.adminContractSetting.abi,
      //   this.adminContractSetting.contractAddress
      // );
      try {
        // const calculatePrice = getFeeCalucations(item, this.settings);

        // const options = {
        //   value: ethers.utils
        //     .parseUnits(item.price?.toString() || "0", "ether")
        //     .toHexString(),
        // };
        // const transaction = await adminContract.sendETH(
        //   calculatePrice.address,
        //   calculatePrice.price,
        //   options
        // );

        // const receipt = await transaction.wait();
        const priceEth = convertPriceToEther(price);
        console.log("priceEth",priceEth);
        const receipt = await this.SIGNER.sendTransaction({to:this.settings.walletAddress.publicKey, value:priceEth,gasLimit: Web3.utils.toWei("21000","wei")});

        callback && callback(null, receipt);
        resolve(receipt);
      } catch (error) {
        callback && callback(error?.message);
        reject(error);
      }
    });
  };

  switchChain = () => {
    return new Promise((resolve, reject) => {
        const chainId = this.networkUrl.chainId; // Retrieve the chain ID from this.networkUrl.chainId
        console.log("this.networkUrl", this.networkUrl, "chainId", chainId);
          return window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: convertNumberToHex(chainId) }],
          }).then(() => {
            console.log(`Added and switched to chain ${chainId}`);
            resolve();
        }).catch(error => {
            console.error("Error switching chain:", error);
            reject(error);
        });
    });
};


  /**
   * Check user signature
   *
   * @param {string} userWallet Current user wallet address
   * @param {object} message Message
   * @param {function} callback Callback function
   *
   * @returns {Promise} Success for approved or Fail for error
   */
}
export default Web3Intraction;

export const convertPriceToEther = (price) => {
  return ethers.utils.parseEther(price?.toString())._hex;
  // return Web3.utils.toWei(Number(price).toFixed(8), "ether")
};

export const convertHexToString = (hex) => {
  return Web3.utils.hexToNumberString(hex);
};

export const convertNumberToHex = (number) => {
  return Web3.utils.numberToHex(Number(number));
};
export const convertToWei = (number) => Web3.utils.toWei(number);
export const convertFromWei = (number, unit) =>
  Web3.utils.fromWei(number, unit || "ether");