import { useSection } from "Context/HomeContext";
import { useImage } from "Context/ImageContext";
import React from "react";
import { Button } from "react-bootstrap";
import {  useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Workshop = () => {
  const { authToken } = useSelector((state) => state.Login);
  const { activeSection } = useSection();
  const { handleImageLoad } = useImage();
const navigate =useNavigate();

  const onWorkshopClick = () =>{
    if(authToken){
        navigate("/workshop")
    }else{
        navigate("/signin", {
            state: {
              redirectTo: "/workshop",
            },
          })
    }
  }

  return (
    <div className="mint_wrap">
      <div className="heading_wrap">
        <div className="inner position-relative">
          <h2 className="front_heading">Workshop</h2>
          <span className="back_heading">Workshop</span>
        </div>
      </div>
      <section className="workshop position-relative" id="workshop">
        <div
          className={`${activeSection === "workshop" ? "bannerFixed" : "bannerAbsolute"
            } mintWrap`}
        >
          <img
            onLoad={handleImageLoad}
            onError={handleImageLoad}
            src="/images/mintingBg.gif"
            className="img-fluid lazy-media"
          />
        </div>
      </section>
      {/* <Container> */}
      <div className="play_mint desktop-playmint">
        {/* <img
              onLoad={handleImageLoad}
              onError={handleImageLoad}
              src="/images/mintingBg.gif"
              className="img-fluid lazy-media desktop-playmint-img"/> */}

        <video
          autoPlay
          loop
          muted
          className="desktop-playmint-img lazy-media"
          onLoadedData={handleImageLoad}
          onError={handleImageLoad}
          playsInline
        >
          <source src="/videos/shock.MP4" type="video/mp4" />
        </video>
      </div>

      <div className="mintbtn_Wrapps">
        <div className="connection_btn pb-3  gap-3 play-mint-btn-wrap">
            <div className="button-wrap d-inline-flex mt-4 mb-2">
              <Button
                onClick={()=>onWorkshopClick()}
                className="common-greenorange"
                color="primary"
              > Workshop
                
              </Button>
       
          </div>
        </div>
      </div>

      {/* </Container> */}
    </div>
  );
};

export default Workshop;
