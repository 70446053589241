import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// importing all the themes
import Welcometheme from "../themes/welcome-theme";
import Hometheme from "themes/home-theme";
import Singlecollectiontheme from "themes/singlecollection-theme";
import Myprofiletheme from "themes/myprofile-theme";

// profile routes
import Workshop from "Pages/Profile/Workshop";
import WorkshopActionPage from "Pages/Profile/Workshop/Action";
import Nfts from "Pages/Profile/Nfts";
import Account from "Pages/Profile/Account";
import Nfttrade from "Pages/Profile/Nfttrade";
import Scannizer from "Pages/Profile/Scannizer";
import Linkedwallet from "Pages/Profile/Linkedwallet";
import Discordlink from "Pages/Profile/Discordlink";
import MintOG from "Pages/Profile/mint";
import Claim from "Pages/Profile/Claim";
import PowerRoom from "Pages/Profile/PowerRoom"
import Signintheme from "themes/signin-theme";
import Signuptheme from "themes/signup-theme";
import Forgotpasstheme from "themes/forgotpass-theme";

import Notfound from "Pages/Notfound";
import { Navigate } from "react-router-dom";

// Store
import { getProfile, getSettings } from "store/actions";
import OGAuthRoute from "Component/OGAuthRoute";
import PlayToMint from "Pages/Profile/PlayToMint";

import PrivacyPolicyTheme from "themes/privacy-policy-theme";
import TermsTheme from "themes/terms-and-conditions";
import ContactUstheme from "themes/contactus-theme";


// export function ProtectedWrapper({ reveal_play_to_mint,children }) {
//   const {pathname} = useLocation();
//   const {
//     Login: { authToken },
//   } = useSelector((state) => state);
// console.log("check play to mint route",pathname,"reveal_play_to_mint aasasa",reveal_play_to_mint)
//   if (authToken && pathname !== "/play-to-mint") {
//     return children;
//   }else if(pathname === "/play-to-mint" && reveal_play_to_mint){
//     return children;
//   } else {
//     return <Navigate to="/" replace />;
//   }
// }

const ProtectedWrapper = ({
  element: Element,
  reveal_play_to_mint,
  ...rest
}) => {
  const {pathname} = useLocation();
  const {
    Login: { authToken },
  } = useSelector((state) => state);
  if (authToken ) {
    return <Element {...rest} />
  }
  // else if(pathname === "/play-to-mint" && reveal_play_to_mint){
  //   return <Element {...rest} />
  // } 
  else {
    return <Navigate to="/" replace />;
  }
};


export function RedirectWrapper({ children }) {
  const {
    Login: { user, authToken },
  } = useSelector((state) => state);

  if (authToken) {
    return <Navigate to="/inventory" replace />;
  } else {
    return <Navigate to="/welcome" replace />;
  }
}
function MyRouts() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Login);
  const { settings } = useSelector((state) => state?.Setting);
  
  console.log("user", user);
  useEffect(() => {
    dispatch(getSettings());
  }, []);
  useEffect(() => {
    dispatch(getProfile());
  }, []);
  console.log(user, "user")
  const isOgAuth = user?.discord_link && user?.og_role_status;
  const revealPlayToMint = settings?.gamePlaySettings?.reveal_play_to_mint; 
  console.log("revealPlayToMint",revealPlayToMint)
  return (
    <div>
      <BrowserRouter>
        <Routes>
          
          <Route path="/" element={<RedirectWrapper></RedirectWrapper>} />
          <Route path="/welcome" element={<Welcometheme />} />
          <Route path="/home" element={<Hometheme />} />
          <Route path="/privacy" element={<PrivacyPolicyTheme />} />
          <Route path="/terms-and-conditions" element={<TermsTheme />} />
          <Route path="/support" element={<ContactUstheme />} />
          <Route path="/minting" element={<Singlecollectiontheme />} />

          <Route exact path="/signin" element={<Signintheme />} />
          <Route exact path="/signup" element={<Signuptheme />} />
          <Route exact path="/forgot-password" element={<Forgotpasstheme />} />

          <Route element={<ProtectedWrapper element={Myprofiletheme}/>}>
            {/* <Route path="/workshop" element={<ProtectedWrapper element={Workshop}/>} /> */}
            <Route path="/workshop/nft/:token_id" element={<ProtectedWrapper element={WorkshopActionPage}/>} />
            <Route path="/og-mint" element={
              <OGAuthRoute isOgAuthenticated={isOgAuth} isAuth={user} element={MintOG} />
            } />

            <Route path="/claim" element={
                    <ProtectedWrapper element={Claim}/>
                  } 
            />
          {revealPlayToMint && <Route path="/play-to-mint" element={
                  <ProtectedWrapper element={PlayToMint}/>
              } 
            />
}

            <Route path="/inventory" element={
            <ProtectedWrapper element={Nfts}/>}/>
            <Route path="/account" element={<ProtectedWrapper element={Account}/>} />
            <Route path="/nft-trade" element={<ProtectedWrapper element={Nfttrade}/>} />

            <Route path="/scaning" element={<ProtectedWrapper element={Scannizer}/>} />
            <Route path="/wallet-link" element={<ProtectedWrapper element={Linkedwallet}/>} />
            <Route path="/discord-link" element={<ProtectedWrapper element={Discordlink}/>} />
            {/* <Route path="/collection-stacks" element={<ProtectedWrapper><Collectionstack /></ProtectedWrapper>} /> */}
            <Route path="/power_room" element={<ProtectedWrapper element={PowerRoom}/>} />
            {/* <Route path="/test-game-myself" element={
                  <ProtectedWrapper element={PlayToMint}/>
              } 
            /> */}
          </Route>
         
          success
          <Route path="*" element={<Notfound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default MyRouts;
