import React, { useState } from "react";
import { Button } from "react-bootstrap";
import CharactersTab from "./Tabbed/Characters";
import ArmorsTab from "./Tabbed/armors";


const Nfts = () => {
  const tab = [
    {
      btn: "Characters",
      component: <CharactersTab />
    },
    {
      btn: "Armory",
      component: <ArmorsTab />
    }
  ]
  const [activeTab, setActiveTab] = useState("Characters")

  const handleTab = (key) => {
    setActiveTab(key)
  }


  const selectedTabComponent = tab.find((item) => item.btn === activeTab).component;
  return (

    <div className="tabWrpper pillsTab">
      <div className="navTab d-flex align-items-center gap-10">
        {tab.map((item, key) => (
          <Button key={key}
            onClick={() => handleTab(item.btn)}
            className={`${activeTab == item.btn && "active"} btn d-flex align-items-center justify-content-center navLink`}>{item.btn}</Button>

        ))}
      </div>
      <div className="tabContent">
        {selectedTabComponent}
      </div>
    </div>

  );
};

export default Nfts;
