import { useSection } from "Context/HomeContext";
import { useImage } from "Context/ImageContext";
import react, { useRef } from "react";
import { Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="150"
        viewBox="0 0 24 24"
      >
        <path fill="#21e786" d="M10 17V7l5 5l-5 5Z" />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="150"
        viewBox="0 0 24 24"
      >
        <path fill="#21e786" d="m14 17l-5-5l5-5v10Z" />
      </svg>
    </div>
  );
}

const NftsSlider = () => {
  const { settings } = useSelector((state) => state?.Setting);
  const navigate = useNavigate();
  const { activeSection } = useSection();
  console.log("activeSection", activeSection);
  const sectionRef = useRef(null);
  // Register section refs
  // registerSectionRef(sectionRef, 'nfts');
  const { handleImageLoad } = useImage();

  const nfts = [
    {
      image: "/images/slider1.png",
    },
    {
      image: "/images/slider2.png",
    },
    {
      image: "/images/slider3.png",
    },
    {
      image: "/images/slider4.png",
    },
    {
      image: "/images/slider5.png",
    },
    {
      image: "/images/slider6.png",
    },
    {
      image: "/images/slider7.png",
    },
    {
      image: "/images/slider8.png",
    },
    {
      image: "/images/slider9.png",
    },
    {
      image: "/images/slider10.png",
    },
  ];

  var settinga = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1.3,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };
  return (
    // <section ref={sectionRef} className="nft_slider_home">
    //   <Container>
    //     <div className="heading_wrap">
    //       <div className="inner position-relative">
    //         <h2 className="front_heading">Collections</h2>
    //         <span className="back_heading">Collections</span>
    //       </div>
    //     </div>

    //     <div className="wrap_slider" id="nfts">
    //       {settings?.homeNftsSlider?.length ? (
    //         <Slider {...settingaa}>
    //           {(settings?.homeNftsSlider || []).map((nft, index) => (
    //             <div key={index} className="slide_boximg">
    //               <h5
    //                 title={nft?.title}
    //                 className="card_header text-white text-truncate m-auto mb-2"
    //               >
    //                 {nft?.title}
    //               </h5>
    //               <img
    //                 className="lazy-media"
    //                 onLoad={handleImageLoad}
    //                 onError={handleImageLoad}
    //                 src={nft?.image}
    //               />
    //               <a
    //                 //   href="#"
    //                 href={nft?.opensea_link}
    //                 target="_blank"
    //                 className="opensea_link"
    //               >
    //                 Opensea
    //               </a>
    //             </div>
    //           ))}
    //         </Slider>
    //       ) : null}
    //     </div>
    //   </Container>
    // </section>
    <section ref={sectionRef} id="faq" className="faq_section">
      <div className="heading_wrap">
        <div className="inner position-relative">
          <h2 className="front_heading">Collections</h2>
          <span className="back_heading">Collections</span>
        </div>
      </div>

      <div className="faq_column roadSlider p-3 px-lg-5">
        {settings?.homeNftsSlider?.length ? (
          <Slider {...settinga} className="roadMapSlider">
            {(settings?.homeNftsSlider || []).map((nft, index) => (
              <>
                <div key={index} className="px-2 py-3">
                  <div className="roadmap-card">
                    <div className="image-wrap">
                      <img
                        className="lazy-media w-100 home-collection-img"
                        onLoad={handleImageLoad}
                        onError={handleImageLoad}
                        src={nft?.image}
                      />
                    </div>
                    <div className="content p-3">
                      <h3
                        title={nft?.title}
                        className="card_header text-white text-truncate mx-0 mb-2"
                      >
                        {nft?.title}
                      </h3>
                      <p className="text-white">{nft?.description}</p>
                      <p className="d-flex justify-content-center ">
                        {" "}
                        <div class="button-wrap w-100">
                          <a
                            href={nft?.opensea_link}
                            target="_blank"
                            className="opensea_link"
                            style={{ fontSize: 16 }}
                          >
                            Opensea
                          </a>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </Slider>
        ) : null}
      </div>
    </section>
  );
};

export default NftsSlider;
