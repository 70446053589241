import React, { useEffect, useRef, useState } from "react";

// Components
import Header from "Component/Header/Header";
import Footer from "Component/Footer/Footer";
import Homebanner from "Pages/Home/Homebanner";
import Team from "Pages/Home/Team";
import Faq from "Pages/Home/Faq";
import Playmint from "Pages/Home/Playmint";
import Workshop from "Pages/Home/Workshop";
import { useDispatch, useSelector } from "react-redux";

// Store
import { getCollection, getFaqs, getTeams } from "store/actions";
import NftsSlider from "Pages/Home/NftsSlider";
import Video from "Component/Video/Video";
import { RoboticRabbitsS3BaseUri } from "helpers/constants";
import { SectionProvider } from "../Context/HomeContext";
import moment from "moment";
import { ImageProvider, useImage } from "Context/ImageContext";
import Roadmap from "Pages/Home/Roadmap";
import { ButtomToTopButton } from "Component/BottomToTopButton";

const Home = (props) => {
  console.log("home props", props);
  const { faqs, teams, roadmaps } = useSelector((state) => state?.Contents);
  const { settings } = useSelector((state) => state?.Setting);
  const [showVideo, setShowVideo] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(true);

  const dispatch = useDispatch();
  const childProgressRef = useRef();

  const { setShowProgress } = useImage();
  useEffect(() => {
    dispatch(getFaqs());
    dispatch(getTeams());

    const homeVideoSkipDate = localStorage.getItem("homeVideoSkipDate");

    if (homeVideoSkipDate) {
      const storedDate = moment(homeVideoSkipDate).toDate();
      // Calculate the next date after the stored date
      const nextDate = moment(storedDate).add(1, "day").toDate();

      const currentDate = new Date();

      // Compare the current date with the next date
      if (currentDate >= nextDate) {
        setShowVideo(true);
      } else {
        //
        setShowProgress(true);
      }
    } else {
      setShowVideo(true);
    }
  }, []);

  useEffect(() => {
    if (settings?.activeCollectionId) {
      dispatch(getCollection(settings?.activeCollectionId));
    }
  }, [settings]);

  const onVideoEnd = (videoRef) => {
    if (videoRef?.current) {
      videoRef.current.style.transition = "opacity 0.4s";
      videoRef.current.style.opacity = 0;
    }
    setTimeout(() => {
      setShowVideo(false);
    }, 500);
  };

  const onSkipVideo = (videoRef) => {
    if (videoRef?.current) {
      videoRef?.current?.pause();
    }
    localStorage.setItem("homeVideoSkipDate", moment().format());
    if (videoRef?.current) {
      videoRef.current.style.transition = "opacity 0.4s";
      videoRef.current.style.opacity = 0;
    }
    setTimeout(() => {
      setShowVideo(false);
      setShowProgress(true);
    }, 500);
  };
  const { showProgress } = useImage();

  console.log("showProgress", showProgress);
  const onSoundHandler = () => {};
  return (
    <SectionProvider>
      <div className="home_theme_brikcs" id="home_theme_brikcs">
        {showVideo ? (
          <Video
            className="home_video"
            videoUrl={`${RoboticRabbitsS3BaseUri}/RRS_mouth_blast.MP4`}
            onVideoEnd={onVideoEnd}
            isFullScreen={true}
            autoPlay
            muted={isVideoMuted}
            onLoadVideo={(videoRef) => {
              if (videoRef?.current) {
              }
            }}
            controls={false}
            isSkipAllow={true}
            onSkipVideo={onSkipVideo}
            playsInline
            isShowSound={true}
            onSoundHandler={(videoRef) => {
              if (videoRef?.current) {
                videoRef.current.muted = !isVideoMuted;
                setIsVideoMuted(!isVideoMuted);
              }
            }}
          />
        ) : (
          <>
            <Header />
            <Homebanner />
            <Playmint />
            <Workshop/>
            <NftsSlider />
            {/* <Mintsection /> */}
            <Roadmap />
            <Team TeamData={teams} />
           
            <Faq FaqData={faqs} />
            {/* <Joincommunity /> */}
            <Footer extraclass="footer-main" />
          </>
        )}
      </div>
      <ButtomToTopButton />
    </SectionProvider>
  );
};

export default function Hometheme() {
  return (
    <ImageProvider>
      <Home />
    </ImageProvider>
  );
}
