import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
// Item Redux States
import {
  POST_MINT_ACTIVITY,
  GET_ITEMS,
  NFTS_RRC_TO_MAINBUCKET_REQUEST,
  GET_USER_MINTED_NFTS_COUNT_REQUEST,
  UPDATE_USER_NFT_METADATA_REQUEST,
  RRWS_TOKENIDS_S3_METADATA_REQUEST,
  GET_DISCORD_AK_NFT_COUNT_REQUEST,
  GET_USER_GAME_MINT_REQUEST,
} from "./actionTypes";
import {
  postMintActivityFail,
  postMintActivitySuccess,
  getItemsFail,
  getItemsSuccess,
  postNftRrcToMainBucketFail,
  postNftRrcToMainBucketSuccess,
  getUserMintedNftsCountFail,
  getUserMintedNftsCountSuccess,
  updateUserNftMetadataFail,
  updateUserNftMetadataSuccess,
  postRrwsS3MetadataFail,
  postRrwsS3MetadataSuccess,
  getDiscordAkNftCountFail,
  getDiscordAkNftCountSuccess,
  getUserGameMintFail,
  getUserGameMintSuccess,
} from "./actions";

import {
  postMintActivity,
  getItems,
  postNftRrcToMainApi,
  getUserMintedNftsCountApi,
  updateUserNftMetadataApi,
  postRrwsTokenIdsS3MetadataApi,
  getDiscordAkNftCountApi,
  postDirectMintActivity,
  getUserGameMintDetailsApi,
} from "helpers/backend_helper";

function* onPostMintActivity({ payload: { data, callback } }) {
  try {
    const response = yield call(
      data?.directMint ? postDirectMintActivity : postMintActivity,
      data
    );

    if (response.status === "failure") {
      // toast.error(response.message);
      console.log("error", response.message);
      return yield put(postMintActivityFail(response.message));
    }

    yield put(postMintActivitySuccess(response));
    callback && callback();
  } catch (error) {
    console.log("error", error);
    yield put(postMintActivityFail(error));
  }
}

function* onGetItems({ payload: { data, callback } }) {
  try {
    const response = yield call(getItems, data);

    if (response.status === "failure") {
      toast.error(response.message);
      return yield put(getItemsFail(response.message));
    }

    yield put(getItemsSuccess(response));
    callback && callback();
  } catch (error) {
    console.log("error", error);
    yield put(getItemsFail(error));
  }
}

function* postNftRrcToMainBucketReq({ payload: { data, callback } }) {
  try {
    const response = yield call(postNftRrcToMainApi, data);
    if (response.status === "failure") {
      // toast.error(response.message);
      console.log("error", response.message);
      return yield put(postNftRrcToMainBucketFail(response.message));
    }
    yield put(postNftRrcToMainBucketSuccess(response));
    callback && callback(response.data);
  } catch (error) {
    console.log("error", error);
    yield put(postNftRrcToMainBucketFail(error));
  }
}

function* getUserMintedNftsCountReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getUserMintedNftsCountApi, data);
    if (response.status === "failure") {
      callback && callback(false, response.message);
      return yield put(getUserMintedNftsCountFail(response.message));
    }
    callback && callback(true, response.totalCount);
    yield put(getUserMintedNftsCountSuccess(response));
  } catch (error) {
    console.log("error", error);
    callback && callback(false, "Internal Error");
    yield put(getUserMintedNftsCountFail(error));
  }
}

function* updateUserNftMetadataReq({ payload: { data, callback } }) {
  try {
    const response = yield call(updateUserNftMetadataApi, data);
    if (response.status === "failure") {
      toast.error(response.message);
      return yield put(updateUserNftMetadataFail(response.message));
    }
    yield put(updateUserNftMetadataSuccess(response));
    toast.success(
      "Nft updated successfully, It will take few minutes to reflect.",
      { autoClose: 10000 }
    );
    callback && callback(response);
  } catch (error) {
    toast.error("Internal Error!");
    yield put(updateUserNftMetadataFail(error));
  }
}

function* postRRWSTokenIdsS3MetadataReq({ payload: { data, callback } }) {
  try {
    const response = yield call(postRrwsTokenIdsS3MetadataApi, data);
    if (response.status === "failure") {
      return yield put(postRrwsS3MetadataFail(response.message));
    }
    yield put(postRrwsS3MetadataSuccess(response));
    callback && callback(response);
  } catch (error) {
    console.log("Internal error", error);
    yield put(postRrwsS3MetadataFail(error));
  }
}

function* getDiscordAk47ClaimedNftReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getDiscordAkNftCountApi, data);
    if (response.status === "failure") {
      callback && callback(false, response.message);
      return yield put(getDiscordAkNftCountFail(response.message));
    }
    yield put(getDiscordAkNftCountSuccess(response));
    callback && callback(true, response.data);
  } catch (error) {
    console.log("Internal error", error);
    callback && callback(false, error?.message || "Internal Error");
    yield put(getDiscordAkNftCountFail(error));
  }
}

function* getUserGameMintDetailsReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getUserGameMintDetailsApi);
    if (response.status === "failure") {
      return yield put(getUserGameMintFail(response.message));
    }
    yield put(getUserGameMintSuccess(response));
    callback && callback(response.data);
  } catch (error) {
    console.log("Internal error", error);
    yield put(getUserGameMintFail(error));
  }
}

function* ItemsSaga() {
  yield takeEvery(POST_MINT_ACTIVITY, onPostMintActivity);
  yield takeEvery(GET_ITEMS, onGetItems);
  yield takeLatest(NFTS_RRC_TO_MAINBUCKET_REQUEST, postNftRrcToMainBucketReq);
  yield takeLatest(
    GET_USER_MINTED_NFTS_COUNT_REQUEST,
    getUserMintedNftsCountReq
  );
  yield takeLatest(UPDATE_USER_NFT_METADATA_REQUEST, updateUserNftMetadataReq);
  yield takeLatest(
    RRWS_TOKENIDS_S3_METADATA_REQUEST,
    postRRWSTokenIdsS3MetadataReq
  );
  yield takeLatest(
    GET_DISCORD_AK_NFT_COUNT_REQUEST,
    getDiscordAk47ClaimedNftReq
  );
  yield takeLatest(GET_USER_GAME_MINT_REQUEST, getUserGameMintDetailsReq);
}

export default ItemsSaga;
