import { getContentApi } from "helpers/backend_helper";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import toastr from "toastr";

export default function TermsAndConditions() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    try {
      const response = await getContentApi("TERMS_CONDITIONS");
      console.log("response terms", response);
      if (response?.status === "success") {
        setLoading(false);
        setData(response?.data)
      } else {
        setLoading(false);
        toastr.error(response?.message)
      }
    } catch (err) {

    }

  }, []);

  return (
    <section
      className="welcome-page"
    >

      <Container >
        <h4 className="text-white py-5">Terms and conditions</h4>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="text-white" dangerouslySetInnerHTML={{ "__html": data?.content }}></div>
          </Col>
        </Row>

      </Container>
    </section>
  );
}
