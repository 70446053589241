import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext
} from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { Button, Card, CardBody, CardHeader, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getCollection,
  getProfile,
  getUserGameMintRequest,
  postMintActivity,
  postNftRrcToMainBucketRequest
} from "store/actions";
import Web3Intraction from "util/web3Intraction";
import useWallet from "hooks/wallet";
import SuccessMintModal from "Component/Modals/SuccessMintMod";
import { toast } from "react-toastify";
import { ethers, Contract } from "ethers";
import Video from "Component/Video/Video";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import { useWindow } from "hooks/useWindow";
import { RoboticRabbitsS3BaseUri, getNetworkUrl } from "helpers/constants";
import { convertFromWei, convertToWei } from "util/web3InteractionEth";
import moment from "moment";
import { createGameValidatorSign } from "helpers/backend_helper";

// ["verify-to-mint", "easymode-to-mint", "hardmode-to-mint", null]
const modalType = {
  "verify-to-mint": 1,
  "easymode-to-mint": 2,
  "hardmode-to-mint": 3
};

export const PlayToMintWebGl = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const wallet = useWallet();
  const {
    screenSize: { dynamicWidth }
  } = useWindow();
  const { user } = useSelector((state) => state.Login);
  const { settings } = useSelector((state) => state.Setting);
  const { collection } = useSelector((state) => state.Collection);
  const [loading, setloading] = useState(false);
  const [userDetail, setUserDetails] = useState();
  const [successMintModal, setSuccessinModal] = useState(false);
  const [counter, setCounter] = useState(1);
  const [isShowUnityGame, setIsShowUnityGame] = useState(true);
  const [showVideo, setShowVideo] = useState(true);
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [isMintingPaused, setIsMintingPaused] = useState(false);
  const { loadingGameMintDetails, userGameMintDetails, gameMintDetailError } =
    useSelector((state) => state.Items);

  const { navigator } = useContext(NavigationContext);

  const onVideoEnd = () => {
    setShowVideo(false);
  };

  const {
    unityProvider,
    loadingProgression,
    UNSAFE__unityInstance,
    unload,
    isLoaded,
    sendMessage,
    requestFullscreen,
    addEventListener,
    removeEventListener
  } = useUnityContext({
    loaderUrl: "/WebGl/Build/WebGl.loader.js",
    dataUrl: "/WebGl/Build/WebGl.data",
    frameworkUrl: "/WebGl/Build/WebGl.framework.js",
    codeUrl: "/WebGl/Build/WebGl.wasm"
    // webGLContextAttributes: {
    //   alpha: true,
    //   antialias: true,
    //   depth: true,
    //   failIfMajorPerformanceCaveat: true,
    //   powerPreference: "high-performance",
    //   premultipliedAlpha: true,
    //   preserveDrawingBuffer: true,
    //   stencil: true,
    //   desynchronized: true,
    //   xrCompatible: true,
    // },
  });

  useEffect(
    () => (window.unityInstance = UNSAFE__unityInstance),
    [UNSAFE__unityInstance]
  );

  useEffect(() => {
    const handleUnload = async (tx) => {
      // Your custom alert message
      const userResponse = window.confirm(
        "Are you sure you want to exit? \nNote :- You will lose your discount in easy and hard mode if you exit without minting your NFT."
      );

      if (userResponse) {
        // User confirmed, proceed with unloading logic
        await unload();
        await unblock();
        await tx.retry();
        return;
      } else {
        return;
        // User canceled, handle accordingly
        // You may choose to not call unload() or do something else
      }
    };

    const unblock = navigator.block(async (tx) => {
      if (isLoaded) {
        await handleUnload(tx);
      } else {
        await unblock();
        await tx.retry();
      }
    });

    return unblock;
  }, [navigator, isLoaded]);

  useEffect(() => {
    if (!wallet.account && JSON.stringify(settings) !== "{}") {
      const networkUrl = getNetworkUrl("ethereum", settings);
      console.log("networkUrl",networkUrl);
      wallet.connect(
        "metamask",
        networkUrl.chainId || 1
      );
    }
  }, [settings, wallet.account]);

  useEffect(() => {
    if (user) {
      setUserDetails({ ...user, walletAddress: user?.walletId?.walletAddress });
    }
  }, [user]);

  useEffect(() => {
    if (settings && settings.activeCollectionId) {
      dispatch(
        getCollection(settings.activeCollectionId, async (coll) => {
          const web3Intraction = new Web3Intraction(coll.blockchain, settings);
          // console.log(coll, "coll")

          const contract = await web3Intraction.getContract(
            coll.contractAbi,
            coll.contractAddress
          );
          console.log("contract", contract);
          const isMintActive = await contract.methods.isGameMintActive().call();
          if (!isMintActive) {
            return toast.error("Minting is paused for the session.", {
              autoClose: 4000
            });
          }
          setIsMintingPaused(!isMintActive);
        })
      );
      dispatch(getUserGameMintRequest());
    }
  }, [JSON.stringify(settings)]);

  const handleClickEnterFullscreen = () => {
    requestFullscreen(true);
  };

  const onCloseMintModal = (type) => {
    if (      
      typeof window?.unityInstance !== "undefined" &&
      typeof window?.unityInstance?.SendMessage === "function"
    ) {
      console.log("type",type);
      console.log("window?.unityInstance",window?.unityInstance)
      window?.unityInstance?.SendMessage(
        "BrowserInteractionManager",
        "OnMintNFTSuccess",
        type
      );
    }
  };

  const onUnityLoaderStop =() =>{
    if (      
      typeof window?.unityInstance !== "undefined" &&
      typeof window?.unityInstance?.SendMessage === "function"
    ) {
      console.log("window?.unityInstance",window?.unityInstance)
      window?.unityInstance?.SendMessage(
        "BrowserInteractionManager",
        "OnMintNFTFailed"
      );
    }
    
  }


  const createValidatorSign = async (data) =>{
     return new Promise(async(resolve, reject)=>{
         try{
            const response = await createGameValidatorSign(data);
            resolve(response.data);
         }catch(e){
          reject(null)
         }
     })
  }

  const createVoucher = async (
    contractAddress,
    signer,
    chainId,
    mintQuantity,
    mintMode,
    buyer
  ) => {
    try {
  
      // const validaterSigner = new ethers.Wallet(
      //   "4cc2cca81f89af4d5259a5a017303788182efb091a3f80a824ebcd8e3f2ae521"
      // );
      // console.log("validaterSigner", validaterSigner);
      console.log("chainId", chainId);
      // roboticrabbitsyndicate
      const domain = {
        name: "Robotic_Rabbit_Syndicate",
        version: "1",
        verifyingContract: contractAddress,
        chainId: chainId
      };
      const mintTimestamp = moment().unix();
      console.log(
        "mintQuantity",
        mintQuantity,
        "mintMode",
        mintMode,
        "mintTime",
        mintTimestamp,
        "buyer",
        buyer
      );
      const voucher = { mintQuantity, mintMode, mintTimestamp, buyer };
      const types = {
        GameVoucher: [
          { name: "mintQuantity", type: "uint256" },
          { name: "mintMode", type: "uint256" },
          { name: "mintTimestamp", type: "uint256" },
          { name: "buyer", type: "address" }
        ]
      };

      console.log("voucher", voucher);
      console.log("types", types);
      const signature = await signer._signTypedData(domain, types, voucher);
      const validateSignature = await createValidatorSign(
        {
          mintQuantity,
          mintMode,
          mintTimestamp,
          buyer,
          chainId,
          contractAddress,
      }
      );
      // const validateSignature = await validaterSigner._signTypedData(
      //   domain,
      //   types,
      //   voucher
      // );
      console.log("signature", signature, "valid", validateSignature);

      if(!validateSignature){
        toast.error("Validator sign is missing!")
        return;
      }

      return {
          ...voucher,
          signature,
          validateSignature
      };
    } catch (err) {
      console.log("eeror in voucher", err);
      return null;
    }
  };


  const mintNft = async (counter, perMintValue, playMintType, discount) => {
    try {
      console.log(
        "counter",
        counter,
        "perMintValue",
        perMintValue,
        "PlayMintType",
        playMintType,
        "discount",
        discount
      );
      setloading(true);
      if (
        !collection ||
        !collection.contractAbi ||
        !settings ||
        JSON.stringify(settings) === "{}"
      ) {
        setloading(false);
            setTimeout(()=>onUnityLoaderStop() ,1000);
        toast.error("Something went wrong!");
        return;
      }

      if (!wallet.account) {
        setloading(false);
            setTimeout(()=>onUnityLoaderStop() ,1000);
        return toast.error(`Make sure your metamask connected.`);
      }
      console.log("count", counter, "perMintValue", perMintValue);
      const value = parseFloat(perMintValue).toFixed(8);
      console.log("convert float", value);
      const valueInWei = convertToWei(value?.toString());
      console.log("value on wei", valueInWei);

      console.log("collection.blockchain", collection);
      const web3Intraction = new Web3Intraction(
        collection.blockchain,
        settings
      );
      // console.log(collection, "collection")

      const contract = await web3Intraction.getContract(
        collection.contractAbi,
        collection.contractAddress
      );
      console.log("contract", contract);
      const isMintActive = await contract.methods.isGameMintActive().call();
      if (!isMintActive) {
        setloading(false);
            setTimeout(()=>onUnityLoaderStop() ,1000);
        return toast.error("Minting is paused for the session.", {
          autoClose: 4000
        });
      }
      console.log("is paused", !isMintActive);
      const PROVIDER = new ethers.providers.Web3Provider(
        wallet.provider,
        web3Intraction.networkUrl
          ? {
              name: web3Intraction.networkUrl.chainName,
              chainId: Number(web3Intraction.networkUrl.chainId)
            }
          : "any"
      );
      console.log("PROVIDER", PROVIDER);
      const SIGNER = PROVIDER.getSigner();
      console.log("SIGNER", SIGNER);
      // console.log(collection, "collection")
      let ethercontract = new Contract(
        collection.contractAddress,
        JSON.parse(collection.contractAbi),
        SIGNER
      );

      if (counter <= 0){
            setTimeout(()=>onUnityLoaderStop() ,1000);
        setloading(false);
        return toast.error("Quantity needed!");
      }
      // setloading(true);

      if (!contract) {
            setTimeout(()=>onUnityLoaderStop() ,1000);
        setloading(false);
        return toast.error("Service not provided!");
      }
      const networkUrl = getNetworkUrl("ethereum", settings);
      console.log("networkUrl",networkUrl);
      let chainID = networkUrl?.chainId || 1;
      if (
        wallet.account &&
        wallet.account?.toLowerCase() ===
          userDetail?.walletAddress?.toLowerCase()
      ) {
        
          await wallet.switchNetwork(
            chainID
        );
       
      } else {
            setTimeout(()=>onUnityLoaderStop() ,1000);
        setloading(false);
        return toast.error(
          `Make sure your metamask connected account is same as primary wallet : ${userDetail?.walletAddress}`
        );
      }
      const cost = await ethercontract.gameNFTCost();
      console.log("cost", cost);
      const etherAmount = convertFromWei(cost?.toString());
     
      console.log("ethercontract", ethercontract);
      let txHash = "";
      let gasLimit = 300000;
      gasLimit =
        parseInt(counter) === 1
          ? 300000
          : parseInt(counter) === 2
          ? 600000
          : parseInt(counter) === 3
          ? 900000
          : 300000;

      let mintMode;
      console.log("playMintType",playMintType)
      console.log("mintMode before and discount",modalType[playMintType],discount);
      if(parseInt(modalType[playMintType]) === 3 && parseInt(discount) === 20)
      { 
          mintMode = 2;
      }else{
          mintMode = modalType[playMintType];
      }
      console.log("mintMode after and discount",mintMode,discount);

      const voucher = await createVoucher(
        collection.contractAddress,
        SIGNER,
        chainID,
        counter,
        mintMode,
        wallet.account
      );
      console.log("vouchervouchervoucher",voucher)
      // const validVoucher = await ethercontract.verify(
        
      //     voucher.mintQuantity,
      //     voucher.mintMode,
      //     voucher.mintTimestamp,
      //     voucher.buyer,
      //     voucher.signature,
      //     voucher.validateSignature
        
      // );

      // console.log("validVoucher", validVoucher);
      if (!voucher) {
        setloading(false)
            setTimeout(()=>onUnityLoaderStop() ,1000);
        toast.error("Error while create security data.");
        return;
      }
      console.log("valueInWei", valueInWei);
      const estimatePrice = await ethercontract.estimateForGame(
        voucher.mintQuantity,
        voucher.mintMode
      );
      console.log("estimatePrice/....", estimatePrice?.toString());
   
      // const result = await ethercontract.callStatic["mintForGame"]( 
      //   voucher.mintQuantity,
      //   voucher.mintMode,
      //   voucher.mintTimestamp,
      //   voucher.buyer,
      //   voucher.signature,
      //   voucher.validateSignature,
      //   {
      //     value: estimatePrice
      //     // gasLimit: gasLimit
      //   });
      //   console.log("resutlt callStatic mintForGame ",result);
     

      const transaction = await ethercontract.mintForGame(
        voucher.mintQuantity,
        voucher.mintMode,
        voucher.mintTimestamp,
        voucher.buyer,
        voucher.signature,
        voucher.validateSignature,
        {
          value: estimatePrice
          // gasLimit: gasLimit
        }
      );
      const tx = await transaction.wait();
      console.log("tx", tx);
      txHash = tx.transactionHash;

      // console.log("receipt after mint",receipt);
      setloading(false);
          setTimeout(()=>onUnityLoaderStop() ,1000);
      if (txHash) {
        setSuccessinModal(true);
      }

      const _tokens = await contract.methods
        .walletOfOwner(userDetail?.walletAddress)
        .call();
      console.log("v", _tokens);

      //save item and history
      if (_tokens?.length && txHash) {
        const recentMintedTokens = _tokens.slice(-counter);
        console.log("recentMintedTokens", recentMintedTokens);
        dispatch(
          postMintActivity(
            {
              type: collection.blockchain,
              collection_id: collection._id,
              transactionHash: txHash,
              price: etherAmount,
              quantity: counter,
              walletAddress: userDetail?.walletAddress,
              mint_type: "og-mint",
              token_ids: recentMintedTokens,
              play_to_mint_type: playMintType,
              mint_discount_percent: discount,
              play_session: settings?.gamePlaySettings?.play_session
            },
            () => {
              // setloading(false);
              // dispatch(getProfile());
              dispatch(getUserGameMintRequest());
              wallet.getWalletBalance();
            }
          )
        );

        //reveal nfts
        dispatch(
          postNftRrcToMainBucketRequest(
            { tokens: recentMintedTokens },
            (response) => {
              console.log("response after tokens move", response);
            }
          )
        );

        setTimeout(() => {
          //close game mint modal;
          onCloseMintModal(modalType[playMintType]);
          setloading(false);
        }, 1000);
      }
    } catch (err) {
      console.log("error in mint", err,"typeof err",typeof err);
          setTimeout(()=>onUnityLoaderStop() ,1000);
      setloading(false);
      if(typeof err === "string"){
            setTimeout(()=>onUnityLoaderStop() ,1000);
        return typeof err === "string" && toast.error(err);

      }else if(err?.message?.includes("insufficient funds")){
        setTimeout(()=>onUnityLoaderStop() ,1000);
        return toast.error("Insufficient funds for gas and value");
      }
      else if (err?.message?.includes("reverted")) {
            setTimeout(()=>onUnityLoaderStop() ,1000);
        // Extract the error message from the revert error
        const errorMessage = err.message.split('"')[1];
        
        // Display the error message to the user
        return toast.error(errorMessage); // You can use other UI elements to display the message
      } 
      else{
            setTimeout(()=>onUnityLoaderStop() ,1000);
        return toast.error(err?.message || "Something went wrong!");
      }
    } finally {
      setTimeout(() => {
        //close game mint modal;
            setTimeout(()=>onUnityLoaderStop() ,1000);
        setloading(false);
      }, 1000);
      setloading(false);
    }
  };
  const verifyToMintHandler = useCallback(
    async (counter, perMintValue) => {
      // Do something with the score
      console.log("count", counter, "perMintValue", perMintValue);
      const valueEth = parseFloat(perMintValue);
      let totalValue = counter * valueEth;
      console.log("totalValue", totalValue);
      console.log("verify to mint called");

      console.log("conter", counter, "ethVal", totalValue);
      mintNft(counter, totalValue, "verify-to-mint", 0);
    },
    [userDetail, settings, collection]
  );

  const easyModeMintHandler = useCallback(
    async (mintDiscount, perMintValue) => {
      console.log("EasyToMint function call");
      const discountPercent = parseFloat(mintDiscount).toFixed(0);
      const valueEth = parseFloat(perMintValue);
      mintNft(1, valueEth, "easymode-to-mint", discountPercent);
    },
    [userDetail, settings, collection]
  );

  const hardModeMintHandler = useCallback(
    async (mintDiscount, perMintValue) => {
      console.log("HardMint function call");
      const valueEth = parseFloat(perMintValue);
      const discountPercent = parseFloat(mintDiscount).toFixed(0);
      mintNft(1, valueEth, "hardmode-to-mint", discountPercent);
    },
    [userDetail, settings, collection]
  );

  useEffect(() => {
    try {
      addEventListener("VerifyToMint", verifyToMintHandler);
      addEventListener("EasyModeMint", easyModeMintHandler);
      addEventListener("HardModeMint", hardModeMintHandler);
      return () => {
        removeEventListener("VerifyToMint", verifyToMintHandler);
        removeEventListener("EasyModeMint", easyModeMintHandler);
        removeEventListener("HardModeMint", hardModeMintHandler);
      };
    } catch (error) {
      console.log("error in listeners", error);
    }
  }, [
    addEventListener,
    removeEventListener,
    verifyToMintHandler,
    easyModeMintHandler,
    hardModeMintHandler
  ]);

  const onSkipVideo = (videoRef) => {
    if (videoRef?.current) {
      videoRef?.current?.pause();
    }
    setShowVideo(false);
  };

  const isMobile = dynamicWidth <= 799;
  return (
    <>
      <SuccessMintModal
        show={successMintModal}
        onhide={() => setSuccessinModal(false)}
      />

      {isMintingPaused && (
        <div className="Workshop_content">
          <p className="text-danger">
            <b> Minting is paused for the session.</b>
          </p>
        </div>
      )}
      {isLoaded && !isMobile && (
        <div className="button-wrap my-4">
          <Button
            onClick={handleClickEnterFullscreen}
            className="link_wallet btn btn-secondary"
          >
            Full Screen
          </Button>
        </div>
      )}

      <div className=" mb-4 position-relative w-100">
        <div className="gameplay_loder">
          {!isLoaded && !showVideo && (
            <div className="d-flex align-items-center justify-content-center">
              <Spinner style={{ color: "#fff" }} />
            </div>
          )}
        </div>

        <div className="game_wraaping position-relative">
          {!showVideo && (
            <Unity
              ref={ref}
              unityProvider={unityProvider}
              style={{ visibility: isLoaded ? "visible" : "hidden" }}
            />
          )}
          {!showVideo && loading && (
            <div className="gamefade_loderfade">
              <Spinner style={{ color: "#fff" }} />
            </div>
          )}
        </div>

        {/* 
      {!showVideo &&
          <Unity
            ref ={ref}
            unityProvider={unityProvider}
            style={{ visibility: isLoaded ? "visible" : "hidden",width : "100%", height : "auto" }}
          />
          } */}
        {showVideo && (
          <div className="overlay_vedio position-relative">
            {/* <div className="volume-icon" onClick={()=>setIsVideoMuted(!isVideoMuted)}>
              {isVideoMuted ? 
              <svg  xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 512 512"><path fill="white" d="M215.03 71.05L126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c15.03 15.03 40.97 4.47 40.97-16.97V88.02c0-21.46-25.96-31.98-40.97-16.97zM461.64 256l45.64-45.64c6.3-6.3 6.3-16.52 0-22.82l-22.82-22.82c-6.3-6.3-16.52-6.3-22.82 0L416 210.36l-45.64-45.64c-6.3-6.3-16.52-6.3-22.82 0l-22.82 22.82c-6.3 6.3-6.3 16.52 0 22.82L370.36 256l-45.63 45.63c-6.3 6.3-6.3 16.52 0 22.82l22.82 22.82c6.3 6.3 16.52 6.3 22.82 0L416 301.64l45.64 45.64c6.3 6.3 16.52 6.3 22.82 0l22.82-22.82c6.3-6.3 6.3-16.52 0-22.82z"/></svg>
              : 
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 480 512"><path d="M215.03 71.05L126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c15.03 15.03 40.97 4.47 40.97-16.97V88.02c0-21.46-25.96-31.98-40.97-16.97zM480 256c0-63.53-32.06-121.94-85.77-156.24-11.19-7.14-26.03-3.82-33.12 7.46s-3.78 26.21 7.41 33.36C408.27 165.97 432 209.11 432 256s-23.73 90.03-63.48 115.42c-11.19 7.14-14.5 22.07-7.41 33.36 6.51 10.36 21.12 15.14 33.12 7.46C447.94 377.94 480 319.53 480 256zm-141.77-76.87c-11.58-6.33-26.19-2.16-32.61 9.45-6.39 11.61-2.16 26.2 9.45 32.61C327.98 228.28 336 241.63 336 256c0 14.38-8.02 27.72-20.92 34.81-11.61 6.41-15.84 21-9.45 32.61 6.43 11.66 21.05 15.8 32.61 9.45 28.23-15.55 45.77-45 45.77-76.88s-17.54-61.32-45.78-76.86z" fill="white"/></svg>
              }
             </div> */}
            <Video
              videoUrl={`${RoboticRabbitsS3BaseUri}/RabbitsRunning.MP4`}
              onVideoEnd={onVideoEnd}
              isFullScreen={false}
              autoPlay={false}
              muted={isVideoMuted}
              controls={true}
              onLoadVideo={(videoRef) => {
                if (videoRef?.current) {
                  // setTimeout(()=>{
                  // videoRef.current.muted = false;
                  // setIsVideoMuted(false)
                  // },3000)
                }
              }}
              isSkipAllow={true}
              onSkipVideo={onSkipVideo}
            />
          </div>
        )}
      </div>
      <div className="game-details">
        <h5 className="game-detail-heading">Game Mint Details</h5>
        <hr />
        {gameMintDetailError ? (
          <p className="text-warning">
            There is some issue while getting the mint details
          </p>
        ) : (
          <>
            {/* <h5 className="text-white"> Whitelist</h5>

            <p>
              Verify To Mint :{" "}
              <span>
                {" "}
                {userGameMintDetails?.["whitelist"]?.verifyMintCount || 0}/3
              </span>
            </p>
            <p>
              Easy Mode To Mint:{" "}
              <span>
                {" "}
                {userGameMintDetails?.["whitelist"]?.easyModeMintCount || 0}/1
              </span>
            </p>
            <p>
              Hard Mode To Mint:{" "}
              <span>
                {" "}
                {userGameMintDetails?.["whitelist"]?.hardModeMintCount || 0}/1
              </span>
            </p>
            <hr /> */}

            <h5 className="text-white"> Public</h5>

            <p>
              Verify To Mint :{" "}
              <span>
                {" "}
                {userGameMintDetails?.["public"]?.verifyMintCount || 0}/3
              </span>
            </p>
            <p>
              Easy Mode To Mint:{" "}
              <span>
                {" "}
                {userGameMintDetails?.["public"]?.easyModeMintCount || 0}/1
              </span>
            </p>
            <p>
              Hard Mode To Mint:{" "}
              <span>
                {" "}
                {userGameMintDetails?.["public"]?.hardModeMintCount || 0}/1
              </span>
            </p>
          </>
        )}
      </div>
      
    </>
  );
};
