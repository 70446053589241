import React, { useEffect } from "react";
import Signup from "Pages/Signup";
import { ImageProvider, useImage } from "Context/ImageContext";

const SignupPage = () => {
  const { setShowProgress,setHideTimeOut } = useImage();
  useEffect(()=>{
    setShowProgress(true);
    setHideTimeOut(4000);
  },[])
  return (
    <React.Fragment>
             <Signup />
    </React.Fragment>
  );
};

const Signuptheme = () =>{
  return(
    <ImageProvider>
       <SignupPage/>
    </ImageProvider>
  );
}


export default Signuptheme;
