import {
  ITEM_API_FAIL,
  POST_MINT_ACTIVITY,
  POST_MINT_ACTIVITY_FAIL,
  POST_MINT_ACTIVITY_SUCCESS,
  GET_ITEMS,
  GET_ITEMS_FAIL,
  GET_ITEMS_SUCCESS,
  NFTS_RRC_TO_MAINBUCKET_SUCCESS,
  NFTS_RRC_TO_MAINBUCKET_REQUEST,
  NFTS_RRC_TO_MAINBUCKET_FAIL,
  GET_USER_MINTED_NFTS_COUNT_REQUEST,
  GET_USER_MINTED_NFTS_COUNT_SUCCESS,
  GET_USER_MINTED_NFTS_COUNT_FAIL,
  UPDATE_USER_NFT_METADATA_REQUEST,
  UPDATE_USER_NFT_METADATA_SUCCESS,
  UPDATE_USER_NFT_METADATA_FAIL,
  RRWS_TOKENIDS_S3_METADATA_REQUEST,
  RRWS_TOKENIDS_S3_METADATA_SUCCESS,
  RRWS_TOKENIDS_S3_METADATA_FAIL,
  GET_DISCORD_AK_NFT_COUNT_REQUEST,
  GET_DISCORD_AK_NFT_COUNT_SUCCESS,
  GET_DISCORD_AK_NFT_COUNT_FAIL,
  GET_USER_GAME_MINT_REQUEST,
  GET_USER_GAME_MINT_SUCCESS,
  GET_USER_GAME_MINT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  items: [],
  total: 0,
  currentMintTokens: [],
  userWalletMintedNftsCount: 0,
  loadingUserMintedNftCount: false,
  loadingUpdateNft: false,
  loadingDiscordAk47: false,
  loadingGameMintDetails: false,
  userGameMintDetails: {},
  gameMintDetailError: "",
};

const Items = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Post Mint Activity */
    case POST_MINT_ACTIVITY:
      return {
        ...state,
        loading: true,
      };

    case POST_MINT_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case POST_MINT_ACTIVITY_FAIL:
      return {
        ...state,
        loading: false,
      };
    /* Post Mint Activity END */

    /* get items */
    case GET_ITEMS:
      return {
        ...state,
        loading: true,
      };

    case GET_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.data || [],
        total: action.payload.totalCount || 0,
      };

    case GET_ITEMS_FAIL:
      return {
        ...state,
        loading: false,
      };
    /* get items END */

    case ITEM_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case NFTS_RRC_TO_MAINBUCKET_REQUEST:
      return {
        ...state,
        loading: false,
      };

    case NFTS_RRC_TO_MAINBUCKET_SUCCESS:
      return {
        ...state,
        loading: false,
        currentMintTokens: action.payload.data,
      };

    case NFTS_RRC_TO_MAINBUCKET_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_USER_MINTED_NFTS_COUNT_REQUEST:
      return {
        ...state,
        loadingUserMintedNftCount: false,
        userWalletMintedNftsCount: 0,
      };

    case GET_USER_MINTED_NFTS_COUNT_SUCCESS:
      return {
        ...state,
        loadingUserMintedNftCount: false,
        userWalletMintedNftsCount: action.payload.totalCount,
      };

    case GET_USER_MINTED_NFTS_COUNT_FAIL:
      return {
        ...state,
        loadingUserMintedNftCount: false,
        userWalletMintedNftsCount: 0,
      };

    case UPDATE_USER_NFT_METADATA_REQUEST:
      return {
        ...state,
        loadingUpdateNft: true,
      };

    case UPDATE_USER_NFT_METADATA_SUCCESS:
      return {
        ...state,
        loadingUpdateNft: false,
      };

    case UPDATE_USER_NFT_METADATA_FAIL:
      return {
        ...state,
        loadingUpdateNft: false,
      };

    case RRWS_TOKENIDS_S3_METADATA_REQUEST:
      return {
        ...state,
      };

    case RRWS_TOKENIDS_S3_METADATA_SUCCESS:
      return {
        ...state,
      };

    case RRWS_TOKENIDS_S3_METADATA_FAIL:
      return {
        ...state,
      };

    case GET_DISCORD_AK_NFT_COUNT_REQUEST:
      return {
        ...state,
        loadingDiscordAk47: true,
      };

    case GET_DISCORD_AK_NFT_COUNT_SUCCESS:
      return {
        ...state,
        loadingDiscordAk47: false,
      };

    case GET_DISCORD_AK_NFT_COUNT_FAIL:
      return {
        ...state,
        loadingDiscordAk47: false,
      };

    case GET_USER_GAME_MINT_REQUEST:
      return {
        ...state,
        loadingGameMintDetails: true,
        gameMintDetailError: "",
      };

    case GET_USER_GAME_MINT_SUCCESS:
      return {
        ...state,
        loadingGameMintDetails: false,
        userGameMintDetails: action.payload.data,
        gameMintDetailError: "",
      };

    case GET_USER_GAME_MINT_FAIL:
      return {
        ...state,
        loadingGameMintDetails: false,
        gameMintDetailError: action.payload,
      };

    default:
      return state;
  }
};

export default Items;
