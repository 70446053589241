import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";

const Video = ({
  videoUrl,
  onVideoEnd,
  onLoadVideo,
  onSkipVideo,
  isSkipAllow,
  isFullScreen,
  isShowSound,
  onSoundHandler,
  ...props
}) => {
  const videoRef = useRef();
  const [playing, setPlaying] = useState(false);

  const startVideo = () => {
    if (videoRef.current && !playing) {
      videoRef.current.play().then(() => {
        setPlaying(true);
      });
    }
  };

  const handleVideoEnd = () => {
    if (onVideoEnd) {
      onVideoEnd(videoRef);
    }
  };

  const handleLoadVideo = () => {
    onLoadVideo && onLoadVideo(videoRef);
  };

  const handleSkipVideo = () => {
    onSkipVideo && onSkipVideo(videoRef);
  };

  const handleSound = () => {
    onSoundHandler && onSoundHandler(videoRef);
  };
  return (
    <div className={`${isFullScreen ? "full-screen-video" : ""}`}>
      <div class="video-inner">
        <video
          onClick={() => startVideo()}
          width={"100%"}
          height={"100%"}
          ref={videoRef}
          src={videoUrl}
          onLoadStart={handleLoadVideo}
          onEnded={handleVideoEnd}
          {...props}
        ></video>
        {isSkipAllow && (
          <div
            onClick={() => handleSkipVideo(videoRef)}
            className="video-skip-btn-wrap"
          >
            <span className="video-skip-btn">
              <div className="button-wrap">Skip {`${">>"}`}</div>
            </span>
          </div>
        )}

        <span
          className="video-skip-btn-wrap"
          style={{ left: 10, right: "unset" }}
        >
          {isShowSound && (
            <div className="button-wrap" onClick={handleSound}>
              {props.muted ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M215.03 71.05L126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c15.03 15.03 40.97 4.47 40.97-16.97V88.02c0-21.46-25.96-31.98-40.97-16.97M461.64 256l45.64-45.64c6.3-6.3 6.3-16.52 0-22.82l-22.82-22.82c-6.3-6.3-16.52-6.3-22.82 0L416 210.36l-45.64-45.64c-6.3-6.3-16.52-6.3-22.82 0l-22.82 22.82c-6.3 6.3-6.3 16.52 0 22.82L370.36 256l-45.63 45.63c-6.3 6.3-6.3 16.52 0 22.82l22.82 22.82c6.3 6.3 16.52 6.3 22.82 0L416 301.64l45.64 45.64c6.3 6.3 16.52 6.3 22.82 0l22.82-22.82c6.3-6.3 6.3-16.52 0-22.82z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 480 512"
                >
                  <path
                    d="M215.03 71.05L126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c15.03 15.03 40.97 4.47 40.97-16.97V88.02c0-21.46-25.96-31.98-40.97-16.97zM480 256c0-63.53-32.06-121.94-85.77-156.24-11.19-7.14-26.03-3.82-33.12 7.46s-3.78 26.21 7.41 33.36C408.27 165.97 432 209.11 432 256s-23.73 90.03-63.48 115.42c-11.19 7.14-14.5 22.07-7.41 33.36 6.51 10.36 21.12 15.14 33.12 7.46C447.94 377.94 480 319.53 480 256zm-141.77-76.87c-11.58-6.33-26.19-2.16-32.61 9.45-6.39 11.61-2.16 26.2 9.45 32.61C327.98 228.28 336 241.63 336 256c0 14.38-8.02 27.72-20.92 34.81-11.61 6.41-15.84 21-9.45 32.61 6.43 11.66 21.05 15.8 32.61 9.45 28.23-15.55 45.77-45 45.77-76.88s-17.54-61.32-45.78-76.86z"
                    fill="currentColor"
                  />
                </svg>
              )}
            </div>
          )}
        </span>
      </div>
    </div>
  );
};

export default Video;
