import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";

export default function Footer(props) {
  const { settings } = useSelector((state) => state?.Setting);
  console.log("socialMediaLinks",settings?.socialMediaSetting)
  return (
    // <section className={props.extraclass} style={{backgroundImage:'url("images/footerBack.png")',backgroundSize:"cover"}}>
    <section className={`${props.extraclass}`}>
      <div className="footer_wrap">
       <div className="bgshape"></div>
      {/* footer-main position-relative */}
      {/* <Container> */}
        <div className="footer_top">
          {/* <ul className="footer-links">
            <li class="nav-item">
              <a
                href="#homesection"
                class="nav-link gradient-text"
                aria-current="page"
              >
                Home
              </a>
            </li>

            <li class="nav-item">
              <a href="#mintsection" class="nav-link gradient-text">
                Mint
              </a>
            </li>
            <li class="nav-item">
              <a href="#roadmap" class="nav-link gradient-text">
                Roadmap
              </a>
            </li>

            <li class="nav-item">
              <a href="#team" class="nav-link gradient-text">
                Team
              </a>
            </li>

            <li class="nav-item">
              <a href="#faq" class="nav-link gradient-text">
                FAQ
              </a>
            </li>
          </ul> */}
          {/* <img src="/images/logo.png" alt="" className="img-fluid" /> */}

          <div className="ring_animation position-relative">
            <img src="/images/footer_patdot2.png" alt="" className="img-fluid footer_pat"/>
             
            <div className="glowing_ring">
            <img src="/images/logo.png" alt="" className="img-fluid footerlogog_inner" />
            </div>
          </div>
        </div>
        <div className="social_list">
          <ul>
          {settings?.socialMediaSetting?.opensea && 
           <li>   
              <a href={`${settings?.socialMediaSetting?.opensea ? settings?.socialMediaSetting?.opensea : "javascript:void(0)" }`} target="_blank">
                <img src="/images/socialone.png" alt="" className="img-fluid" />
              </a>
            </li>
          }
          {settings?.socialMediaSetting?.youtube &&
            <li>
              <a 
              href={`${settings?.socialMediaSetting?.youtube ? settings?.socialMediaSetting?.youtube : "javascript:void(0)" }`}
                  >
                <img src="/images/socialtwo.png" alt="" className="img-fluid" />
              </a>
            </li>
            }
            {settings?.socialMediaSetting?.twitter &&
            <li>
              <a 
              href={`${settings?.socialMediaSetting?.twitter ? settings?.socialMediaSetting?.twitter : "javascript:void(0)" }`}

             >
                <img src="/images/socialthree.png" alt="" className="img-fluid" />
              </a>
            </li>
           }
           {settings?.socialMediaSetting?.tiktok &&
            <li>
              <a 
                href={`${settings?.socialMediaSetting?.tiktok ? settings?.socialMediaSetting?.tiktok : "javascript:void(0)" }`}
              >
                <img src="/images/socialfour.png" alt="" className="img-fluid" />
              </a>
            </li>
           }
           {settings?.socialMediaSetting?.discord &&
            <li>
              <a 
                 href={`${settings?.socialMediaSetting?.discord ? settings?.socialMediaSetting?.discord : "javascript:void(0)" }`}
               target="_blank">
                <img src="/images/socialfive.png" alt="" className="img-fluid" />
              </a>
            </li>
            }
            {settings?.socialMediaSetting?.playstore &&
            <li>
              <a 
                 href={`${settings?.socialMediaSetting?.playstore ? settings?.socialMediaSetting?.playstore : "javascript:void(0)" }`}
               target="_blank">
                <img src="/images/playstore.png" alt="" className="img-fluid" />
              </a>
            </li>
            }
             {settings?.socialMediaSetting?.amazon &&
            <li>
              <a 
                 href={`${settings?.socialMediaSetting?.amazon ? settings?.socialMediaSetting?.amazon : "javascript:void(0)" }`}
               target="_blank">
                <img src="/images/socialamazon.png" alt="" className="img-fluid" />
              </a>
            </li>
            }
          
          </ul>
        </div>

      {/* </Container> */}
      <div className="footer-copyright d-flex align-item-center justify-content-between px-5">
        <p>© 2024 Robotic Rabbit Syndicate</p>


         <ul className="privacy_condition d-flex align-item-center m-0 p-0 gap-4">
            <li><Link to="/privacy">Privacy Policy</Link></li>
            <li><Link to="/terms-and-conditions">Terms & Condition</Link></li>

         </ul>
      </div>
      </div>
    </section>
  );
}
