import {
  ITEM_API_FAIL,
  POST_MINT_ACTIVITY,
  POST_MINT_ACTIVITY_FAIL,
  POST_MINT_ACTIVITY_SUCCESS,
  GET_ITEMS,
  GET_ITEMS_FAIL,
  GET_ITEMS_SUCCESS,
  NFTS_RRC_TO_MAINBUCKET_SUCCESS,
  NFTS_RRC_TO_MAINBUCKET_FAIL,
  NFTS_RRC_TO_MAINBUCKET_REQUEST,
  GET_USER_MINTED_NFTS_COUNT_REQUEST,
  GET_USER_MINTED_NFTS_COUNT_SUCCESS,
  GET_USER_MINTED_NFTS_COUNT_FAIL,
  UPDATE_USER_NFT_METADATA_REQUEST,
  UPDATE_USER_NFT_METADATA_SUCCESS,
  UPDATE_USER_NFT_METADATA_FAIL,
  RRWS_TOKENIDS_S3_METADATA_REQUEST,
  RRWS_TOKENIDS_S3_METADATA_SUCCESS,
  RRWS_TOKENIDS_S3_METADATA_FAIL,
  GET_DISCORD_AK_NFT_COUNT_REQUEST,
  GET_DISCORD_AK_NFT_COUNT_SUCCESS,
  GET_DISCORD_AK_NFT_COUNT_FAIL,
  GET_USER_GAME_MINT_REQUEST,
  GET_USER_GAME_MINT_SUCCESS,
  GET_USER_GAME_MINT_FAIL,
} from "./actionTypes";

export const apiFails = (error) => ({
  type: ITEM_API_FAIL,
  payload: error,
});

/* Post Mint Activity */
export const postMintActivity = (data, callback) => ({
  type: POST_MINT_ACTIVITY,
  payload: { data, callback },
});

export const postMintActivityFail = (error) => ({
  type: POST_MINT_ACTIVITY_FAIL,
  payload: error,
});

export const postMintActivitySuccess = (data) => ({
  type: POST_MINT_ACTIVITY_SUCCESS,
  payload: data,
});
/* Post Mint Activity END */

/* get item list */
export const getItems = (data, callback) => ({
  type: GET_ITEMS,
  payload: { data, callback },
});

export const getItemsFail = (error) => ({
  type: GET_ITEMS_FAIL,
  payload: error,
});

export const getItemsSuccess = (data) => ({
  type: GET_ITEMS_SUCCESS,
  payload: data,
});
/* get item list END */

//nft rrc to main bucket

export const postNftRrcToMainBucketRequest = (data, callback) => ({
  type: NFTS_RRC_TO_MAINBUCKET_REQUEST,
  payload: { data, callback },
});

export const postNftRrcToMainBucketSuccess = (data) => ({
  type: NFTS_RRC_TO_MAINBUCKET_SUCCESS,
  payload: data,
});

export const postNftRrcToMainBucketFail = (data) => ({
  type: NFTS_RRC_TO_MAINBUCKET_FAIL,
  payload: data,
});

//get user minted nfts count

export const getUserMintedNftsCountRequest = (data, callback) => ({
  type: GET_USER_MINTED_NFTS_COUNT_REQUEST,
  payload: { data, callback },
});

export const getUserMintedNftsCountSuccess = (data) => ({
  type: GET_USER_MINTED_NFTS_COUNT_SUCCESS,
  payload: data,
});

export const getUserMintedNftsCountFail = (data) => ({
  type: GET_USER_MINTED_NFTS_COUNT_FAIL,
  payload: data,
});

//update user nft metadata

export const updateUserNftMetadataRequest = (data, callback) => ({
  type: UPDATE_USER_NFT_METADATA_REQUEST,
  payload: { data, callback },
});

export const updateUserNftMetadataSuccess = (data) => ({
  type: UPDATE_USER_NFT_METADATA_SUCCESS,
  payload: data,
});

export const updateUserNftMetadataFail = (data) => ({
  type: UPDATE_USER_NFT_METADATA_FAIL,
  payload: data,
});

//post rrws metadata to s3

export const postRrwsS3MetadataRequest = (data, callback) => ({
  type: RRWS_TOKENIDS_S3_METADATA_REQUEST,
  payload: { data, callback },
});

export const postRrwsS3MetadataSuccess = (data) => ({
  type: RRWS_TOKENIDS_S3_METADATA_SUCCESS,
  payload: data,
});

export const postRrwsS3MetadataFail = (data) => ({
  type: RRWS_TOKENIDS_S3_METADATA_FAIL,
  payload: data,
});

//get discord ak47 nft count

export const getDiscordAkNftCountRequest = (data, callback) => ({
  type: GET_DISCORD_AK_NFT_COUNT_REQUEST,
  payload: { data, callback },
});

export const getDiscordAkNftCountSuccess = (data) => ({
  type: GET_DISCORD_AK_NFT_COUNT_SUCCESS,
  payload: data,
});

export const getDiscordAkNftCountFail = (data) => ({
  type: GET_DISCORD_AK_NFT_COUNT_FAIL,
  payload: data,
});

//get game mint details

export const getUserGameMintRequest = (data, callback) => ({
  type: GET_USER_GAME_MINT_REQUEST,
  payload: { data, callback },
});

export const getUserGameMintSuccess = (data) => ({
  type: GET_USER_GAME_MINT_SUCCESS,
  payload: data,
});

export const getUserGameMintFail = (data) => ({
  type: GET_USER_GAME_MINT_FAIL,
  payload: data,
});
