import SuccessMintModal from "Component/Modals/SuccessMintMod";
import { useSection } from "Context/HomeContext";
import { useImage } from "Context/ImageContext";
import { Contract, ethers } from "ethers";
import { getNftsBasicDetails } from "helpers/backend_helper";
import { getNetworkUrl } from "helpers/constants";
import useWallet from "hooks/wallet";
import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Input } from "reactstrap";
import {
  getCollection,
  postMintActivity,
  postNftRrcToMainBucketRequest,
} from "store/actions";
import { convertFromWei } from "util/web3InteractionEth";
import Web3Intraction from "util/web3Intraction";


const Playmint = () => {
  const { authToken } = useSelector((state) => state.Login);
  const navigate = useNavigate();
  const { activeSection } = useSection();
  console.log("activeSection", activeSection);
  const sectionRef = useRef(null);
  // Register section refs
  // registerSectionRef(sectionRef, 'play_mint');
  const { handleImageLoad } = useImage();
  const dispatch = useDispatch();
  const wallet = useWallet();
  const { user } = useSelector((state) => state.Login);
  const { settings } = useSelector((state) => state.Setting);
  const { collection } = useSelector((state) => state.Collection);
  const [loading, setloading] = useState(false);
  const [successMintModal, setSuccessinModal] = useState(false);
  const [counter, setCounter] = useState(1);
  const [nftsBasicDetails, setNftsBasicDetails] = useState(null);
  const [cost, setCost] = useState(0);
  const getBasicNftsDetails = async () => {
    try {

      const response = await getNftsBasicDetails();
      if (response?.data) {
        console.log("basic", response?.data);
        setNftsBasicDetails(response?.data)
      }
    }
    catch (err) {
      console.log("Error", err);
    }
  }

  useEffect(() => {

    getBasicNftsDetails();

  }, []);

  useEffect(() => {
    if (settings && settings.activeCollectionId) {
      dispatch(getCollection(settings.activeCollectionId));
    }
  }, [JSON.stringify(settings)]);

  useEffect(async () => {
    if (collection?._id && collection.contractAbi && collection.contractAddress) {
      try {
        const web3Intraction = new Web3Intraction(
          collection.blockchain,
          settings
        );
        // console.log(collection, "collection")

        const contract = await web3Intraction.getContract(
          collection.contractAbi,
          collection.contractAddress
        );
        console.log("contract", contract);
        const cost = await contract.methods.cost().call();
        const etherAmount = convertFromWei(cost);
        setCost(etherAmount);
      } catch (err) {
        console.log("err", err)
      }
    }
  }, [collection])
  const onConnectMetamask = async () => {
    try {
      if (wallet.account) return;
      console.log("cliecked");
      await wallet.initializeOnBoard();

      if (!wallet.account && settings?.blockchainNetworkMode && JSON.stringify(settings) !== "{}") {
        const networkUrl = getNetworkUrl("ethereum", settings);
        console.log("networkUrl onConnectMetamask", networkUrl);
        wallet.connect(
          "metamask",
          networkUrl.chainId || 1
        );

      }
    } catch (err) { }
  };

  const mintNft = async () => {
    try {

      setloading(true);
      if (!wallet.account) {
        return toast.error("Please connect your wallet!");
      }
      if (
        !collection ||
        !collection.contractAbi ||
        !settings ||
        JSON.stringify(settings) === "{}"
      ) {
        setloading(false);
        toast.error("Something went wrong!");
        return;
      }

      if (!counter) {
        setloading(false);
        return toast.error("Please select quantity!");
      }

      console.log("wallet.account", wallet.account);
      console.log("wallet.provider", wallet.provider);
      // let perMintValue = parseFloat(
      //   settings?.gamePlaySettings?.direct_mint_price
      // );
      // const value = parseFloat(perMintValue);

      console.log("collection.blockchain", collection);
      const web3Intraction = new Web3Intraction(
        collection.blockchain,
        settings
      );
      // console.log(collection, "collection")

      const contract = await web3Intraction.getContract(
        collection.contractAbi,
        collection.contractAddress
      );
      console.log("contract", contract);

      const isMintActive = await contract.methods.isMintActive().call();
      if (!isMintActive) {
        setloading(false);
        return toast.error("Minting is paused for the session.", {
          autoClose: 4000,
        });
      }
      console.log("is isMintActive", isMintActive);
      const PROVIDER = new ethers.providers.Web3Provider(
        wallet.provider,
        web3Intraction.networkUrl
          ? {
            name: web3Intraction.networkUrl.chainName,
            chainId: Number(web3Intraction.networkUrl.chainId),
          }
          : "any"
      );
      if (!wallet.account) {
        const networkUrl = getNetworkUrl("ethereum", settings);
        console.log("networkUrl", networkUrl);
        wallet.connect(
          "metamask",
          networkUrl.chainId || 1
        );
      }
      console.log("PROVIDER", PROVIDER);
      const SIGNER = PROVIDER.getSigner();
      console.log("SIGNER", SIGNER);
      // console.log(collection, "collection")
      let ethercontract = new Contract(
        collection.contractAddress,
        JSON.parse(collection.contractAbi),
        SIGNER
      );

      if (wallet.account) {
        const networkUrl = getNetworkUrl("ethereum", settings);
        console.log("networkUrl", networkUrl);


        await wallet.switchNetwork(
          networkUrl?.chainId || 1
        );


      } else {
        setloading(false);
        return toast.error(`Make sure your metamask connected`);
      }

      const cost = await contract.methods.cost().call();
      const etherAmount = convertFromWei(cost);
      const estimateFullCostWei = await contract.methods
        .estimateForFixPrice(counter)
        .call();
      const estimateFullCostEth = convertFromWei(estimateFullCostWei);

      console.log(
        "cost",
        cost,
        "etherAmount",
        etherAmount,
        "estimateFullCostEth",
        estimateFullCostEth
      );

      console.log("ethercontract", ethercontract);

      // let gasLimit = 300000;
      // gasLimit =
      //   parseInt(counter) === 1
      //     ? 300000
      //     : parseInt(counter) === 2
      //     ? 600000
      //     : parseInt(counter) === 3
      //     ? 900000
      //     : parseInt(counter) > 3
      //     ? undefined
      //     : 300000;
      const result = await ethercontract.callStatic["mint"](counter, {
        value: estimateFullCostWei
      });
      console.log("Successfully check : ", result);
      const transaction = await ethercontract.mint(counter,
        {
          value: estimateFullCostWei

        });
      const tx = await transaction.wait();
      console.log("tx", tx);
      let txHash = tx.transactionHash;

      // console.log("receipt after mint",receipt);
      setloading(false);
      if (txHash) {
        setSuccessinModal(true);
      }

      const _tokens = await contract.methods
        .walletOfOwner(wallet.account?.toLowerCase())
        .call();
      console.log("v", _tokens);

      //save item and history
      if (_tokens?.length && txHash) {
        const recentMintedTokens = _tokens.slice(-counter);
        console.log("recentMintedTokens", recentMintedTokens);
        dispatch(
          postMintActivity(
            {
              type: collection.blockchain,
              collection_id: collection._id,
              transactionHash: txHash,
              price: etherAmount,
              quantity: counter,
              walletAddress: wallet.account?.toLowerCase(),
              mint_type: "og-mint",
              token_ids: recentMintedTokens,
              directMint: true,
              play_to_mint_type: "direct-mint",
            },
            () => {
              // setloading(false);
            }
          )
        );

        //reveal nfts
        dispatch(
          postNftRrcToMainBucketRequest(
            { tokens: recentMintedTokens },
            (response) => {
              console.log("response after tokens move", response);
            }
          )
        );

        setTimeout(() => {
          setloading(false);
        }, 1000);
      }
    } catch (err) {
      console.log("error in mint", err);
      setloading(false);
      if (typeof err === "string") {
        return typeof err === "string" && toast.error(err);

      } else if (err?.message?.includes("insufficient funds")) {
        return toast.error("Insufficient funds for gas and value");
      } else if (err?.message?.includes("reverted")) {
        // Extract the error message from the revert error
        const errorMessage = err.message.split('"')[1];

        // Display the error message to the user
        return toast.error(errorMessage); // You can use other UI elements to display the message
      }
      else {
        console.log("else error mint", err)
        return toast.error(err?.message || "Something went wrong!");
      }
    } finally {
      setloading(false);
    }
  };

  const handleCounter = (value) => {
    if (value > 15) {
      toast.error("You can mint maximux 15 nfts at a time.")
      return;
    }

    setCounter(value);
  }

  const revealPlayToMint = settings?.gamePlaySettings?.reveal_play_to_mint;

  return (
    <div className="mint_wrap">
      <SuccessMintModal
        show={successMintModal}
        onhide={() => setSuccessinModal(false)}
      />
      <div className="heading_wrap">
        <div className="inner position-relative">
          <h2 className="front_heading">Minting</h2>
          <span className="back_heading">Minting</span>
        </div>
      </div>
      <section className="play_mint position-relative" id="play_mint">
        <div
          className={`${activeSection === "play_mint" ? "bannerFixed" : "bannerAbsolute"
            } mintWrap`}
        >
          <img
            onLoad={handleImageLoad}
            onError={handleImageLoad}
            src="/images/typing.gif"
            className="img-fluid lazy-media"
          />
        </div>
      </section>
      {/* <Container> */}
      <div className="play_mint desktop-playmint">
        {/* <img
              onLoad={handleImageLoad}
              onError={handleImageLoad}
              src="/images/mintingBg.gif"
              className="img-fluid lazy-media desktop-playmint-img"/> */}

        <video
          autoPlay
          loop
          muted
          className="desktop-playmint-img lazy-media"
          onLoadedData={handleImageLoad}
          onError={handleImageLoad}
          playsInline
        >
          <source src="/videos/typing.MP4" type="video/mp4" />
        </video>
      </div>

      <div className="mintbtn_Wrapps">
        <div className="button-wrap mt-4 mb-2">
          <Button
            onClick={onConnectMetamask}
            className="common-greenorange ps-4 position-relative"
          >
            <div
              className={`dotcommon ${wallet.account ? "online_dot" : "ofline_dot"
                }`}
            ></div>
            {wallet.account ? "Wallet Connected" : "Connect Wallet"}
          </Button>
        </div>
        <div className="text-white">
          <p className="h5"><b>Total Minted</b> : {nftsBasicDetails?.totalOGMintedNfts || 0}</p>
          <p className="h5"><b>Remaining</b> : {nftsBasicDetails?.remainingOGMintedNfts || 0}</p>
          <p className="h5"><b>Cost Per Mint</b> : {(Number(cost) || 0).toFixed(3)}</p>
          <p className="h5"><b>Total Cost</b> : {Number(Number(cost) * Number(counter) || 0).toFixed(3)}</p>
        </div>
        <div className="connection_btn pb-3  gap-3 play-mint-btn-wrap">
          <div className="counter-wrap">
            <div className="button-wrap m-1">
              <Button
                onClick={() => handleCounter(counter > 1 ? counter - 1 : 1)}
                className="common-greenorange "
                color="primary"
                style={{ width: 50 }}
                disabled={loading}
              >
                -
              </Button>
            </div>
            <div className="button-wrap m-1">
              <Input
                type="number"
                value={counter}
                onChange={(e) => handleCounter(e.target.value)}
                min={1}
                disabled
                style={{ width: 80 }}
              />
            </div>
            <div className="button-wrap m-1">
              <Button
                onClick={() => handleCounter(counter + 1)}
                className="common-greenorange"
                color="primary"
                style={{ width: 50 }}
                disabled={loading}
              >
                +
              </Button>
            </div>
          </div>
          <div className="mint-btn-wrap align-items-center">
            <div className="button-wrap d-inline-flex">
              <Button
                onClick={mintNft}
                className="common-greenorange"
                color="primary"
                disabled={loading}
              >
                {loading && (
                  <Spinner color="light" size="sm" className="mr-3" />
                )}
                Mint
              </Button>
            </div>
            <div className="my-3">
              <h5 className="text-white">or play for a discount</h5>
            </div>
            {revealPlayToMint ? (
              <>
                {/* <p className="my-0 text-white">OR</p> */}
                <div className="button-wrap bg-transparent d-inline-flex" style={{maxWidth: 150}}>
                  <Button
                    // className="common-greenorange"
                    className="bg-transparent border-0 p-0"
                    color="primary"
                    onClick={() => {
                      authToken
                        ? navigate("/play-to-mint")
                        : navigate("/signin", {
                          state: {
                            redirectTo: "/play-to-mint",
                          },
                        });
                    }}
                  >
                    <img src="./images/plattomint.png" alt="" className="img-fluid object-fit-contain" style={{ height: 150, }} />
                  </Button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>

      {/* </Container> */}
    </div>
  );
};

export default Playmint;
