export const GET_DISCORD_DATA = "GET_DISCORD_DATA";
export const GET_DISCORD_DATA_FAIL = "GET_DISCORD_DATA_FAIL";
export const GET_DISCORD_DATA_SUCCESS = "GET_DISCORD_DATA_SUCCESS";

export const GET_DISCORD_USER_DATA = "GET_DISCORD_USER_DATA";
export const GET_DISCORD_USER_DATA_FAIL = "GET_DISCORD_USER_DATA_FAIL";
export const GET_DISCORD_USER_DATA_SUCCESS = "GET_DISCORD_USER_DATA_SUCCESS";

export const UNLINK_DISCORD = "UNLINK_DISCORD";
export const UNLINK_DISCORD_FAIL = "UNLINK_DISCORD_FAIL";
export const UNLINK_DISCORD_SUCCESS = "UNLINK_DISCORD_SUCCESS";


export const GET_DISCORD_LOGIN_REDIRECT_REQUEST = "GET_DISCORD_LOGIN_REDIRECT_REQUEST";
export const GET_DISCORD_LOGIN_REDIRECT_SUCCESS = "GET_DISCORD_LOGIN_REDIRECT_SUCCESS";
export const GET_DISCORD_LOGIN_REDIRECT_FAIL = "GET_DISCORD_LOGIN_REDIRECT_FAIL";

export const POST_DISCORD_LOGIN_REQUEST = "POST_DISCORD_LOGIN_REQUEST";
export const POST_DISCORD_LOGIN_SUCCESS = "POST_DISCORD_LOGIN_SUCCESS";
export const POST_DISCORD_LOGIN_FAIL = "POST_DISCORD_LOGIN_FAIL";

export const VERIFY_DISCORD_USER_REQUEST = "VERIFY_DISCORD_USER_REQUEST";
export const VERIFY_DISCORD_USER_SUCCESS = "VERIFY_DISCORD_USER_SUCCESS";
export const VERIFY_DISCORD_USER_FAIL = "VERIFY_DISCORD_USER_FAIL";





