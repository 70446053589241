import { useImage } from "Context/ImageContext";
import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Fade } from "react-reveal";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="150"
        viewBox="0 0 24 24"
      >
        <path fill="#21e786" d="M10 17V7l5 5l-5 5Z" />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="150"
        viewBox="0 0 24 24"
      >
        <path fill="#21e786" d="m14 17l-5-5l5-5v10Z" />
      </svg>
    </div>
  );
}

const Roadmap = () => {
  const { handleImageLoad } = useImage();
  const { settings } = useSelector((state) => state?.Setting);

  var settinga = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1.3,
        },
      },
    ],
  };

  return (
    // <Fade>
    <>
      <section id="faq" className="faq_section">
        {/* <Container> */}
        <div className="heading_wrap">
          <div className="inner position-relative">
            <h2 className="front_heading">Roadmap</h2>
            <span className="back_heading">Roadmap</span>
          </div>
        </div>

        <div className="faq_column roadSlider p-3 px-lg-5 px-4">
          {settings?.roadMapSetting?.length ? (
            <Slider {...settinga} className="roadMapSlider line">
              {(settings?.roadMapSetting || []).map((nft, index) => (
                <>
                  <div key={index} className="px-1 py-3 text-start innerWrpper">
                    <div className="top d-inline-block text-center">
                      <p className="text-white m-0 mb-2">{nft?.timeline}</p>
                      <span
                        className="roadmap-ico-wrap"
                        style={{ backgroundColor: nft.topSquareBgColor }}
                      >
                        <img
                          className="rounded-new"
                          src={nft?.topIcon}
                          alt=""
                          height={40}
                          width={40}
                        />
                      </span>
                    </div>
                    <div className="roadmap-card">
                      <div className="image-wrap">
                        <img
                          className="lazy-media w-100"
                          onLoad={handleImageLoad}
                          onError={handleImageLoad}
                          src={nft?.image}
                          height={211}
                        />
                      </div>
                      <div className="content p-3">
                        <h4
                          title={nft?.title}
                          className="card_header text-white text-truncate mx-0 mb-2"
                        >
                          {nft?.title}
                        </h4>
                        <p className="text-white">{nft?.description}</p>
                        {/* <p>
                          {" "}
                          <a
                            //   href="#"
                            href={nft?.link}
                            target="_blank"
                            className=" text-white "
                          >
                            Visit link {`>`}
                          </a>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </Slider>
          ) : null}
        </div>
        {/* </Container> */}
      </section>
      {/* </Fade> */}
    </>
  );
};

export default Roadmap;
